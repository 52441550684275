import { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";

import * as am5percent from "@amcharts/amcharts5/percent";

import dark from "@amcharts/amcharts5/themes/Dark";

function DriverSexFemaleChart(props) {
  const [data, setData] = useState([
    {
      title: "25 хүртлэх",
      value: 8,
    },
    {
      title: "26-35 нас",
      value: 274,
    },
  ]);
  useEffect(() => {
    if (props.dashboardData != null) {
      setData(props.dashboardData.driver.female.age);
    }
  }, [props]);
  //   console.log(props);
  //   console.log(data);
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv5");
    root._logo.dispose();

    root.setThemes([dark.new(root)]);

    let chart = root.container.children.push(
      am5percent.SlicedChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/sliced-chart/#Series
    let series = chart.series.push(
      am5percent.PictorialStackedSeries.new(root, {
        alignLabels: true,
        orientation: "vertical",
        valueField: "value",
        categoryField: "title",
        svgPath:
          "M 18.4 15.1 L 15.5 25.5 c -0.6 2.3 2.1 3.2 2.7 1 l 2.6 -9.6 h 0.7 l -4.5 16.9 H 21.3 v 12.7 c 0 2.3 3.2 2.3 3.2 0 V 33.9 h 1 v 12.7 c 0 2.3 3.1 2.3 3.1 0 V 33.9 h 4.3 l -4.6 -16.9 h 0.8 l 2.6 9.6 c 0.7 2.2 3.3 1.3 2.7 -1 l -2.9 -10.4 c -0.4 -1.2 -1.8 -3.3 -4.2 -3.4 h -4.7 C 20.1 11.9 18.7 13.9 18.4 15.1 z M 28.6 7.2 c 0 -2.1 -1.6 -3.7 -3.7 -3.7 c -2 0 -3.7 1.7 -3.7 3.7 c 0 2.1 1.6 3.7 3.7 3.7 C 27 10.9 28.6 9.2 28.6 7.2 z",
      })
    );

    series.labelsContainer.set("width", 100);
    series.ticks.template.set("location", 0.6);

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/sliced-chart/#Setting_data
    series.data.setAll(data);

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, props]);

  return <div id="chartdiv5" style={{ width: "100%", height: "310px" }}></div>;
}
export default DriverSexFemaleChart;
