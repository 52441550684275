import { Box, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Popover } from "devextreme-react/popover";
import Button from "devextreme-react/button";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Box as BoxDevExt, Item } from "devextreme-react/box";
import { useCallback, useEffect, useRef, useState } from "react";

import ScrollView from "devextreme-react/scroll-view";
import { useDispatch, useSelector } from "react-redux";
import {
  DropDownBox,
  FileUploader,
  Form,
  LoadIndicator,
  Lookup,
  Popup,
  SelectBox,
  TextArea,
  TileView,
  TreeView,
} from "devextreme-react";

import { GroupItem, SimpleItem } from "devextreme-react/form";
import {
  backDecisionFun,
  feedBackEdit,
  getGrievanceDecisionList,
  getGrievanceDecisionTypeListFun,
  grievanceMove,
  setGrievanceIsEdit,
} from "../../store/reducers/feedBack";
import { backgrounds, borderRadius } from "polished";
import { getEmployeTreeList } from "../../store/reducers/employe";
import { DropDownOptions } from "devextreme-react/autocomplete";
import DataSource from "devextreme/data/data_source";
import GrievanceMoveStore from "./grievanceMoveStore";
import notify from "devextreme/ui/notify";
import {
  getVehicleCheckFun,
  setVehicleCheckData,
} from "../../store/reducers/vehicle";
import BaseHelper from "../../helper/BaseHelper";
import { AttachFile } from "@mui/icons-material";
import ReactAudioPlayer from "react-audio-player";

const FeedBackConfirmed = (props) => {
  const dispatch = useDispatch();
  const [isFeedBack, setIsFeedBack] = useState(false);
  const gridRef = useRef();
  const getGrievanceDecisionListData = useSelector(
    (state) => state.feedBack.grievanceDecisionList
  );
  const getFeedBackTypeList = useSelector(
    (state) => state.feedBack.feedBackTypeList
  );
  const employeTreeListData = useSelector(
    (state) => state.employe.employeTreeListData
  );
  const getGrievanceIsEdit = useSelector(
    (state) => state.feedBack.grievanceIsEdit
  );
  const getGrievanceDecisionTypeListData = useSelector(
    (state) => state.feedBack.grievanceDecisionTypeListData
  );
  const getVehicleCheckData = useSelector(
    (state) => state.vehicle.vehicleCheckData
  );
  // INSERT INTO "VRS"."SYSTEM_MENU" (ID, NAME, URL) VALUES ('120281', 'Захиалсан дугаар жагсаалт', '/plateSave/plateNumberOrderList')
  //  console.log(props);
  const [isPop, setIsPop] = useState(false);
  const [employes, setEmployes] = useState([]);
  const [isType, setIsType] = useState(1);
  const [comment, setComment] = useState("");
  const [commentGrivance, setCommentGrivance] = useState("");
  const [decissionId, setDecissionId] = useState("");
  const [plateNo, setPlateNo] = useState("");
  const [garageNo, setGarageNo] = useState("");
  const [itemComment, setItemComment] = useState("");
  const [feedbackType, setFeedbackType] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dataDrop, setDataDrop] = useState(null);
  const [feedBackTypeChildData, setFeedBackTypeChildData] = useState([]);
  const simpleProductLabel = { "aria-label": "Simple Product" };
  const [treeBoxValue, setTreeBoxValue] = useState(null);
  const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
  useEffect(() => {
    if (getFeedBackTypeList !== null) {
      var tempProps = JSON.parse(JSON.stringify(getFeedBackTypeList));
      // console.log(tempProps);
      setFeedbackType(tempProps);
    }
  }, [getFeedBackTypeList, setFeedbackType]);

  useEffect(() => {
    // console.log(getGrievanceDecisionListData);
    if (getVehicleCheckData !== null && garageNo === "") {
      setGarageNo(getVehicleCheckData.garage_no);
    }
  }, [getVehicleCheckData, garageNo]);
  useEffect(() => {
    // console.log(getGrievanceDecisionListData);
    if (plateNo === "") {
      setPlateNo(props.feedBackData.plate_no);
    }
  }, [props, plateNo, setPlateNo]);

  // console.log(plateNo);
  //console.log(getFeedBackTypeList);
  // console.log(props);
  useEffect(() => {
    // console.log(getGrievanceDecisionListData);
    if (getGrievanceDecisionListData === null) {
      setIsFeedBack(false);
    } else {
      setIsFeedBack(true);
    }

    // dispatch(getGrievanceDecisionList())
  }, [getGrievanceDecisionListData]);
  useEffect(() => {
    // console.log(getGrievanceDecisionListData);
    if (props.isCloseFun) {
      setCommentGrivance("");
      setDecissionId("");
      // setFeedbackType(null);
      // setFeedbackChildType(null);
      setTreeBoxValue(null);
      setDataDrop(null);
      setPlateNo("");
      setGarageNo("");
      dispatch(setVehicleCheckData(null));
    }

    // dispatch(getGrievanceDecisionList())
  }, [props, dispatch]);
  useEffect(() => {
    if (employeTreeListData === null) {
      const data = { company_id: 1 };
      dispatch(getEmployeTreeList(data));
    }
  }, [employeTreeListData, dispatch]);
  useEffect(() => {
    if (getGrievanceDecisionTypeListData === null) {
      //  const data = { company_id: 1 };
      dispatch(getGrievanceDecisionTypeListFun());
    }
  }, [getGrievanceDecisionTypeListData, dispatch]);

  useEffect(() => {
    if (employeTreeListData !== null) {
      var tempProps = JSON.parse(JSON.stringify(employeTreeListData));

      setEmployes(tempProps);
    }
  }, [employeTreeListData, dispatch]);
  const getDisplayExpr = (item) => {
    return item ? `${item.name}` : "";
  };
  const hideInfo = () => {
    setIsPop(false);
    setCommentGrivance("");
    setDecissionId("");
    setTreeBoxValue(null);
    setDataDrop(null);

    // setFeedbackChildType(null);
    // setFeedbackChildType(null);
    setPlateNo("");

    // setIsConfirm(false);
  };
  useEffect(() => {
    if (props.feedBackData.type_id !== null && dataDrop === null) {
      //  console.log("ddddd");
      setDataDrop([
        {
          id: props.feedBackData.type_id,
          name: props.feedBackData.type_name,
        },
      ]);
    }
  }, [dataDrop, props, setDataDrop]);
  useEffect(() => {
    if (props.feedBackData.plate_no !== null && plateNo === "") {
      //  console.log("ddddd");
      setPlateNo(props.feedBackData.plate_no);
    }
  }, [dataDrop, plateNo, props, setDataDrop]);
  // console.log(feedbackType);
  //console.log(dataDrop);
  // console.log(getFeedBackTypeList);
  //console.log(props);
  //  console.log(getGrievanceDecisionTypeListData);
  // console.log(props.feedBackData);

  //console.log(soum_district_id);
  const submitConfirmFeed = () => {
    setIsPop(false);
    props.isCloseFun();
    if (decissionId !== "") {
      const formData = new FormData();
      formData.append("grievance_id", props.feedBackData.id);
      formData.append("comment", comment);
      formData.append("type_id", decissionId);
      formData.append("is_transfer", 0);
      if (selectedFiles.length > 0) {
        Array.from(selectedFiles).forEach((file) => {
          //  console.log(img);
          formData.append("attachment", file);
        });
      }
      dispatch(grievanceMove(formData));
      dispatch(setGrievanceIsEdit(false));
      setCommentGrivance("");
      setDecissionId("");
      setTreeBoxValue(null);
      // setFeedbackChildType(null);
      // setFeedbackChildType(null);

      setPlateNo("");
    } else {
      notify(
        {
          message: "Мэдээлэл гүйцэд оруулна уу!!!",
          width: 450,
        },
        "error"
      );
    }
  };
  // console.log(getVehicleCheckData);
  function avatarRender() {
    return (
      <div
        className="form-avatar"
        style={{ width: "62px", height: "62px", borderRadius: "0" }}
      ></div>
    );
  }
  function formFieldDataChanged(e) {
    // const updatedField = e.dataField;
    // const newValue = e.value;
    console.log(e);
    // Event handling commands go here
  }
  //   function getImagePath(taskId) {
  //     const imgPath = `assets/`;
  //     img = `${imgPath}/profile.png`;
  //     return img;
  //   }

  function getTaskColor(taskId) {
    const color = taskId % 6;
    return `custom-task-color-${color}`;
  }
  const onSelectedFilesChanged = useCallback(
    (e) => {
      setSelectedFiles(e.value);
    },
    [setSelectedFiles]
  );
  function formSubmitUpdate() {
    if (getVehicleCheckData !== null) {
      const data = {
        id: props.feedBackData.id,
        vehicle_id: getVehicleCheckData.id,
        note: commentGrivance,
        company_id: getVehicleCheckData.company_id,
        type_id: treeBoxValue,
      };
      // console.log(data);
      dispatch(feedBackEdit(data));
    } else {
      const data = {
        id: props.feedBackData.id,

        note: commentGrivance,

        type_id: treeBoxValue,
      };
      // console.log(data);
      dispatch(feedBackEdit(data));
    }
    //} else {
    //notify("Талбарын утга гүйцэд оруулан уу.");
    //  }
  }
  //console.log(employeTreeListData);
  const groupedData = new DataSource({
    store: employes,
    key: "id",
    group: "name",
  });
  const searchButtonPlate = {
    icon: "/assets/eye.png",
    //icon: "fa fa-envelope-o",
    type: "default",
    onClick: () => {
      dispatch(getVehicleCheckFun({ plate_no: plateNo, is_approved: 1 }));

      //  dispatch(getVehicleInspectionData(plateValue));
    },
  };
  const searchButtonGarage = {
    icon: "/assets/eye.png",
    disabled:
      BaseHelper.permitionCheck(4, 78, "is_update", props.loggedUserData) ===
      false
        ? true
        : false,
    //icon: "fa fa-envelope-o",
    type: "default",
    onClick: () => {
      //  console.log("garage");
      dispatch(getVehicleCheckFun({ garage_no: garageNo, is_approved: 1 }));

      //  dispatch(getVehicleInspectionData(plateValue));
    },
  };

  const onButtonClick = (url) => {
    const fileName = url.split("/").pop();
    const aTag = document.createElement("a");
    aTag.href = url;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
  };
  function TaskTemplate(item) {
    // console.log(item);
    // loggedUserData
    return (
      <Box sx={{ p: 1, display: "flex", position: "relative" }}>
        <Stack
          spacing={2}
          direction={"column"}
          justifyContent={"space-between"}
        >
          <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
            <img
              style={{ width: "82px", height: "82px" }}
              src="assets/profile.png"
              alt=""
            />
            <Stack direction={"column"} justifyContent={"space-between"}>
              <Typography variant="h6" sx={{ fontWeight: 700 }}>
                {item.user_info}
              </Typography>
              <Typography variant="p">
                {item.employee[0] !== undefined
                  ? item.employee[0].job_title
                  : ""}
              </Typography>
              <Typography variant="p">
                {item.phone[0] !== undefined
                  ? `Утас: ${item.phone[0].phone_number}`
                  : ""}
              </Typography>
              {/* <Typography variant="caption">Ажилтны нэр:</Typography> */}
            </Stack>
            <Stack direction={"column"} justifyContent={"space-between"}>
              <Typography variant="p">
                {` Байгууллага: ${
                  item.employee[0] !== undefined
                    ? item.employee[0].company_name
                    : ""
                }`}
              </Typography>
              <Typography variant="p">
                {` Хэлтэс нэгж: ${
                  item.employee[0] !== undefined
                    ? item.employee[0].branch_name
                    : ""
                }`}
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 700 }}>
                {` Огноо: ${item.created_at}`}
              </Typography>

              {/* <Typography variant="caption">Ажилтны нэр:</Typography> */}
            </Stack>
          </Stack>

          <Stack direction={"row"} spacing={2} height={25}>
            <Box width={320}>
              <Stack direction={"row"} spacing={0}>
                {item.comment ? (
                  <>
                    <Button
                      id={"link1" + item.id}
                      type={item.is_closed === 1 ? "success" : "default"}
                      onClick={() => {
                        if (item.comment !== null && item.comment.length > 22) {
                          //console.log(item.comment);
                          setItemComment(item.comment);
                        }
                      }}
                    >
                      {item.comment !== null && item.comment.length > 22
                        ? item.comment.substring(0, 22) + "....."
                        : item.comment}
                    </Button>
                    <Popover
                      target={"#link1" + item.id}
                      showEvent="mouseenter"
                      hideEvent="mouseleave"
                      position="top"
                      width={300}
                    >
                      {item.comment}
                    </Popover>
                  </>
                ) : (
                  ""
                )}
                {item.attachment.length > 0 ? (
                  <IconButton
                    onClick={() => {
                      onButtonClick(
                        `https://api.ptd.ub.gov.mn/api/v1${item.attachment[0].url}`
                      );
                    }}
                    aria-label="delete"
                    size="small"
                  >
                    <AttachFile fontSize="inherit" />
                  </IconButton>
                ) : (
                  ""
                )}
              </Stack>
            </Box>

            {item.is_active ? (
              <Button
                type="success"
                stylingMode="outlined"
                text="Шилжүүлэх"
                onClick={() => {
                  setIsType(1);
                  setIsPop(true);
                }}
                disabled={
                  item.is_active &&
                  item.is_closed === 0 &&
                  props.permistion(78) &&
                  props.loggedUserData.id === item.user_id
                    ? false
                    : true
                }
              />
            ) : (
              ""
            )}
            {item.is_active ? (
              <Button
                type="success"
                // disabled={props.permistion ? false : true}
                stylingMode="outlined"
                onClick={() => {
                  setIsType(2);
                  setIsPop(true);
                }}
                text="Шийдвэрлэж хаах"
                disabled={
                  item.is_active &&
                  item.is_closed === 0 &&
                  props.permistion(77) &&
                  props.loggedUserData.id === item.user_id
                    ? false
                    : true
                }
              />
            ) : (
              ""
            )}
            {item.is_active ? (
              <Button
                type="danger"
                // disabled={props.permistion ? false : true}
                stylingMode="outlined"
                onClick={() => {
                  setIsType(3);
                  setIsPop(true);
                }}
                text="Буцаах"
                disabled={
                  item.is_active &&
                  item.is_closed === 0 &&
                  props.loggedUserData.id === item.user_id
                    ? false
                    : true
                }
              />
            ) : (
              ""
            )}
          </Stack>
        </Stack>
      </Box>
    );
  }
  const syncSelection = (treeView) => {
    // console.log(treeView);
    const selectedEmployees = treeView
      .getSelectedNodes()
      .map((node) => node.itemData);

    //  setSelectedEmployees(selectedEmployees);
    // if (selectedEmployees[0].child !== null) {
    // console.log(selectedEmployees);

    if (selectedEmployees.length > 0) {
      if (selectedEmployees[0].child === null) {
        setDataDrop(selectedEmployees);
        setTreeBoxValue(
          selectedEmployees.length > 0 ? selectedEmployees[0].id : 0
        );
        setIsTreeBoxOpened(false);
      }
    }
    //  }

    // console.log(selectedEmployees);
    if (selectedEmployees.length > 0) {
    }
  };
  // console.log(treeBoxValue);
  const treeViewSelectionChanged = (e) => {
    //console.log(e);
    syncSelection(e.component);
  };

  const treeViewContentReady = (e) => {
    //  console.log(e.component);
    syncSelection(e.component);
  };
  function renderTreeViewItem(item) {
    // console.log(item);
    return `${item.name}`;
  }
  const onTreeBoxOpened = useCallback((e) => {
    if (e.name === "opened") {
      setIsTreeBoxOpened(e.value);
    }
  }, []);
  // console.log(getFeedBackTypeList);
  const treeViewRender = useCallback(
    () => (
      <TreeView
        id="treeview"
        ref={gridRef}
        //width={340}
        //height={320}
        itemsExpr={"child"}
        //parentIdExpr={"child.id"}
        items={feedbackType}
        //items={dataaa}
        selectNodesRecursive={false}
        selectByClick={true}
        // showCheckBoxesMode={"normal"}
        selectionMode={"single"}
        onSelectionChanged={treeViewSelectionChanged}
        onContentReady={treeViewContentReady}
        itemRender={renderTreeViewItem}
      />
    ),
    [feedbackType, treeViewSelectionChanged, treeViewContentReady]
  );
  // console.log(props);
  return (
    <>
      <ScrollView width="100%" height="90%">
        <Form>
          {props.feedBackData != null ? (
            props.feedBackData.source_id === 1 ? (
              <GroupItem colSpan={2}>
                <Item ratio={1}>
                  <Box className="dx-fieldset">
                    <Box className="dx-field">
                      <Box className="dx-field-label">
                        {" "}
                        <ReactAudioPlayer
                          style={{ width: "100%" }}
                          src={props.feedBackData.call_record}
                          preload="auto"
                          //autoPlay
                          controls
                        />
                      </Box>
                      <Box className="dx-field-value"></Box>
                    </Box>
                  </Box>
                </Item>
              </GroupItem>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <GroupItem cssClass="first-group" colCount={4}>
            {/* <SimpleItem render={avatarRender}></SimpleItem> */}

            <GroupItem colSpan={2}>
              <Item ratio={1}>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Байгууллага нэр:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        readOnly
                        value={
                          props.feedBackData.source_id === 1 &&
                          getVehicleCheckData !== null
                            ? getVehicleCheckData.company_name
                            : props.feedBackData.company_name
                        }
                        onValueChange={(v) => {
                          // setRegnum(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>

                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Байгууллага регистер:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        readOnly
                        value={
                          props.feedBackData.source_id === 1 &&
                          getVehicleCheckData !== null
                            ? getVehicleCheckData.company_regnum
                            : props.feedBackData.company_regnum
                        }
                        onValueChange={(v) => {
                          // setRegnum(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Улсын дугаар:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        placeholder={"Улсын дугаар"}
                        readOnly={
                          props.feedBackData.source_id === 1 &&
                          BaseHelper.permitionCheck(
                            4,
                            78,
                            "is_update",
                            props.loggedUserData
                          )
                            ? false
                            : true
                        }
                        value={
                          // getVehicleCheckData === null
                          plateNo
                        }
                        onValueChange={(v) => {
                          setPlateNo(v);
                          // setRegnum(v);
                        }}
                        valueChangeEvent="input"
                      >
                        <TextBoxButton
                          name="password"
                          location="after"
                          options={searchButtonPlate}
                        />
                      </TextBox>
                    </Box>
                  </Box>
                </Box>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Гарааш дугаар:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        readOnly={
                          BaseHelper.permitionCheck(
                            4,
                            78,
                            "is_update",
                            props.loggedUserData
                          ) === false
                            ? true
                            : false
                        }
                        placeholder={"Гарааш дугаар"}
                        value={
                          garageNo !== ""
                            ? garageNo
                            : props.feedBackData.garage_no
                        }
                        onValueChange={(v) => {
                          setGarageNo(v);
                          // setRegnum(v);
                        }}
                        valueChangeEvent="input"
                      >
                        <TextBoxButton
                          name="password"
                          location="after"
                          options={searchButtonGarage}
                        />
                      </TextBox>
                    </Box>
                  </Box>
                </Box>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Төрөл:</Box>
                    <Box className="dx-field-value">
                      {props.feedBackData.source_id === 1 ? (
                        <DropDownBox
                          value={
                            treeBoxValue !== null
                              ? treeBoxValue
                              : props.feedBackData.type_id
                          }
                          opened={isTreeBoxOpened}
                          valueExpr="id"
                          //  inputAttr={ownerLabel}
                          displayExpr="name"
                          placeholder="Төрөл сонгох..."
                          showClearButton={true}
                          // dataSource={feedbackType}
                          dataSource={dataDrop}
                          // onValueChanged={syncTreeViewSelection}
                          onOptionChanged={onTreeBoxOpened}
                          contentRender={treeViewRender}
                        />
                      ) : (
                        // <Lookup

                        //   items={getFeedBackTypeList}
                        //   displayExpr="name"
                        //   valueExpr="id"
                        //   value={
                        //     feedbackType !== ""
                        //       ? feedbackType
                        //       : props.feedBackData.type_id
                        //   }
                        //   placeholder="Санал хүсэлт төрөл сонгох"
                        //   onSelectionChanged={(e) => {
                        //     //  console.log(e.selectedItem.child);
                        //     setFeedbackType(e.selectedItem.id);

                        //     setFeedBackTypeChildData(e.selectedItem.child);
                        //   }}
                        // />
                        <TextBox
                          readOnly
                          value={props.feedBackData.type_name}
                          onValueChange={(v) => {
                            // setRegnum(v);
                          }}
                          valueChangeEvent="input"
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Item>
            </GroupItem>
            <GroupItem colSpan={2}>
              <Item ratio={1}>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Эх үүсвэр:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        readOnly
                        value={props.feedBackData.source_name}
                        onValueChange={(v) => {
                          // setRegnum(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Илгээсэн:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        readOnly
                        value={props.feedBackData.created}
                        onValueChange={(v) => {
                          // setRegnum(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Илгээсэн огноо:</Box>
                    <Box className="dx-field-value">
                      <TextBox
                        readOnly
                        value={props.feedBackData.created_at}
                        onValueChange={(v) => {
                          // setRegnum(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">Нэмэлт мэдээлэл:</Box>
                    <Box className="dx-field-value">
                      <TextArea
                        readOnly={
                          props.feedBackData.source_id === 1 &&
                          BaseHelper.permitionCheck(
                            4,
                            78,
                            "is_update",
                            props.loggedUserData
                          )
                            ? false
                            : true
                        }
                        value={
                          commentGrivance !== ""
                            ? commentGrivance
                            : props.feedBackData.note
                        }
                        onValueChange={(v) => {
                          setCommentGrivance(v);
                        }}
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
                {props.feedBackData.source_id === 1 ? (
                  <Stack direction="row" justifyContent={"space-between"}>
                    <Box></Box>

                    <Button
                      position="end"
                      icon="save"
                      //text="Ажилтаны мэдээлэл хаадгалах"
                      type="default"
                      stylingMode="outlined"
                      onClick={() => {
                        formSubmitUpdate();
                        // editData();
                        //   dispatch(setIsUserEmployeeEdit(true));
                      }}
                    >
                      <LoadIndicator
                        className="button-indicator"
                        shadingColor="rgba(0, 0, 0, 0.2)"
                        visible={false}
                        width={20}
                        height={20}
                        //indicatorSrc="https://i.gifer.com/ZC9Y.gif"
                      />
                      <span className="dx-button-text">
                        {false
                          ? "Уншиж байна түр хүлээнэ үү..."
                          : "Мэдээлэл засаж хадгалах"}
                      </span>
                    </Button>
                  </Stack>
                ) : (
                  ""
                )}
              </Item>
            </GroupItem>
          </GroupItem>
          <GroupItem
            cssClass="first-group"
            caption="Санал гомдол шийдвэрлэлтийн явц"
          >
            {!isFeedBack ? (
              <GroupItem>
                <GrievanceMoveStore
                  branchTreeList={employes}
                  feedBackData={props.feedBackData}
                  isCloseFunc={props.isCloseFun}
                />
              </GroupItem>
            ) : (
              <GroupItem>
                <TileView
                  direction="vertical"
                  baseItemWidth={685}
                  //height={690}
                  baseItemHeight={150}
                  dataSource={getGrievanceDecisionListData}
                  itemRender={TaskTemplate}
                  onItemClick={(e) => {
                    // console.log(e.itemData);
                  }}
                />
              </GroupItem>
            )}
          </GroupItem>
        </Form>
        <Popup
          visible={isPop}
          onHiding={hideInfo}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={true}
          position={"top"}
          title={
            isType === 1 ? "Шилжүүлэх" : isType === 2 ? "Шийдвэрлэх" : "Буцаах"
          }
          showTitle={true}
          width={"60%"}
          height={500}
        >
          {isType === 1 ? (
            <GrievanceMoveStore
              branchTreeList={employes}
              feedBackData={props.feedBackData}
              isCloseFunc={props.isCloseFun}
            />
          ) : isType === 2 ? (
            <Stack direction={"column"} spacing={1}>
              <Typography>Хаах төрөл сонгох</Typography>
              <SelectBox
                // style={{ "margin-left": "12px" }}
                // width="250"
                // height={"30"}
                items={getGrievanceDecisionTypeListData}
                displayExpr="name"
                valueExpr="id"
                value={decissionId}
                placeholder="Хаах төрөл сонгох"
                onValueChanged={(e) => {
                  setDecissionId(e.value);
                }}
              />
              <Divider />
              <Typography>Тайлбар оруулах</Typography>
              <TextArea
                placeholder="Тайлбар оруулах"
                valueChangeEvent="input"
                onValueChanged={(e) => {
                  setComment(e.value);
                  // console.log(e.value);
                }}
                //  value={comment}
              />
              <FileUploader
                selectButtonText="Файл хавсаргах"
                labelText="эсвэл файлаа чирч оруулна уу"
                multiple={false}
                accept={"*"}
                uploadMode={"useForm"}
                //  uploadUrl="https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
                onValueChanged={onSelectedFilesChanged}
              />
              <Box
                sx={{
                  bottom: " 0px",
                  width: "100%",
                  borderTop: `1px solid #aaa`,
                  padding: "10px 14px",
                }}
              >
                <Stack direction="row" justifyContent={"end"}>
                  <Button
                    position="end"
                    icon="save"
                    //text="Ажилтаны мэдээлэл хаадгалах"feedBackData
                    type="default"
                    stylingMode="outlined"
                    onClick={() => {
                      if (isType === 2 && comment !== "") {
                        dispatch(setGrievanceIsEdit(true));
                        submitConfirmFeed();
                        // const data = {
                        //   // user_id: userId,
                        //   grievance_id: props.feedBackData.id,
                        //   comment: comment,
                        //   type_id: decissionId,
                        //   is_transfer: 0,
                        // };
                        // dispatch(grievanceMove(data));
                        // setIsPop(false);
                        // props.isCloseFun();
                        // if (getGrievanceIsEdit) {

                        //}
                      } else {
                        // console.log(getGrievanceIsEdit);
                        notify(
                          {
                            message: "Тайлбар оруулна уу!!!",
                            width: 450,
                          },
                          "error"
                        );
                      }
                    }}
                  >
                    <LoadIndicator
                      className="button-indicator"
                      shadingColor="rgba(0, 0, 0, 0.2)"
                      visible={getGrievanceIsEdit}
                      width={20}
                      height={20}
                      //indicatorSrc="https://i.gifer.com/ZC9Y.gif"
                    />
                    <span className="dx-button-text">
                      {getGrievanceIsEdit ? "Түр хүлээн үү!!!" : "Хадгалах"}
                    </span>
                  </Button>
                </Stack>
              </Box>
            </Stack>
          ) : (
            <Box>
              <Typography>Тайлбар оруулах</Typography>
              <TextArea
                placeholder="Тайлбар оруулах"
                valueChangeEvent="input"
                onValueChanged={(e) => {
                  setComment(e.value);
                  // console.log(e.value);
                }}
                //  value={comment}
              />
              <Stack direction="row" justifyContent={"end"}>
                <Button
                  position="end"
                  icon="save"
                  //text="Ажилтаны мэдээлэл хаадгалах"feedBackData
                  type="default"
                  stylingMode="outlined"
                  onClick={() => {
                    // console.log(getGrievanceDecisionListData);
                    if (isType === 3 && comment !== "") {
                      const decissionId = getGrievanceDecisionListData.findLast(
                        (e) => e.user_id === props.loggedUserData.id
                      );
                      // const decissionId = getGrievanceDecisionListData.find(
                      //   (e) => e.user_id === 73
                      // );
                      //console.log(decissionId.id);
                      // dispatch(setGrievanceIsEdit(true));
                      const data = {
                        // user_id: userId,
                        id: decissionId.id,
                        comment: comment,
                      };
                      dispatch(backDecisionFun(data));
                      setIsPop(false);
                      // props.isCloseFun();
                      // if (getGrievanceIsEdit) {
                      //}
                    } else {
                      // console.log(getGrievanceIsEdit);
                      notify(
                        {
                          message: "Тайлбар оруулна уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                >
                  <LoadIndicator
                    className="button-indicator"
                    shadingColor="rgba(0, 0, 0, 0.2)"
                    visible={getGrievanceIsEdit}
                    width={20}
                    height={20}
                    //indicatorSrc="https://i.gifer.com/ZC9Y.gif"
                  />
                  <span className="dx-button-text">
                    {getGrievanceIsEdit ? "Түр хүлээн үү!!!" : "Хадгалах"}
                  </span>
                </Button>
              </Stack>
            </Box>
          )}
        </Popup>
      </ScrollView>
      {/* <Box
        sx={{
          position: "absolute",
          bottom: " 0px",
          width: "100%",
          borderTop: `1px solid #aaa`,
          padding: "10px 14px",
        }}
      >
        <Stack direction="row" justifyContent={"space-between"}>
          <Button
            text="Буцах"
            icon="back"
            onClick={() => {
              // props.searchVehBtn();
              setSoum_district_id(0);
            }}
          />

          <Button
            position="end"
            icon="save"
            text="Хадгалах"
            type="default"
            onClick={() => {
              editData();
            }}
          />
        </Stack>
      </Box> */}
    </>
  );
};

export default FeedBackConfirmed;
