import { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";

import * as am5percent from "@amcharts/amcharts5/percent";

import dark from "@amcharts/amcharts5/themes/Dark";

function ViolationCountDetial(props) {
  const [data, setData] = useState([
    {
      id: 1,
      code: "phone",
      name: "Утас",
      total_count: 22647,
    },
  ]);
  useEffect(() => {
    if (props.dashboardData != null) {
      setData(props.dashboardData.violation.type);
    }
  }, [props]);
  // console.log(props);
  // console.log(data);
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdivViolationDetial");
    root._logo.dispose();

    root.setThemes([dark.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.horizontalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "total_count",
        legendValueText: "- [bold {fill}]{value}[/]",
        categoryField: "name",
        alignLabels: false,
      })
    );
    series.labels.template.setAll({
      textType: "circular",
      forceHidden: true,
      centerX: 0,
      centerY: 0,
    });
    series.labels.template.set("forceHidden", true);
    series.ticks.template.set("forceHidden", true);
    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(data);

    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(20),
        x: am5.percent(60),
        layout: root.verticalLayout,
      })
    );

    legend.data.setAll(series.dataItems);

    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div
      id="chartdivViolationDetial"
      style={{ width: "100%", height: "350px" }}
    ></div>
  );
}
export default ViolationCountDetial;
