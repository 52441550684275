import {
  Avatar,
  Box,
  IconButton,
  Link,
  ListItemButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  CampaignTwoTone,
  Help,
  HelpTwoTone,
  LogoutOutlined,
  Person,
} from "@mui/icons-material";
import { Colors } from "../../styles/theme";
import { logout } from "../../store/reducers/user/auth";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem } from "react-pro-sidebar";

const FooterBar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const colorMode = useContext(ColorModeContext);
  const settings = ["Хувийн мэдээлэл", "Системээс гарах"];
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const logedUser = useSelector((state) => state.auth.user);

  return (
    <Box
      display="flex"
      justifyContent="end"
      p={2}
      sx={{
        background: "#1F2A40!important",
        position: "fixed",
        left: 0,
        bottom: 0,
        right: 0,
        height: "60px",
        opacity: 0.7,
        zIndex: 999,
      }}
    >
      {/* SEARCH BAR */}

      {/* ICONS */}

      <Typography sx={{ alignContent: "center", textAlign: "end", mr: 2 }}>
        Хаяг: Улаанбаатар хот, Хан-Уул дүүрэг, 23 дугаар хороо, Нийслэлийн
        нутгийн захиргааны цогцолбор “Б” блок 4 давхарт
      </Typography>
      <Box display={"flex"}>
        <Typography sx={{ alignContent: "center", textAlign: "end", mr: 2 }}>
          developed by: Инфокон ХХК
        </Typography>
        <Box>
          {/* <Typography variant="body1" sx={{ alignContent: "center", mr: 1 }}>
          Developed by:Инфокон ххк
        </Typography> */}
          <Link
            href="https://www.infocon.mn/"
            underline="hover"
            target="_blank"
          >
            <img
              src="../../../assets/infocon.png"
              alt=""
              style={{ width: "90px" }}
            />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default FooterBar;
