import { Box, Divider, Stack, TextField, Typography } from "@mui/material";

import Button from "devextreme-react/button";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { Box as BoxDevExt, Item } from "devextreme-react/box";
import { useCallback, useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";
import ScrollView from "devextreme-react/scroll-view";
import { useDispatch, useSelector } from "react-redux";
import {
  CheckBox,
  Form,
  LoadIndicator,
  LoadPanel,
  Lookup,
  TextArea,
} from "devextreme-react";

import { DropDownOptions } from "devextreme-react/autocomplete";

import {
  getEmployeeInfoData,
  getUserJobposition,
  getUserLocation,
  setIsUserEmployeeEdit,
  userEmployeeEditData,
} from "../../store/reducers/user/auth";
import notify from "devextreme/ui/notify";
import { GroupItem } from "devextreme-react/form";
import { Label } from "devextreme-react/data-grid";
import { getEmployeTreeList } from "../../store/reducers/employe";
import { getCompany } from "../../store/reducers/company";
const EmployeInfo = (props) => {
  const dispatch = useDispatch();
  const [regnum, setRegnum] = useState("");
  const getEmployeeDataList = useSelector(
    (state) => state.auth.employeeDataList
  );
  const getEmployeTreeListData = useSelector(
    (state) => state.employe.employeTreeListData
  );
  const getCompanyDatatData = useSelector((state) => state.company.companyData);
  const getUserJobpositionData = useSelector(
    (state) => state.auth.userJobposition
  );
  const getisUserEmployeeEdit = useSelector(
    (state) => state.auth.isUserEmployeeEdit
  );
  // console.log(getEmployeTreeListData);
  // const getUserSexDataList = useSelector((state) => state.auth.userSexData);
  const [user_type_id, setUserTypeId] = useState("");
  const [branch_id, setBranch_id] = useState(0);
  const [company_id, setCompany_id] = useState(0);
  const [job_position_id, setJob_position_id] = useState(0);
  const [job_title, setJob_title] = useState("");
  const [salary, setSalary] = useState(0);
  const [note, setNote] = useState("");
  const [buttonText, setButtonText] = useState("Ажилтаны мэдээлэл хаадгалах");
  const [isEdit, setEdit] = useState(false);
  const filter = useSelector((state) => state.company.filter);
  const getDisplayExpr = (item) => {
    return item ? `${item.name}` : "";
  };

  useEffect(() => {
    setBranch_id(
      getEmployeeDataList !== null ? getEmployeeDataList[0].branch_id : 0
    );
    setCompany_id(
      getEmployeeDataList !== null ? getEmployeeDataList[0].company_id : 0
    );
    setJob_position_id(
      getEmployeeDataList !== null ? getEmployeeDataList[0].job_position_id : 0
    );
    setJob_title(
      getEmployeeDataList !== null ? getEmployeeDataList[0].job_title : 0
    );
    setSalary(getEmployeeDataList !== null ? getEmployeeDataList[0].salary : 0);
    setNote(getEmployeeDataList !== null ? getEmployeeDataList[0].note : "");
  }, [getEmployeeDataList, dispatch]);
  useEffect(() => {
    if (getEmployeTreeListData === null) {
      dispatch(getEmployeTreeList());
    }
  }, [getEmployeTreeListData, dispatch]);
  useEffect(() => {
    if (getUserJobpositionData === null) {
      dispatch(getUserJobposition());
    }
  }, [getUserJobpositionData, dispatch]);
  useEffect(() => {
    if (getCompanyDatatData === null) {
      dispatch(getCompany(filter));
    }
  }, [filter, getCompanyDatatData, dispatch]);
  //console.log(getEmployeeDataList[0].id);
  const editData = () => {
    // dispatch(editTravelList(travelId));
    //console.log(dateStart);
    //let startDate = dateStart.toISOString().split("T")[0];
    //const startDate = dateStart.toISOString().split("T")[0];
    // const endDate = dateEnd.toISOString().split("T")[0];
    if (company_id !== "") {
      const formData = new FormData();
      formData.append("id", getEmployeeDataList[0].id);
      formData.append("user_id", props.userData.id);
      formData.append("company_id", company_id);
      formData.append("branch_id", branch_id);
      formData.append("job_position_id", job_position_id);
      formData.append("job_title", job_title);
      formData.append("salary", salary);
      formData.append("note", note);

      // console.log("object");
      dispatch(userEmployeeEditData(formData));
      //props.userUpdate(formData);
      // formReset();
    } else {
      notify(
        {
          message: "Мэдээлэл гүйцэд оруулна уу!!!",
          width: 450,
        },
        "error"
      );
    }
  };
  console.log(getEmployeTreeListData);
  return (
    <>
      {getEmployeTreeListData === null ? (
        <LoadPanel
          visible={true}
          shadingColor="rgba(0,0,0,0.4)"
          shading={true}
          showPane={false}
          id="large-indicator"
          height={60}
          width={60}
          message="Уншиж байна түр хүлээнэ үү"
        />
      ) : (
        <Form
          formData={getEmployeeDataList}
          // onFieldDataChanged={formFieldDataChanged}
        >
          <GroupItem cssClass="second-group" colCount={2}>
            <GroupItem>
              <Box className="dx-field">
                <Box className="dx-field-label">Байгууллага:</Box>
                <Box className="dx-field-value">
                  <Lookup
                    onSelectionChanged={(e) => {
                      // props.filterCompany(e.selectedItem.ID);
                      // console.log(e.selectedItem.ID);
                      // setUserTypeId(e.selectedItem.ID);
                      dispatch(
                        getEmployeTreeList({ company_id: e.selectedItem.id })
                      );
                      setCompany_id(e.selectedItem.id);
                      //console.log(e.selectedItem.id);
                    }}
                    value={company_id}
                    items={getCompanyDatatData}
                    // defaultValue={
                    //   getUserProfile !== null
                    //     ? getUserProfile[0].aimag_city_id
                    //     : 0
                    // }
                    displayExpr={getDisplayExpr}
                    valueExpr="id"
                  >
                    <DropDownOptions showTitle={false} />
                  </Lookup>
                </Box>
              </Box>
              <Box className="dx-field">
                <Box className="dx-field-label">Хэлтэс:</Box>
                <Box className="dx-field-value">
                  <Lookup
                    onSelectionChanged={(e) => {
                      // props.filterCompany(e.selectedItem.ID);
                      // console.log(e.selectedItem.ID);
                      setBranch_id(e.selectedItem.id);
                    }}
                    value={branch_id}
                    items={getEmployeTreeListData}
                    // defaultValue={
                    //   getUserProfile !== null
                    //     ? getUserProfile[0].aimag_city_id
                    //     : 0
                    // }
                    displayExpr={getDisplayExpr}
                    valueExpr="id"
                  >
                    <DropDownOptions showTitle={false} />
                  </Lookup>

                  {/* <TextBox
                          value={aimag_city_name}
                          valueChangeEvent="input"
                        /> */}
                </Box>
              </Box>

              <Box className="dx-field">
                <Box className="dx-field-label">Албан тушаалын төрөл:</Box>
                <Box className="dx-field-value">
                  <Lookup
                    onSelectionChanged={(e) => {
                      // props.filterCompany(e.selectedItem.ID);
                      // console.log(e.selectedItem.ID);
                      setJob_position_id(e.selectedItem.id);
                      //   setBag_khoroo_id(e.selectedItem.id);
                    }}
                    value={job_position_id}
                    items={getUserJobpositionData}
                    // defaultValue={
                    //   getUserProfile !== null
                    //     ? getUserProfile[0].aimag_city_id
                    //     : 0
                    // }
                    displayExpr={getDisplayExpr}
                    valueExpr="id"
                  >
                    <DropDownOptions showTitle={false} />
                  </Lookup>
                </Box>
              </Box>
            </GroupItem>
            <GroupItem>
              <Box className="dx-field">
                <Box className="dx-field-label">Албан тушаал:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    valueChangeEvent="input"
                    onValueChanged={(e) => {
                      setJob_title(e.value);
                      // console.log(e.value);
                    }}
                    value={job_title}
                  />
                </Box>
              </Box>
              <Box className="dx-field">
                <Box className="dx-field-label">Цалин:</Box>
                <Box className="dx-field-value">
                  <TextBox
                    valueChangeEvent="input"
                    onValueChanged={(e) => {
                      setSalary(e.value);
                      // console.log(e.value);
                    }}
                    value={salary}
                  />
                </Box>
              </Box>
              <Box className="dx-field">
                <Box className="dx-field-label">Нэмэлт мэдээлэл:</Box>
                <Box className="dx-field-value">
                  <TextArea
                    valueChangeEvent="input"
                    onValueChanged={(e) => {
                      setNote(e.value);
                      // console.log(e.value);
                    }}
                    value={note}
                  />
                </Box>
              </Box>
            </GroupItem>
          </GroupItem>
          <GroupItem>
            <Box
              sx={{
                bottom: " 0px",
                width: "100%",
                borderTop: `1px solid #aaa`,
                padding: "10px 14px",
              }}
            >
              <Stack direction="row" justifyContent={"space-between"}>
                <Box></Box>

                <Button
                  position="end"
                  icon="save"
                  //text="Ажилтаны мэдээлэл хаадгалах"
                  type="default"
                  stylingMode="outlined"
                  onClick={() => {
                    editData();

                    dispatch(setIsUserEmployeeEdit(true));
                  }}
                >
                  <LoadIndicator
                    className="button-indicator"
                    shadingColor="rgba(0, 0, 0, 0.2)"
                    visible={getisUserEmployeeEdit}
                    width={20}
                    height={20}
                    //indicatorSrc="https://i.gifer.com/ZC9Y.gif"
                  />
                  <span className="dx-button-text">
                    {getisUserEmployeeEdit
                      ? "Уншиж байна түр хүлээнэ үү..."
                      : "Ажилтаны мэдээлэл хадгалах"}
                  </span>
                </Button>
              </Stack>
            </Box>
          </GroupItem>
        </Form>
      )}
    </>
  );
};

export default EmployeInfo;
