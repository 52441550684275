import { createSlice } from "@reduxjs/toolkit";

import notify from "devextreme/ui/notify";
import {
  getDashboardDataCall,
  getDriverReportCall,
  getDriverVehicleActivityCall,
  getInspectorReportCall,
  getLegalProvisionReportCall,
  getLogCall,
  getPenaltyReportCall,
  getPorkurorReportCall,
  getServiceTypeReportCall,
  getVehicleCountAgeReportCall,
  getVehicleCountBuildReportCall,
  getVehicleCountMarkReportCall,
  getVehicleDriverReportCall,
  getViolationAanTypeReportCall,
  getViolationCompanyReportCall,
  getViolationDetectionReportCall,
  getViolationInspectionDetectReportCall,
  getViolationInspectionTypeReportCall,
  getViolationInspectorWorkCall,
  getViolationSubjectReportCall,
  getViolationTypeReportCall,
  reportGrievanceCall2,
  reportGrievanceCall3,
  reportGrievanceConfirmedCall,
  reportHumanCall1,
  reportHumanCall2,
  reportHumanCall3,
} from "../../utils/services/reportService";
import moment from "moment";
export const slice = createSlice({
  name: "report",
  initialState: {
    porkurorReportData: null,
    serviceTypeReportData: null,
    inspectorReportData: null,
    penaltyReportData: null,
    legalProvisionReportData: null,
    violationTypeReportData: null,
    violationDetectionReportData: null,
    violationCompanyReportData: null,
    violationSubjectReportData: null,
    violationAanTypeReportData: null,
    violationInspectionDetectReportData: null,
    violationInspectionTypeReportData: null,
    vehicleCountBuildReportData: null,
    vehicleCountMarkReportData: null,
    vehicleCountAgeReportData: null,
    vehicleDriverReportData: null,
    grievanceConfirmedList: null,
    grievanceList2: null,
    violationInspectorWork: null,
    vehicleDriverActivity: null,
    grievanceList3: null,
    humanList1: null,
    humanList2: null,
    humanList3: null,
    driverReportData: null,
    dashboardData: null,
    feedbackDateType: 0,
    violationDateType: 1,
    filter: {
      start_date: moment().startOf("month").format("YYYY-MM-DD"),

      end_date: moment().subtract(0, "days").format("YYYY-M-D"),
    },
    isLoading: true,
    logData: null,
  },

  reducers: {
    setPorkurorReportData: (state, { payload }) => {
      state.porkurorReportData = payload;
    },
    setFeedbackDateType: (state, { payload }) => {
      //console.log(payload);
      state.feedbackDateType = payload;
    },
    setViolationDateType: (state, { payload }) => {
      //console.log(payload);
      state.violationDateType = payload;
    },
    setDashboardData: (state, { payload }) => {
      //console.log(payload);
      state.dashboardData = payload;
    },
    setServiceTypeReportData: (state, { payload }) => {
      //console.log(payload);
      state.serviceTypeReportData = payload;
    },
    setViolationInspectorWork: (state, { payload }) => {
      //console.log(payload);
      state.violationInspectorWork = payload;
    },
    setInspectorReportData: (state, { payload }) => {
      //console.log(payload);
      state.inspectorReportData = payload;
    },
    setPenaltyReportData: (state, { payload }) => {
      //console.log(payload);
      state.penaltyReportData = payload;
    },
    setLegalProvisionReportData: (state, { payload }) => {
      //console.log(payload);
      state.legalProvisionReportData = payload;
    },
    setViolationTypeReportData: (state, { payload }) => {
      //console.log(payload);
      state.violationTypeReportData = payload;
    },
    setViolationDetectionReportData: (state, { payload }) => {
      //console.log(payload);
      state.violationDetectionReportData = payload;
    },
    setViolationCompanyReportData: (state, { payload }) => {
      //console.log(payload);
      state.violationCompanyReportData = payload;
    },
    setViolationSubjectReportData: (state, { payload }) => {
      //console.log(payload);
      state.violationSubjectReportData = payload;
    },
    setViolationAanTypeReportData: (state, { payload }) => {
      //console.log(payload);
      state.violationAanTypeReportData = payload;
    },
    setViolationInspectionDetectReportData: (state, { payload }) => {
      //console.log(payload);
      state.violationInspectionDetectReportData = payload;
    },
    setViolationInspectionTypeReportData: (state, { payload }) => {
      //console.log(payload);
      state.violationInspectionTypeReportData = payload;
    },
    setVehicleCountBuildReportData: (state, { payload }) => {
      //console.log(payload);
      state.vehicleCountBuildReportData = payload;
    },
    setVehicleCountMarkReportData: (state, { payload }) => {
      //console.log(payload);
      state.vehicleCountMarkReportData = payload;
    },
    setVehicleCountAgeReportData: (state, { payload }) => {
      //console.log(payload);
      state.vehicleCountAgeReportData = payload;
    },
    setVehicleDriverReportData: (state, { payload }) => {
      //console.log(payload);
      state.vehicleDriverReportData = payload;
    },
    setDriverReportData: (state, { payload }) => {
      //console.log(payload);
      state.driverReportData = payload;
    },
    setGrievanceConfirmedList: (state, { payload }) => {
      //console.log(payload);
      state.grievanceConfirmedList = payload;
    },
    setGrievanceList2: (state, { payload }) => {
      //console.log(payload);
      state.grievanceList2 = payload;
    },
    setHumanList1: (state, { payload }) => {
      //console.log(payload);
      state.humanList1 = payload;
    },
    setHumanList2: (state, { payload }) => {
      //console.log(payload);
      state.humanList2 = payload;
    },
    setHumanList3: (state, { payload }) => {
      //console.log(payload);
      state.humanList3 = payload;
    },
    setVehicleDriverActivity: (state, { payload }) => {
      //console.log(payload);
      state.vehicleDriverActivity = payload;
    },
    setGrievanceList3: (state, { payload }) => {
      //console.log(payload);
      state.grievanceList3 = payload;
    },

    setFilter: (state, { payload }) => {
      //console.log(payload);
      state.filter = payload;
    },
    setRemFilter: (state, { payload }) => {
      //console.log(payload);
      state.filter = {};
    },
    setLoading: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = payload;
    },
    setLogData: (state, { payload }) => {
      // console.log(payload);
      state.logData = payload;
    },
  },
});

export const {
  setPorkurorReportData,
  setLoading,
  setRemFilter,
  setServiceTypeReportData,
  setInspectorReportData,
  setPenaltyReportData,
  setLegalProvisionReportData,
  setViolationTypeReportData,
  setViolationDetectionReportData,
  setViolationCompanyReportData,
  setViolationSubjectReportData,
  setViolationAanTypeReportData,
  setViolationInspectionDetectReportData,
  setViolationInspectionTypeReportData,
  setVehicleCountBuildReportData,
  setVehicleCountMarkReportData,
  setVehicleCountAgeReportData,
  setVehicleDriverReportData,
  setDriverReportData,
  setDashboardData,
  setGrievanceConfirmedList,
  setGrievanceList2,
  setGrievanceList3,
  setHumanList1,
  setHumanList2,
  setHumanList3,
  setViolationInspectorWork,
  setVehicleDriverActivity,
  setFilter,
  setFeedbackDateType,
  setViolationDateType,
  setLogData,
} = slice.actions;

export const getDriverReportFun = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await getDriverReportCall(filter);
    // report/activity_1
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setDriverReportData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getLogFun = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await getLogCall(filter);
    // report/activity_1
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setLogData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getViolationInspectorWorkFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationInspectorWorkCall(filter);
      // report/activity_1
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setViolationInspectorWork(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getDriverVehicleActivityFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getDriverVehicleActivityCall(filter);
      // report/activity_1
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setVehicleDriverActivity(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const reportGrievanceConfirmedFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await reportGrievanceConfirmedCall(filter);
      // report/activity_1
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setGrievanceConfirmedList(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        dispatch(setGrievanceConfirmedList([]));
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const reportGrievanceFun2 = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await reportGrievanceCall2(filter);
    // report/activity_1
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setGrievanceList2(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      dispatch(setGrievanceList2([]));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const reportGrievanceFun3 = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await reportGrievanceCall3(filter);
    // report/activity_1
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setGrievanceList3(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      dispatch(setGrievanceList3([]));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const reportHumanFun1 = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await reportHumanCall1(filter);
    // report/hr_1
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setHumanList1(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      dispatch(setHumanList1([]));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const reportHumanFun2 = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await reportHumanCall2(filter);
    // report/hr_1
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setHumanList2(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      dispatch(setHumanList2([]));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const reportHumanFun3 = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await reportHumanCall3(filter);
    // report/hr_1
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setHumanList3(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      dispatch(setHumanList3([]));
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getDashboardDataFun = () => async (dispatch, getState) => {
  try {
    const responseData = await getDashboardDataCall();
    // report/activity_1
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setDashboardData(responseData.data));
      // dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getVehicleDriverReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getVehicleDriverReportCall(filter);
      // report/activity_1
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setVehicleDriverReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getVehicleCountAgeReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getVehicleCountAgeReportCall(filter);
      // report/activity_1
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setVehicleCountAgeReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getVehicleCountMarkReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getVehicleCountMarkReportCall(filter);
      // report/activity_1
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setVehicleCountMarkReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getVehicleCountBuildReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getVehicleCountBuildReportCall(filter);
      // report/activity_1
      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setVehicleCountBuildReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getPorkurorReportFun = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await getPorkurorReportCall(filter);

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setPorkurorReportData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      dispatch(setPorkurorReportData([]));
      // notify(
      //   {
      //     message: responseData.status.message,
      //     width: 450,
      //   },
      //   "error"
      // );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getServiceTypeReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getServiceTypeReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setServiceTypeReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        dispatch(setServiceTypeReportData([]));
        // notify(
        //   {
        //     message: responseData.status.message,
        //     width: 450,
        //   },
        //   "error"
        // );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getInspectorReportFun = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await getInspectorReportCall(filter);

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setInspectorReportData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getPenaltyReportFun = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await getPenaltyReportCall(filter);

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setPenaltyReportData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setVehicleInspectionData(responseData.data));
    } else {
      /// console.log(responseData);
      notify(
        {
          message: responseData.status.message,
          width: 450,
        },
        "error"
      );
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getLegalProvisionReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getLegalProvisionReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setLegalProvisionReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationTypeReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationTypeReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setViolationTypeReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationDetectionReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationDetectionReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setViolationDetectionReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationCompanyReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationCompanyReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setViolationCompanyReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationSubjectReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationSubjectReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setViolationSubjectReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationAanTypeReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationAanTypeReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setViolationAanTypeReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationInspectionDetectReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationInspectionDetectReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setViolationInspectionDetectReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getViolationInspectionTypeReportFun =
  (filter) => async (dispatch, getState) => {
    try {
      const responseData = await getViolationInspectionTypeReportCall(filter);

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setViolationInspectionTypeReportData(responseData.data));
        dispatch(setLoading(false));
        // dispatch(setVehicleInspectionData(responseData.data));
      } else {
        /// console.log(responseData);
        notify(
          {
            message: responseData.status.message,
            width: 450,
          },
          "error"
        );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };

export default slice.reducer;
