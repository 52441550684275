import { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";

import * as am5percent from "@amcharts/amcharts5/percent";

import dark from "@amcharts/amcharts5/themes/Dark";

function FeedBackTypeCount(props) {
  const [data, setData] = useState([
    {
      id: 1,
      code: "phone",
      name: "Утас",
      total_count: 22647,
    },
  ]);
  useEffect(() => {
    if (props.dashboardData != null) {
      setData(props.dashboardData.grievance.source);
    }
  }, [props]);
  //   console.log(props);
  //   console.log(data);
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv2");
    root._logo.dispose();

    root.setThemes([dark.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: 180,
        endAngle: 360,
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    // start and end angle must be set both for chart and series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        startAngle: 180,
        endAngle: 360,
        valueField: "total_count",
        categoryField: "name",
        alignLabels: false,
      })
    );

    series.states.create("hidden", {
      startAngle: 180,
      endAngle: 180,
    });

    series.slices.template.setAll({
      cornerRadius: 5,
    });

    series.ticks.template.setAll({
      forceHidden: true,
    });

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(data);

    series.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [data, props]);

  return <div id="chartdiv2" style={{ width: "100%", height: "380px" }}></div>;
}
export default FeedBackTypeCount;
