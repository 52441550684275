import { Box, Chip, useTheme } from "@mui/material";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Column,
  SortByGroupSummaryInfo,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import React, { useState, useRef, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { DataGridConfig } from "../../config";
import { getLogFun, setFilter } from "../../store/reducers/report";
import {
  AirportShuttle,
  DirectionsBusFilled,
  Face6,
  LocalTaxi,
} from "@mui/icons-material";
import { Colors } from "../../styles/theme";

const LogList = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const getLogs = useSelector((state) => state.report.logData);
  const filter = useSelector((state) => state.report.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);
  const start = moment().add(-4, "y");
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const setFilters = (field, value) => {
    //console.log(field, value);
    //dispatch(setFilter({ ...filter, [field]: value }));
  };
  useEffect(() => {
    if (getLogs === null) {
      // dispatch(getLogFun(filter));
      dispatch(getLogFun(filter));
    } else {
      setFormData(getLogs);
    }
  }, [filter, dispatch, getLogs]);

  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getLogFun(filter));
    }
  }, [filter, dispatch]);

  const gridRef = useRef();

  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}

      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(setFilter(filter));
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"user"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(
                  setFilter({
                    ...filter,
                    type_id: 1,
                  })
                );
              }}
              type={"success"}
              text="Жолооч"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              style={{ "background-color": "orange" }}
              icon={"car"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(
                  setFilter({
                    ...filter,
                    type_id: 2,
                  })
                );
              }}
              type={"danger"}
              text="Тээврийн хэрэгсэл"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"bulletlist"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(
                  setFilter({
                    ...filter,
                    service_type_name: null,
                  })
                );
              }}
              type={"normal"}
              text="Бүгд"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box sx={{ ml: 2 }}>
            <Button
              style={{ color: "#00ff00" }}
              icon={"favorites"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(
                  setFilter({
                    ...filter,
                    service_type_name: "Үндсэн",
                  })
                );
              }}
              type={"normal"}
              text="Үндсэн"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box sx={{ ml: 2 }}>
            <Button
              style={{ color: "yellow" }}
              icon={"user"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(
                  setFilter({
                    ...filter,
                    service_type_name: "Хүүхдийн автобус",
                  })
                );
              }}
              type={"normal"}
              text="Хүүхдийн"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box sx={{ ml: 2 }}>
            <Button
              style={{ color: "#ffb200" }}
              icon={"car"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(
                  setFilter({
                    ...filter,
                    service_type_name: "Такси",
                  })
                );
              }}
              type={"normal"}
              text="такси"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(
                  setFilter({
                    ...filter,
                    service_type_name: "Буухиа",
                  })
                );
              }}
              type={"normal"}
              text="Буухиа"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"product"}
              onClick={() => {
                // dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
                dispatch(
                  setFilter({
                    ...filter,
                    service_type_name: "Ажилчдын автобус",
                  })
                );
              }}
              type={"normal"}
              text="Ажилчдын"
            />
          </Box>
        </Item>

        <Item location="after">
          <Box>
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        remoteOperations={false}
        dataSource={formData}
        //keyExpr="id"
        //  keyExpr="violation_date"
        id="gridContainer"
        wordWrapEnabled={false}
        height={600}
        //summary={}
        // columns={gridColumns}
      >
        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />
        <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
          // fixed={true}
        />
        <Column
          dataField="type_name"
          format="fixedPoint"
          width={150}
          caption={"Төрөл"}
          cellRender={(v) => {
            // console.log(v);
            return v.data.type_name === "Жолооч" ? (
              <Chip
                size="small"
                icon={<Face6 />}
                label={v.data.type_name}
                color="success"
                variant="outlined"
              />
            ) : (
              <Chip
                size="small"
                icon={<DirectionsBusFilled />}
                label={v.data.type_name}
                color="warning"
                variant="outlined"
              />
            );
          }}
        />
        <Column dataField="content_tag" format="fixedPoint" caption={"РД/УД"} />
        <Column
          dataField="content_name"
          format="fixedPoint"
          caption={"Овог,Нэр/Марк,Загвар"}
        />
        <Column
          dataField="action_type"
          format="fixedPoint"
          caption={"Хийсэн үйлдэл"}
          cellRender={(v) => {
            return v.data.action_type.includes("Баталгаажуулсан") ? (
              <div style={{ color: Colors.success }}>{v.data.action_type}</div>
            ) : v.data.action_type.includes("Баталгаажсан") ? (
              <div style={{ color: Colors.warning }}>{v.data.action_type}</div>
            ) : v.data.action_type.includes("Цуцалсан") ? (
              <div style={{ color: Colors.danger }}>{v.data.action_type}</div>
            ) : v.data.action_type.includes("Шинээр үүсгэсэн") ? (
              <div style={{ color: Colors.success }}>{v.data.action_type}</div>
            ) : (
              <div style={{ color: Colors.white }}>{v.data.action_type}</div>
            );
          }}
        />

        <Column dataField="note" format="fixedPoint" caption={"Тайлбар"} />

        <Column
          dataField="service_type_name"
          format="fixedPoint"
          width={120}
          caption={"ҮА-ны төрөл"}
          cellRender={(v) => {
            // console.log(v);
            return v.data.service_type_name === "Үндсэн" ? (
              <Chip
                sx={{ border: "0 !important" }}
                size="small"
                icon={<DirectionsBusFilled />}
                label={v.data.service_type_name}
                // color="info"
                variant="outlined"
              />
            ) : v.data.service_type_name === "Хүүхдийн автобус" ? (
              <Chip
                sx={{ border: "0 !important" }}
                icon={<AirportShuttle />}
                label={v.data.service_type_name}
                //color="info"
                variant="outlined"
              />
            ) : (
              <Chip
                sx={{ border: "0 !important" }}
                icon={<LocalTaxi />}
                label={v.data.service_type_name}
                // color="info"
                variant="outlined"
              />
            );
          }}
        />
        <Column
          dataField="company_name"
          format="fixedPoint"
          caption={"ААНБ-н нэр"}
        />
        <Column
          dataField="lastname"
          format="fixedPoint"
          caption={"Үйлдэл хийсэн"}
          cellRender={(v) => {
            if (v.data.firstname !== null) {
              return (
                <div>
                  {v.data.lastname} {v.data.firstname}
                </div>
              );
            } else {
              return <div>Вэб сервис</div>;
            }
          }}
        />

        <Column dataField="action_date" format="fixedPoint" caption={"Огноо"} />

        <SortByGroupSummaryInfo summaryItem={0} />
      </DataGrid>
    </>
  );
};

export default LogList;
