import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import PorkurorReportList from "../../components/reports/porkurorReport/porkuror_reportt_list";

const PorkurorReport = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box m="20px">
      <Header title="Тайлан прокурор" subtitle="Тайлан прокурор жагсаалт" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <PorkurorReportList
          //  porkurorReportListData={getPorkurorReportData}
          // comptData={compName}
          //  isCompanyFilter={isCompanyFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PorkurorReport;
