import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useEffect, useLayoutEffect, useState } from "react";
import { Button } from "devextreme-react";
import DriverCountDetial from "./driverCountDetial";

const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function ChartDetialDriver(props) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);

  //console.log({ ui });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState(false);
  console.log(props);
  return (
    <>
      <BootstrapDialog
        // onClose={(event, reason) => {
        //   if (reason && reason === "backdropClick") {
        //     return;
        //   } else {
        //     return onClose();
        //   }
        //   // // console.log(reason);
        // }}

        maxWidth="lg"
        onClose={props.onClose}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.mode === "dark" ? " #1F2A40" : "#fff",
            backgroundImage: "none",
            borderRadius: "16px",
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Жолооч</DialogTitle>
        {/* <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton> */}
        <DialogContent dividers>
          <Box
            sx={{
              width: "650px",
              // height: "321px",
              paddingTop: "34px",
              paddingRight: "20px",
              paddingLeft: "10px",
              paddingBottom: "20px",
              borderRadius: "16px",
            }}
          >
            <Stack direction={"column"} alignItems={"center"} spacing={2}>
              <DriverCountDetial
                dashboardData={props.dashboardData}
                type={"vehicle"}
              />
              {/* <Typography
                sx={{
                  fontWeight: 700,
                  textAlign: "center",
                }}
              >
                sdvsdvds
              </Typography> */}
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          {/* <Button
            sx={{
              color: "#ffff",
              background: "#0B3599",
              borderRadius: "8px",
              fontSize: ui.fontContent + "px",
              paddingLeft: "32px",
              paddingRight: "32px",
              paddingTop: "12px",
              paddingBottom: "12px",
              "&:hover": {
                background:
                  theme.palette.mode === "dark" ? "#0B3599" : "#0B3599",
                boxShadow: "none",
              },
            }}
            autoFocus
            onClick={() => {
              // dispatch(setClearGiverance());
              // onClose();
              //submitFeedBack();
            }}
          >
            Илгээх
          </Button> */}
          <Button
            type="primary"
            autoFocus
            onClick={() => {
              //  dispatch(setClearGiverance());
              props.onClose();
            }}
          >
            Хаах
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
