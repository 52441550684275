import { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";

import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";

import dark from "@amcharts/amcharts5/themes/Dark";
import { useSelector } from "react-redux";

function VailotionBarChart(props) {
  const violationDateType = useSelector(
    (state) => state.report.violationDateType
  );
  const [data, setData] = useState({
    value: 0,
    children: [
      {
        name: "Хувь хүн",
        children: [
          {
            name: "Т. Мягмарсүрэн",
            value: 1,
          },
          {
            name: "Ц. ЦЭЭСҮРЭН",
            value: 1,
          },
        ],
      },
    ],
  });
  // console.log(violationDateType);
  useEffect(() => {
    if (props.dashboardData != null) {
      if (violationDateType === 1) {
        setData(props.dashboardData.violation.chart);
      } else if (violationDateType === 2) {
        setData(props.dashboardData.violation.chart_year);
      }
      // console.log(props.dashboardData.violation);
    }
  }, [props, violationDateType]);
  //   console.log(props);
  //   console.log(data);
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdivViolationChart");
    root._logo.dispose();

    root.setThemes([dark.new(root)]);
    // let data = data;

    // Create wrapper container
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        paddingLeft: 0,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
      })
    );

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // let data = [
    //   {
    //     year: "2021",
    //     europe: 2.5,
    //     namerica: 2.5,
    //     asia: 2.1,
    //     lamerica: 1,
    //     meast: 0.8,
    //     africa: 0.4,
    //   },
    //   {
    //     year: "2022",
    //     europe: 2.6,
    //     namerica: 2.7,
    //     asia: 2.2,
    //     lamerica: 0.5,
    //     meast: 0.4,
    //     africa: 0.3,
    //   },
    //   {
    //     year: "2023",
    //     europe: 2.8,
    //     namerica: 2.9,
    //     asia: 2.4,
    //     lamerica: 0.3,
    //     meast: 0.9,
    //     africa: 0.5,
    //   },
    // ];

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: 0.1,
      cellEndLocation: 0.9,
      minorGridEnabled: true,
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "violation_month",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xRenderer.grid.template.setAll({
      location: 1,
    });

    xAxis.data.setAll(data);

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName) {
      let series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "violation_month",
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryX}:{valueY}",
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
      });

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: 0,
            centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    makeSeries("Шугам замд", "detection_type_1");
    makeSeries("Сити", "detection_type_2");
    makeSeries("Гомдол", "detection_type_3");
    makeSeries("Сити камер", "detection_type_4");
    makeSeries("Шуурхай үүрэг", "detection_type_5");
    makeSeries("Бусад", "detection_type_other");

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [data, props]);

  return (
    <div
      id="chartdivViolationChart"
      style={{ width: "100%", height: "400px" }}
    ></div>
  );
}
export default VailotionBarChart;
