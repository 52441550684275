import React, { useRef } from "react";
import {
  Form,
  SimpleItem,
  ButtonItem,
  RequiredRule,
  CustomRule,
  GroupItem,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { Divider, Stack } from "@mui/material";
import { Item } from "devextreme-react/box";
import { useDispatch, useSelector } from "react-redux";
import { updateEmployee } from "../../store/reducers/user/auth";

const PasswordChange = (props) => {
  const formRef = useRef(null);
  const colCountByScreen = {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 1,
  };
  const logedUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const formInstance = formRef.current.instance;

    // Validate form data
    const validationResult = formInstance.validate();
    if (validationResult.isValid) {
      const formData = new FormData();
      //formInstance.option("formData");

      formData.append("id", logedUser.id);
      formData.append("is_new_password", 1);
      formData.append("password", formInstance.option("formData").password);
      formData.append("repassword", formInstance.option("formData").password);
      console.log("Password:", formData.password);
      dispatch(updateEmployee(formData)).then((res) => {
        if (res) {
          props.closeDialogd();
        }
      }); // Handle password change securely
      // Perform API call or other actions here
    } else {
      notify("Нууц үг таарахгүй байна.", "error", 2000);
      // console.log("Form validation failed");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form ref={formRef} formData={{ password: "", rePassword: "" }}>
        {/* Password Field */}
        <SimpleItem
          dataField="password"
          label={{ text: "Шинэ нууц үг оруулах" }}
          editorType="dxTextBox"
          editorOptions={{
            mode: "password",
            placeholder: "Шинэ нууц үг оруулах",
          }}
        >
          <RequiredRule message="Password is required" />
        </SimpleItem>

        {/* Confirm Password Field */}
        <SimpleItem
          dataField="rePassword"
          editorType="dxTextBox"
          label={{ text: "Шинэ нууц үг дахин оруулах" }}
          editorOptions={{
            mode: "password",
            placeholder: "Шинэ нууц үг дахин оруулах",
          }}
        >
          <RequiredRule message="Нууц үг таарахгүй байна." />
          <CustomRule
            message="Нууц үг таарахгүй байна."
            validationCallback={(options) => {
              return (
                options.value ===
                formRef.current.instance.option("formData").password
              );
            }}
          />
        </SimpleItem>

        <GroupItem
          cssClass="buttons-group button-row"
          colCountByScreen={colCountByScreen}
        >
          <ButtonItem
            buttonOptions={{
              text: "Хадгалах",
              type: "success",
              useSubmitBehavior: true,
            }}
          />
          {/* <ButtonItem
            buttonOptions={{
              text: "Reset",
              type: "default",
              //  onClick: handleReset,
            }}
          /> */}
        </GroupItem>
        {/* Submit Button */}
      </Form>
      <style jsx>{`
        .button-row {
          justify-content: end;
          margin-top: 20px;
        }
      `}</style>
    </form>
  );
};

export default PasswordChange;
