import { Typography } from "@mui/material";

import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { HorizontalRule } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Item } from "devextreme-react/data-grid";
import { getCompany } from "../../store/reducers/company";
import { Toolbar } from "devextreme-react";
import moment from "moment";
import { setFilter } from "../../store/reducers/report";
const FilterBox = (props) => {
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(0, "days").format("YYYY-M-D")
  );
  const logedUser = useSelector((state) => state.auth.user);
  const [compNameGridBoxValue, setCompNameGridBoxValue] = useState(0);
  const [isGridCompNameBoxOpened, setIsGridCompNameBoxOpened] = useState(false);
  const getCompanyList = useSelector((state) => state.company.companyData);
  const [formData, setFormData] = useState([]);
  const gridColumns = ["name"];
  const filterComp = useSelector((state) => state.company.filter);
  const filter = useSelector((state) => state.report.filter);
  const dispatch = useDispatch();
  useEffect(() => {
    if (getCompanyList == null) {
      dispatch(getCompany(filterComp));
    } else {
      setFormData(getCompanyList);
    }
  }, [getCompanyList, dispatch, filterComp]);
  const dateFilterFun = (value, type) => {
    if (type == 1) {
      //  console.log(value);

      dispatch(
        setFilter({
          ...filter,
          start_date: value,
          end_date: endDate,
        })
      );
    } else {
      dispatch(
        setFilter({
          ...filter,
          start_date: startDate,
          end_date: value,
        })
      );
    }
  };
  return (
    <Toolbar>
      <Item location="before">
        <Typography variant="h4" mb={1} ml={2} mt={1}>
          Шүүлт
        </Typography>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{ ml: 2 }}
          justifyContent={"space-between"}
        >
          <DateBox
            defaultValue={startDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              // console.log(e.value);
              setStartDate(e.value);
              dateFilterFun(e.value, 1);
            }}
            type="date"
          />
          <HorizontalRule sx={{ fontSize: "16px", mt: 2 }} />
          <DateBox
            defaultValue={endDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              setEndDate(e.value);
              dateFilterFun(e.value, 2);
            }}
            type="date"
          />

          <TextBox
            // ref={this.CarTextRef}
            placeholder="Хайлт...."
            showClearButton={true}
            onValueChanged={(e) => {
              // console.log([...e.value].length);
              if ([...e.value].length > 0) {
                dispatch(
                  setFilter({
                    ...filter,
                    search_value: e.value,
                  })
                );
              } else {
                dispatch(
                  setFilter({
                    ...filter,
                    search_value: null,
                  })
                );
              }
            }}
            onKeyUp={(e) => {
              if (e.event.keyCode === 13) {
                e.event.preventDefault();
                //  console.log(e.event.target.value);
              }
              // console.log(e.event.target.value);
            }}
          />
        </Stack>
      </Item>
    </Toolbar>
  );
};

export default FilterBox;
