import { Box } from "@mui/material";

//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useRef, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import Header from "../../../components/Header";
import { getFeedBackTypeList } from "../../../store/reducers/feedBack";
import FeedbackTypeCrudComponent from "../../../components/feedBackTypeCrod/feedbackTypeCrud";
import FeedbackTypeCrudChild from "../../../components/feedBackTypeCrod/feedbackTypeCrudChild";

const FeedbackTypeCrud = () => {
  const gridRef = useRef();
  // const gertransportRouteListData = useSelector(
  //   (state) => state.metaCrud.transportRouteListData
  // );
  const getFeedBackTypeListData = useSelector(
    (state) => state.feedBack.feedBackTypeList
  );
  // const getTransportRouteStopList = useSelector(
  //   (state) => state.metaCrud.transportRouteStopList
  // );

  const [currentFilter, setCurrentFilter] = useState("full");
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [selectIndex, setSelectIndex] = useState(-1);
  const [formEditData, setFormEditData] = useState("");

  const [selectRowIndex, setSelectRowIndex] = useState(null);

  const [feedbackTypeChild, setFeedbackTypeChild] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getFeedBackTypeListData === null) {
      dispatch(getFeedBackTypeList());
    }
  }, [getFeedBackTypeListData, dispatch]);
  const refreshData = () => {
    dispatch(getFeedBackTypeList());
  };
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    //  console.log(selectedRowsData[0]);

    setSelectRowIndex(selectedRowKeys);
    // props.getFilters("company_id", selectedRowsData[0].id);
    setFormEditData(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    //  console.log(selectRowIndex);

    // setIsType(2);
  };

  const setFilters = (filterData) => {
    // console.log(filterData);
    setFeedbackTypeChild(filterData);
  };
  return (
    <Box m="15px">
      <Header
        title="Санал хүсэлтийн төрөл"
        subtitle="Санал хүсэлтийн төрөл жагсаалт"
      />
      <Box
        display="grid"
        gap="1px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 6" }}>
          {/* <FilterBox getFilters={setFilters} filterCompany={filterIsCompany} /> */}
          <FeedbackTypeCrudComponent
            getFeedBackTypeListData={getFeedBackTypeListData}
            refreshData={refreshData}
            getFilters={setFilters}
          />
        </Box>
        <Box sx={{ gridColumn: "span 6" }}>
          {" "}
          <FeedbackTypeCrudChild
            feedbackTypeChild={feedbackTypeChild}
            refreshData={refreshData}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FeedbackTypeCrud;
