import { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";

import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";

import dark from "@amcharts/amcharts5/themes/Dark";

function VailotionCompCount(props) {
  const [data, setData] = useState({
    value: 0,
    children: [
      {
        name: "Хувь хүн",
        children: [
          {
            name: "Т. Мягмарсүрэн",
            value: 1,
          },
          {
            name: "Ц. ЦЭЭСҮРЭН",
            value: 1,
          },
        ],
      },
    ],
  });
  useEffect(() => {
    if (props.dashboardData != null) {
      //console.log(props.dashboardData.violation);
      setData(props.dashboardData.violation.force_directed_tree);
    }
  }, [props]);
  //   console.log(props);
  //   console.log(data);
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv3");
    root._logo.dispose();

    root.setThemes([dark.new(root)]);
    // let data = data;

    // Create wrapper container
    let container = root.container.children.push(
      am5.Container.new(root, {
        width: am5.percent(100),
        height: am5.percent(100),
        layout: root.verticalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding
    let series = container.children.push(
      am5hierarchy.ForceDirected.new(root, {
        singleBranchOnly: false,
        downDepth: 2,
        topDepth: 1,
        initialDepth: 1,
        valueField: "value",
        categoryField: "name",
        childDataField: "children",
        idField: "name",
        linkWithField: "linkWith",
        manyBodyStrength: -10,
        centerStrength: 0.9,
      })
    );

    series.get("colors").setAll({
      step: 2,
    });

    series.links.template.set("strength", 0.3);

    series.data.setAll([data]);
    return () => {
      root.dispose();
    };
  }, [data, props]);

  return <div id="chartdiv3" style={{ width: "100%", height: "530px" }}></div>;
}
export default VailotionCompCount;
