import axios from "../../api/axiosInctance";

export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};

export const requestVehicleDataCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    // console.log(newFilter);
    const getRequestListData = await axios
      .post("/company/vehicle/request/list", newFilter)
      .then((res) => {
        //  console.log(res);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log("Aldaaa");

        console.warn("error", err.response.data.status.message);
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getRequestListData;
  } catch (error) {
    console.log(error);
  }
};
export const requestDriverDataCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });
    // console.log(newFilter);
    const getRequestListData = await axios
      .post("/company/driver/request/list", newFilter)
      .then((res) => {
        //  console.log(res);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        console.log("Aldaaa");

        console.warn("error", err.response.data.status.message);
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getRequestListData;
  } catch (error) {
    console.log(error);
  }
};
export const inspectorSetVehicleCall = async (data) => {
  try {
    //  console.log(axios);

    // console.log(JSON.stringify(newFilter));
    const removeVeh = await axios
      .post("company/vehicle/request/inspector", data)
      .then((res) => {
        //console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return removeVeh;
  } catch (error) {
    console.log(error);
  }
};
export const inspectorSetDriverCall = async (data) => {
  try {
    //  console.log(axios);

    // console.log(JSON.stringify(newFilter));
    const removeVeh = await axios
      .post("company/driver/request/inspector", data)
      .then((res) => {
        //console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return removeVeh;
  } catch (error) {
    console.log(error);
  }
};
export const requestRemoveService = async (requestId) => {
  try {
    //  console.log(axios);

    // console.log(JSON.stringify(newFilter));
    const removeVeh = await axios
      .post("/company/driver/request/delete", { id: requestId })
      .then((res) => {
        //console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return removeVeh;
  } catch (error) {
    console.log(error);
  }
};
export const requestRemoveVehicleService = async (requestId) => {
  try {
    //  console.log(axios);

    // console.log(JSON.stringify(newFilter));
    const removeVeh = await axios
      .post("/company/vehicle/request/delete", { id: requestId })
      .then((res) => {
        //console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return removeVeh;
  } catch (error) {
    console.log(error);
  }
};
export const cancelRequestListCall = async (data) => {
  try {
    //  console.log(axios);

    // console.log(JSON.stringify(newFilter));
    const removeVeh = await axios
      .post("/company/vehicle/request/cancel", data)
      .then((res) => {
        //console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return removeVeh;
  } catch (error) {
    console.log(error);
  }
};

export const editRequestService = async (formData) => {
  try {
    //   console.log(plateNo);

    const getInspection = await axios
      .post("/company/request/edit", formData)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getInspection;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const companyReqeustVehicleCall = async (data) => {
  try {
    //   console.log(plateNo);

    const getRespone = await axios
      .post("/company/vehicle/request/edit", data)
      .then((res) => {
        //  console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return getRespone;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};

export const requestIsCheckedConfirmCall = async (data) => {
  try {
    //console.log(axios);

    const requestIsCheckedConfirm = await axios
      .post("company/vehicle/request/check", data)
      .then((res) => {
        // console.log("=--==========");
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return requestIsCheckedConfirm;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const requestIsCheckedDriverConfirmCall = async (data) => {
  try {
    //console.log(axios);

    const requestIsCheckedConfirm = await axios
      .post("company/driver/request/check", data)
      .then((res) => {
        // console.log("=--==========");
        // console.log(res);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return requestIsCheckedConfirm;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const requestIsApprovedConfirmCall = async (data) => {
  try {
    //console.log(axios);

    const requestIsApprovedConfirm = await axios
      .post("company/vehicle/request/approve", data)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      });
    return requestIsApprovedConfirm;
  } catch (error) {
    console.log(error);
  }
};
export const requestIsApprovedDriverConfirmCall = async (data) => {
  try {
    const requestIsApprovedConfirm = await axios
      .post("company/driver/request/approve", data)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      });

    return requestIsApprovedConfirm;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const requestIsApprovedVehicleConfirmCall = async (data) => {
  try {
    const requestIsApprovedConfirm = await axios
      .post("company/vehicle/request/approve", data)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      });

    return requestIsApprovedConfirm;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const companyReqeustDriverCall = async (data) => {
  try {
    const requestIsApprovedConfirm = await axios
      .post("company/driver/request/edit", data)
      .then((res) => {
        console.log(res.data);
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      });

    return requestIsApprovedConfirm;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
