import { Box } from "@mui/material";

//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useState } from "react";

import { Stack } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

import FilterBox from "./filter";
import { useDispatch, useSelector } from "react-redux";

import LogList from "../../components/log/logList";
import Header from "../../components/Header";

const LogPage = () => {
  const driverDataList = useSelector((state) => state.driver.driverData);
  const filter = useSelector((state) => state.driver.filter);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const logedUser = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const setFilters = (field, value) => {
    // console.log(field, value);
    //  dispatch(setFilter({ ...filter, [field]: value }));
  };
  //  console.log(isNonMobile);
  useEffect(() => {
    if (driverDataList == null) {
      // dispatch(getLogPage(filter));
      // if (logedUser.role === "admin") {
      //   dispatch(setFilter({ is_approved: 1 }));
      // }
    }
  }, [driverDataList, logedUser, filter, dispatch]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      // dispatch(getLogPage(filter));
    }
  }, [filter, dispatch]);
  const filterIsCompany = (filterData) => {
    // console.log(filterData);
    //const data = selectedRowsData[0];
    if (filterData !== null) {
      setCompanyName(filterData[0]);
      setIsCompanyFilter(true);
    } else {
      setCompanyName(null);
      setIsCompanyFilter(false);
    }
  };

  return (
    <Box m="10px">
      <Header title="Системийн лог" subtitle="Системийн лог жагсаалт" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 12" }}>
          <FilterBox getFilters={setFilters} filterCompany={filterIsCompany} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <FilterBox getFilters={setFilters} filterCompany={filterIsCompany} />

          <LogList />
        </Box>
      </Box>
    </Box>
  );
};

export default LogPage;
