import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useState } from "react";

import { Stack } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import themes from "devextreme/ui/themes";

import FilterBox from "../../components/company/filter";
import { useDispatch, useSelector } from "react-redux";
import VehicleList from "../../components/vehicle/vehicleList";
import UserList from "../../components/user/userList";
import CompanyList from "../../components/company/companyList";
import { getCompany, setFilter } from "../../store/reducers/company";
import {
  branchTreeListFun,
  getEmployeTreeList,
  setBranchFilter,
} from "../../store/reducers/employe";
import BranchList from "../../components/company/branchList";

const Company = () => {
  // const userDataList = useSelector((state) => state.vehicle.vehicleData);
  const dispatch = useDispatch();
  const filter = useSelector((state) => state.company.filter);
  const branchFilter = useSelector((state) => state.employe.branchFilter);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const getCompanyList = useSelector((state) => state.company.companyData);

  const getBranchTreeListData = useSelector(
    (state) => state.employe.branchTreeListData
  );
  const [companyId, setCompanyId] = useState(0);
  // const setFilters = (field, value) => {
  //   // console.log(field, value);
  //   dispatch(setFilter({ ...filter, [field]: value }));
  // };
  const setFilters = (field, value) => {
    // console.log(field, value);
    setCompanyId(value);
    dispatch(setBranchFilter({ ...branchFilter, [field]: value }));
  };
  // useEffect(() => {
  //   if (companyId === 0) {
  //     // setCompanyId(1);
  //     setFilters("company_id", 1);
  //   }
  // }, []);

  useEffect(() => {
    if (getCompanyList == null) {
      dispatch(getCompany(filter));
    }
  }, [filter, getCompanyList, dispatch]);
  useEffect(() => {
    if (getBranchTreeListData == null) {
      if (companyId === 0) {
        //  const data = { company_id: 1 };

        dispatch(branchTreeListFun(branchFilter));
      }
    }
  }, [getBranchTreeListData, branchFilter, companyId, dispatch]);
  useEffect(() => {
    if (Object.keys(branchFilter).length > 0) {
      dispatch(branchTreeListFun(branchFilter));
    }
  }, [branchFilter, dispatch]);
  // useEffect(() => {
  //   if (Object.keys(filter).length > 0) {
  //     dispatch(getCompany(filter));
  //   }
  // }, [filter, dispatch]);
  const filterIsCompany = (filter) => {
    // console.log(filter);
    //const data = selectedRowsData[0];
    if (filter !== "") {
      setIsCompanyFilter(true);
    }
  };

  return (
    <Box m="20px">
      <Header title="Аж ахуй нэгж" subtitle="Аж ахуй нэгжийн жагсаалт" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 8" }}>
          {/* <FilterBox getFilters={setFilters} filterCompany={filterIsCompany} /> */}
          <CompanyList
            getFilters={setFilters}
            //  getCompSelectId={setCompSelectId}
            companyList={getCompanyList}
            isCompanyFilter={isCompanyFilter}
          />
        </Box>
        <Box sx={{ gridColumn: "span 4" }}>
          <BranchList
            companyId={companyId}
            branchList={getBranchTreeListData}
            isCompanyFilter={isCompanyFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Company;
