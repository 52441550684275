import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";

import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { Button } from "devextreme-react";
import PasswordChange from "./passwordChange";
import { CloseOutlined } from "@mui/icons-material";

const MiddleDivider = styled((props) => (
  <Divider variant="middle" {...props} />
))``;
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
export default function PasswordDialog(props) {
  //const { drawerOpen, setDrawerOpen } = useUIContext();
  const ui = useSelector((state) => state.ui);

  //console.log({ ui });
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  // console.log(props);
  return (
    <>
      <BootstrapDialog
        // onClose={(event, reason) => {
        //   if (reason && reason === "backdropClick") {
        //     return;
        //   } else {
        //     return onClose();
        //   }
        //   // // console.log(reason);
        // }}

        maxWidth="lg"
        onClose={props.onClose}
        PaperProps={{
          style: {
            backgroundColor:
              theme.palette.mode === "dark" ? " #1F2A40" : "#fff",
            backgroundImage: "none",
            borderRadius: "12px",
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>Нууц үг солих</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => props.closeDialogd()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent dividers>
          <PasswordChange closeDialogd={props.closeDialogd} />
        </DialogContent>
        {/* <DialogActions sx={{ m: 1 }}>
        
          <Button
            type="primary"
            autoFocus
            onClick={() => {
              //  dispatch(setClearGiverance());
              props.onClose();
            }}
          >
            Хаах
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </>
  );
}
