import { useEffect, useLayoutEffect, useState } from "react";
import * as am5 from "@amcharts/amcharts5";

import * as am5percent from "@amcharts/amcharts5/percent";

import dark from "@amcharts/amcharts5/themes/Dark";

function DriverAllChart(props) {
  const [data, setData] = useState([
    {
      title: "25 хүртлэх",
      value: 8,
    },
    {
      title: "26-35 нас",
      value: 274,
    },
  ]);
  useEffect(() => {
    if (props.dashboardData != null) {
      setData(props.dashboardData.driver.age);
    }
  }, [props]);
  //   console.log(props);
  //   console.log(data);
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv6");
    root._logo.dispose();

    root.setThemes([dark.new(root)]);
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(50),
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "title",
        legendValueText: "[bold {fill}]{value}[/]",
        alignLabels: false,
      })
    );

    series.labels.template.setAll({
      textType: "circular",
      forceHidden: true,
      centerX: 0,
      centerY: 0,
    });

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(data);

    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      })
    );

    legend.data.setAll(series.dataItems);

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [data, props]);

  return <div id="chartdiv6" style={{ width: "100%", height: "400px" }}></div>;
}
export default DriverAllChart;
