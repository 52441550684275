import { useDispatch } from "react-redux";
import axios from "../../api/axiosInctance";
import { guest, logout } from "../../store/reducers/user/auth";
import { axiosPrivate } from "../../api/axios";
export const sleep = async (msec = 3000) => {
  return new Promise((resolve) => setTimeout(resolve, msec));
};

export const userDataListCall = async (filter) => {
  try {
    let newFilter = {};
    Object.keys(filter).map((key) => {
      if (filter[key] !== null) {
        newFilter[key] = filter[key];
      }
    });

    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/list", newFilter)
      .then((res) => {
        //  console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
// export const getUserTypeListCall = async (filter) => {
//   try {
//     // console.log(newFilter);
//     const dataResponse = await axios
//       .post("/user/type/list")
//       .then((res) => {
//         //  console.log(res.data);
//         if (res.data.status.code === 200) {
//           return res.data;
//         }
//       })
//       .catch((err) => {
//         return err.response.data;
//       })
//       .finally(() => {
//         // console.log("ddd");
//       });
//     return dataResponse;
//   } catch (error) {
//     console.log(error);
//   }
// };
export const userProfileDataCall = async (userId) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/profile/data", userId)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userProfileEditDataCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/profile/edit", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userDeleteCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/deleteData", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};

export const createEmployeeCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/create", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const updateEmployeeCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/update", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const deleteEmployeeCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/delete", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userEmployeeEditCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/employee/edit", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserLocationCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/location/aimag_list", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserLocationDistCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/location/soum_list", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserLocationHorooCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/location/bag_list", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserUserNationalityCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/nationality/list")
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserSocietyCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/society/list")
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserSexCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/basic/sex/list")
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getEmployeeInfoCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/employee/data", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserJobpositionCall = async () => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/job_position/list")
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserModuleCall = async (userId) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/permission/data", userId)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const getUserTypeModuleCall = async (userTypeId) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/type/permission/data", userTypeId)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const setUserPermissionCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/permission/edit", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const setUserTypePermissionCall = async (data) => {
  try {
    // console.log(newFilter);
    const dataResponse = await axios
      .post("/user/type/permission/edit", data)
      .then((res) => {
        // console.log(res.data);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return dataResponse;
  } catch (error) {
    console.log(error);
  }
};
export const userEditCall = async (formData) => {
  try {
    //console.log(axios);

    const editData = await axios
      .post("/user/editData", formData)
      .then((res) => {
        //  console.log(res.data);

        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });
    return editData;
  } catch (error) {
    console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};
export const getUser = async () => {
  // console.log("jhgjgjgjhg");
  // await sleep(11000);

  try {
    // console.log(getUser);
    // axios.defaults.headers.common["Authorization"]=

    const guestToken = await axios
      .post("/user/data")
      .then((res) => {
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        // console.log("Aldaaa");
        return err.response.data;
      })
      .finally(() => {
        // console.log("ddd");
      });

    return guestToken;
  } catch (error) {
    // console.log(error);
  }

  // console.log(ddd);
  //const guestToken = localStorage.getItem("guestToken");
  // return guestToken;
};

export const login = async ({ data }) => {
  try {
    const loginToken = axios
      .post("/user/login", data)
      .then((res) => {
        console.log(res);
        if (res.data.status.code === 200) {
          return res.data;
        }
      })
      .catch((err) => {
        return err.response.data;
      });

    return loginToken;
    // return {
    //   logedUser: {
    //     userId: 1,
    //     role: "admin",
    //     userName: "batuna",
    //   },
    //   success: true,
    //   token: "d23232213232",
    // };
  } catch (ex) {
    console.log(ex);
  }
};

export const requestPusher = async ({ data }) => {
  const pusherReq = await axios
    .post("/request", data)
    .then((res) => {
      //  console.log(res.data);

      return res.data;
    })
    .catch((err) => {
      console.log("Aldaaa");
      let message =
        typeof err.response !== "undefined"
          ? err.response.data.message
          : err.message;
      console.warn("error", message);
    })
    .finally(() => {
      // console.log("ddd");
    });
  return pusherReq;
};
