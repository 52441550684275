import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useRef, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import FeedBackList from "../../components/feedBack/feedBackList";
import {
  getFeedBack,
  getFeedBackTypeDeleteFun,
  getFeedBackTypeEditFun,
  getFeedBackTypeList,
  setFilter,
} from "../../store/reducers/feedBack";
import FilterBox from "../../components/feedBack/filter";
import DataGrid, {
  Column,
  Editing,
  Popup,
  Paging,
  Lookup,
  Form,
  FilterRow,
  HeaderFilter,
  ColumnChooser,
  Selection,
  Texts,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import notify from "devextreme/ui/notify";
import { DataGridConfig } from "../../config";
import {
  getTransportRouteFun,
  getTransportRouteStopFun,
  transportRouteAddEditFun,
} from "../../store/reducers/metaCrud";

const FeedbackTypeCrudChild = (props) => {
  const gridRef = useRef();
  const getFeedBackTypeListData = useSelector(
    (state) => state.feedBack.feedBackTypeList
  );
  const filter = useSelector((state) => state.feedBack.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [formData, setFormData] = useState([]);
  const [changes, setChanges] = useState([]);
  const [editRowKey, setEditRowKey] = useState(-1);
  const [selectRowIndex, setSelectRowIndex] = useState(null);
  const [editRowData, setEditRowData] = useState(null);
  const [parentId, setParentId] = useState(0);
  const dispatch = useDispatch();
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  //console.log(filter);
  useEffect(() => {
    if (getFeedBackTypeListData === null) {
      dispatch(getFeedBackTypeList());
    }
  }, [getFeedBackTypeListData, dispatch]);
  useEffect(() => {
    if (props.feedbackTypeChild !== null && getFeedBackTypeListData !== null) {
      let x = getFeedBackTypeListData.filter((a) => {
        //  console.log(a);
        if (a.id === props.feedbackTypeChild) {
          return typeof a.child !== "undefined" ? a.child : [];
        }
      });
      // console.log(x);
      setFormData(x.length > 0 ? x[0].child : []);
    }
  }, [props, dispatch, getFeedBackTypeListData]);
  console.log(formData);
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowsData[0].id);
    //const data = { id: selectedRowsData[0].id };

    // props.getFilters(selectedRowsData[0].child);
    //  const data = { id: selectedRowsData[0].id };
    // dispatch(getTransportRouteStopFun(data));
    //dispatch(getTransportRouteStopFun(data));
    setSelectRowIndex(selectedRowKeys);
    // props.getFilters("company_id", selectedRowsData[0].id);
    setFormEditData(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    //  console.log(selectRowIndex);

    // setIsType(2);
  };
  // function deleteRecords() {
  //   gridRef.current.instance.getSelectedRowsData().forEach((key) => {
  //     console.log(key);
  //     //  dispatch(removeCompanyList(key.id));
  //   });
  //   // this.setState({
  //   //   selectedItemKeys: [],
  //   // });
  //   gridRef.current.instance.refresh();
  // }
  const onSaving = React.useCallback(
    (e) => {
      // e.cancel = true;
      //console.log(e.changes[0].type);
      const selectedData = gridRef.current.instance.getSelectedRowsData();
      if (e.changes[0] !== undefined) {
        if (e.changes[0].type === "insert") {
          const data = e.changes[0].data;
          if (Object.keys(data).length > 0) {
            data["id"] = 0;
            data["is_active"] = 1;
            data["parent_id"] = parentId;
          }
          // dispatch(transportRouteAddEditFun(data));
          dispatch(getFeedBackTypeEditFun(data));
          dispatch(getFeedBackTypeList());
          // console.log(data);
          gridRef.current.instance.cancelEditData();
          e.cancel = true;
          // e.component.cancelEditData();
          gridRef.current.instance.cancelEditData();
        } else if (e.changes[0].type === "update") {
          const data = e.changes[0].data;
          // console.log(data);
          data["id"] = e.changes[0].key;
          data["is_active"] = 1;
          data["parent_id"] = parentId;
          if ("code" in data === false) {
            data["code"] = selectedData[0].code;
          }
          if ("rank" in data === false) {
            data["rank"] = selectedData[0].rank;
          }
          if ("name" in data === false) {
            data["name"] = selectedData[0].name;
          }
          dispatch(getFeedBackTypeEditFun(data));
          dispatch(getFeedBackTypeList());
          gridRef.current.instance.cancelEditData();

          e.cancel = true;
        } else if (e.changes[0].type === "remove") {
          // console.log(e.changes[0].key);
          const data = { id: e.changes[0].key };
          dispatch(getFeedBackTypeDeleteFun(data));
          dispatch(getFeedBackTypeList());
          e.cancel = true;
          gridRef.current.instance.cancelEditData();
        }
      }
      // e.component.cancelEditData();
    },
    [parentId, dispatch]
  );

  const onChangesChange = React.useCallback((changes) => {
    setChanges(changes);
  }, []);

  const onEditRowKeyChange = React.useCallback((editRowKey) => {
    // console.log(editRowKey);
    setEditRowKey(editRowKey);
  }, []);

  // function onEditorPreparing(e) {
  //   // treeListRef.current.instance.selectedRowKeys();
  //   console.log(e.row.data);
  //   e.allowEditing === true ? setEditRowData(e.row.data) : setEditRowData(null);
  // }
  return (
    <Box m="20px">
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    // dispatch(getTransportRouteFun());
                    gridRef.current.instance.refresh();
                    //   console.log(gridRef.current.instance.getSelectedRowsData());
                    // setIsPopUpEdit(true);
                    // console.log(isPopUpEdit);
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>
            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"add"}
                  onClick={() => {
                    gridRef.current.instance.addRow();
                  }}
                  type={"default"}
                  text="Шинэ"
                />
              </Box>
            </Item>
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"edit"}
                  onClick={(e) => {
                    // if (selectIndex > -1) {
                    //   gridRef.current.instance.editRow(selectIndex);
                    // } else {
                    //   console.log("ffff");
                    // }
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    //   const getSelectedKey =
                    //   gridRef.current.instance.getSelectedRowKeys();
                    //  console.log(selectRowIndex[0]);
                    if (getSelectedData.length > 0) {
                      gridRef.current.instance.editRow(selectRowIndex[0] - 1);
                    } else {
                      notify(
                        {
                          message: "Засах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }

                    //gridRef.current.instance.instance.deselectAll();
                  }}
                  type={"success"}
                  text="Засах"
                />
              </Box>
            </Item> */}
            {/* <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"remove"}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData);
                    if (getSelectedData.length > 0) {
                      for (let i = 0; i < getSelectedData.length; i++) {
                        const element = getSelectedData[i];

                        console.log(element);
                      }
                    } else {
                      notify(
                        {
                          message: "Устгах мэдээлэл сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                  type={"danger"}
                  text="Устгах"
                />
              </Box>
            </Item> */}
            <Item location="after">
              <Box>
                {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            //  searchPanel={{ visible: true, placeholder: "Хайлт" }}
            rowAlternationEnabled={true}
            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={400}
            onRowDblClick={(row) => {
              setFormEditData(row.data);
              // console.log(row);
              gridRef.current.instance.editRow(row.rowIndex);
            }}
            //   defaultSelectionFilter={selectionFilter}
            onSelectionChanged={onSelectionChanged}
            onSaving={onSaving}
            onEditingStart={(e) => {
              // console.log(e.data);
              setParentId(e.data.parent_id);
            }}
            onInitNewRow={(e) => {
              // console.log(formData);
              //e.data.ChildData = [];
              // console.log(props.feedbackTypeChild);
              setParentId(props.feedbackTypeChild);
            }}
            // onEditorPreparing={onEditorPreparing}
          >
            <Editing
              mode="popup"
              allowUpdating={true}
              //  allowAdding={true}
              allowDeleting={true}
              // changes={changes}
              useIcons={true}
              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popup
                title="Санал хүсэлтийн төрөл"
                showTitle={true}
                width={"40%"}
                height={300}
              />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                saveRowToNode={"ffff"}
                cancelRowChanges={"Цуцлах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="name" />
                  <Item dataField="code" />
                  <Item dataField="rank" />
                </Item>

                {/* <Item
                  itemType="group"
                  caption="Home Address"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="StateID" />
                  <Item dataField="Address" />
                </Item> */}
              </Form>
            </Editing>

            <FilterRow visible={true} />
            <ColumnChooser enabled={false} />

            <Column
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            <Column dataField="name" format="fixedPoint" caption={"Нэр"} />
            <Column dataField="code" format="fixedPoint" caption={"Код"} />
            <Column dataField="rank" format="fixedPoint" caption={"Эрэмбэ"} />

            <Column
              dataField="created_at"
              format="yyyy-MM-dd HH:mm"
              dataType="date"
              caption={"Огноо"}
            />

            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
          </DataGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default FeedbackTypeCrudChild;
