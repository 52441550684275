import { Typography } from "@mui/material";

import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { HorizontalRule } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../store/reducers/violation";
import moment from "moment";

const FilterBox = (props) => {
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };
  const filter = useSelector((state) => state.violation.filter);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment().subtract(0, "days").format("YYYY-M-D")
  );
  const logedUser = useSelector((state) => state.auth.user);
  const [feedBackTypeGridBoxValue, setFeedBackTypeGridBoxValue] = useState(0);

  const [feedBackType, setFeedBackType] = useState([]);
  const gridColumns = ["name"];

  const dispatch = useDispatch();
  useEffect(() => {
    //console.log(startDate);
    if (startDate === null) {
      const offsetStart = new Date().getTimezoneOffset();

      const startDateValue = new Date(
        new Date().getTime() - offsetStart * 60 * 1000
      )
        .toISOString()
        .split("T")[0];
      setStartDate(startDateValue);
    } else if (endDate === null) {
      const offsetEnd = new Date().getTimezoneOffset();

      const endDateValue = new Date(
        new Date().getTime() - offsetEnd * 60 * 1000
      )
        .toISOString()
        .split("T")[0];
      setEndDate(endDateValue);
    }
  }, [startDate, endDate]);

  const dateFilterFun = (value, type) => {
    // console.log(type);

    if (type == 1) {
      dispatch(
        setFilter({
          ...filter,
          start_date: value,
          end_date: endDate,
        })
      );

      //  console.log(startDateValue);

      // props.getFilters("start_date", startDateValue);
      // props.getFilters("end_date", endDate);
    } else {
      dispatch(
        setFilter({
          ...filter,
          start_date: startDate,
          end_date: value,
        })
      );

      // const offsetStart = startDate.getTimezoneOffset();

      // const startDateValue = new Date(
      //   startDate.getTime() - offsetStart * 60 * 1000
      // )
      //   .toISOString()
      //   .split("T")[0];
      // console.log(endDateValue);

      // props.getFilters("start_date", startDate);
      // props.getFilters("end_date", endDateValue);
    }
  };
  // console.log(feedBackType);
  // console.log(props);
  return (
    <>
      <Stack sx={{ ml: 1 }} direction={"row"} spacing={2}>
        <Typography>Үүсгэсэн огноо:</Typography>
        <Stack
          alignItems={"center"}
          direction={"row"}
          spacing={1}
          sx={{ mt: 1 }}
        >
          <DateBox
            height={30}
            defaultValue={
              startDate === null
                ? moment().startOf("month").format("YYYY-MM-DD")
                : startDate
            }
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              //console.log(e.value);
              setStartDate(e.value);
              dateFilterFun(e.value, 1);
              // props.getFilters("start_date", e.value);
              // props.getFilters("end_date", endDate);
            }}
            type="date"
          />
          <HorizontalRule sx={{ fontSize: "12px", mt: 3 }} />
          <DateBox
            height={30}
            defaultValue={
              endDate === null
                ? moment().subtract(0, "days").format("YYYY-M-D")
                : endDate
            }
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              // console.log(e.value);
              setEndDate(e.value);
              dateFilterFun(e.value, 2);
              // props.getFilters("start_date", startDate);
              // props.getFilters("end_date", e.value);
            }}
            type="date"
          />
        </Stack>
        <Typography>Хайлт:</Typography>
        <TextBox
          // ref={this.CarTextRef}
          height={30}
          placeholder="Хайлт...."
          showClearButton={true}
          onValueChanged={(e) => {
            // console.log([...e.value].length);
            if ([...e.value].length > 0) {
              props.getFilters("search_value", e.value);
            } else {
              props.getFilters("search_value", null);
            }
          }}
          onKeyUp={(e) => {
            if (e.event.keyCode === 13) {
              e.event.preventDefault();
              //  console.log(e.event.target.value);
            }
            // console.log(e.event.target.value);
          }}
        />
      </Stack>
    </>
  );
};

export default FilterBox;
