import { Box } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useState } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import { useDispatch, useSelector } from "react-redux";
import FeedBackList from "../../components/feedBack/feedBackList";
import { getFeedBack, setFilter } from "../../store/reducers/feedBack";
import FilterBox from "../../components/feedBack/filter";
import moment from "moment";

const FeedBack = () => {
  const feedBackDataList = useSelector((state) => state.feedBack.feedBackData);
  const filter = useSelector((state) => state.feedBack.filter);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isCompanyFilter, setIsCompanyFilter] = useState(false);
  const [compName, setCompanyName] = useState("");

  const dispatch = useDispatch();
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  //console.log(filter);\

  // console.log(moment().subtract(0, "days").format("YYYY-M-D"));
  // console.log(moment().startOf("month").format("YYYY-MM-DD"));
  useEffect(() => {
    if (feedBackDataList == null) {
      dispatch(
        getFeedBack({
          start_date: moment().startOf("month").format("YYYY-MM-DD"),

          end_date: moment().subtract(0, "days").format("YYYY-M-D"),
        })
      );
    }
  }, [feedBackDataList, dispatch]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getFeedBack(filter));
    }
  }, [filter, dispatch]);
  //   useEffect(() => {
  //     if (Object.keys(filter).length > 0) {
  //     //  dispatch(getFeedBack(filter));
  //     }
  //   }, [filter, dispatch]);
  //   const filterIsCompany = (filterData) => {
  //     // console.log(filter);
  //     //const data = selectedRowsData[0];
  //     if (filterData !== null) {
  //       setCompanyName(filterData[0]);
  //       setIsCompanyFilter(true);
  //     } else {
  //       setCompanyName(null);
  //       setIsCompanyFilter(false);
  //     }
  //   };

  return (
    <Box m="20px">
      <Header title="Санал,хүсэлт" subtitle="Санал, хүсэлтийн жагсаалт" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        {/* <Box sx={{ gridColumn: "span 3" }}>
          <FilterBox getFilters={setFilters} />
        </Box> */}
        <Box sx={{ gridColumn: "span 12" }}>
          <FeedBackList
            feedBackListData={feedBackDataList}
            comptData={compName}
            isCompanyFilter={isCompanyFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FeedBack;
