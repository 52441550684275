import {
  Box,
  Card,
  CardActionArea,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import {
  Badge,
  DirectionsBus,
  Feedback,
  People,
  Person,
  Person2,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getDashboardDataFun,
  setFeedbackDateType,
  setViolationDateType,
} from "../../store/reducers/report";
import FeedBackDashboard from "../../components/dashboard/feedback";
import FeedBackTypeCount from "../../components/dashboard/feedback/feedbackTypeCount";
import VailotionCompCount from "../../components/dashboard/vailotion/vailationCompTypeCount";
import DriverSexFemaleChart from "../../components/dashboard/driver/driverSexFemale";
import DriverSexMaleChart from "../../components/dashboard/driver/driverSexMale";
import DriverAllChart from "../../components/dashboard/driver";
import VehicleAgeCount from "../../components/dashboard/vehicle/vehicleAge";
import VehicleSeriveTypeChart from "../../components/dashboard/vehicle/vehicleServiceType";
import TopChartFeedback from "../../components/dashboard/feedback/topTotalChart";
import ProgressCircle from "../../components/ProgressCircle";
import Contacts from "../contacts";
import useDialogModal from "../../hooks/chartDailogModal";

import ChartDialogModal from "../../hooks/chartDailogModal";
import ChartDetialFeedback from "../../components/dashboard/feedback/topTotalChart";
import ChartDetialVehicle from "../../components/dashboard/vehicle/topVehicleTotalChart";
import ChartDetialDriver from "../../components/dashboard/driver/topDriverTotalChart";
import ChartDetialEmployee from "../../components/dashboard/employee/topEmployeeTotalChart";
import ChartDetialViolation from "../../components/dashboard/vailotion/topViolationTotalChart";
import ChartDetialCompany from "../../components/dashboard/company/topCompanyTotalChart";
import DashboardTable, {
  RevenueAnalysisByStatesCard,
} from "../../components/dashboard/tableAllType/tableAllType";
import { Button } from "devextreme-react";
import VailotionBarChart from "../../components/dashboard/vailotion/vailotionBarChart";
import TableGrievance from "../../components/dashboard/feedback/table_grievance";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const dashboardDataList = useSelector((state) => state.report.dashboardData);
  const [showOptions, setShowOptions] = useState(false);
  const [ChartDetialDialog, showChartDialog, closeDialog] =
    ChartDialogModal(ChartDetialFeedback);
  const [ChartDetialDialogVehicle, showChartDialogVehicle, closeDialogVehicle] =
    ChartDialogModal(ChartDetialVehicle);
  const [ChartDetialDialogDriver, showChartDialogDriver, closeDialogdriver] =
    ChartDialogModal(ChartDetialDriver);
  const [
    ChartDetialDialogEmployee,
    showChartDialogEmployee,
    closeDialogEmployee,
  ] = ChartDialogModal(ChartDetialEmployee);
  const [
    ChartDetialDialogViolation,
    showChartDialogViolation,
    closeDialogViolation,
  ] = ChartDialogModal(ChartDetialViolation);
  const [ChartDetialDialogCompany, showChartDialogCompany, closeDialogCompany] =
    ChartDialogModal(ChartDetialCompany);
  useEffect(() => {
    if (dashboardDataList === null) {
      // console.log(Object.keys(filter).length);
      dispatch(getDashboardDataFun());
    }
  }, [dashboardDataList, dispatch]);
  // const handleMouseEnter = () => {
  //   setShowOptions(true);
  // };
  // const handleMouseLeave = () => {
  //   setShowOptions(false);
  // };
  // console.log(dashboardDataList);
  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Удирдлагын хэсэг" subtitle="Админы удирдлагын хэсэг" />

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}

      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}

        <Box
          onClick={() => {
            // console.log("dssds");
            showChartDialog();
          }}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.grievance.total_count
                : "0"
            }
            subtitle="Нийт санал,хүсэлт"
            progress="1"
            increase="100%"
            // icon={
            //   <Feedback
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />

          {/* <TopChartFeedback
            dashboardData={dashboardDataList}
            isDashboard={true}
          /> */}
        </Box>

        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            //  console.log("dssds");
            showChartDialogVehicle();
          }}
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.vehicle.total_count
                : "0"
            }
            subtitle="Нийт тээврийн хэрэгсэл"
            progress="1"
            increase="100%"
            // icon={
            //   <DirectionsBus
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            //  console.log("dssds");
            showChartDialogDriver();
          }}
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.driver.total_count
                : "0"
            }
            subtitle="Нийт жолооч"
            progress={
              dashboardDataList != null
                ? "0." +
                  parseInt(
                    (dashboardDataList.driver.total_pd * 100) /
                      dashboardDataList.driver.total_count
                  )
                : "0"
            }
            increase={
              dashboardDataList != null
                ? parseInt(
                    (dashboardDataList.driver.total_pd * 100) /
                      dashboardDataList.driver.total_count
                  ) + "%"
                : "0"
            }
            // icon={
            //   <Badge
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            //  console.log("dssds");
            showChartDialogEmployee();
          }}
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.user.total_count
                : "0"
            }
            subtitle="Нийт ажилтан"
            progress="1"
            increase="100%"
            // icon={
            //   <PersonAddIcon
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            //  console.log("dssds");
            showChartDialogCompany();
          }}
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.company.total_count
                : "0"
            }
            subtitle="Нийт аж ахуй нэгж"
            progress="1"
            increase="100%"
            // icon={
            //   <PersonAddIcon
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            //  console.log("dssds");
            showChartDialogViolation();
          }}
        >
          <StatBox
            title={
              dashboardDataList != null
                ? dashboardDataList.violation.total_count
                : "0"
            }
            subtitle="Нийт зөрчил"
            progress="1"
            increase="100%"
            // icon={
            //   <PersonAddIcon
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
        </Box>

        {/* ROW 2 */}

        <Box
          gridColumn="span 8"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Нийт санал, хүсэлт
              </Typography>

              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {dashboardDataList != null
                  ? dashboardDataList.grievance.total_count
                  : "0"}
              </Typography>
            </Box>
            <Box>
              <Stack direction={"row"} spacing={2}>
                <Button
                  onClick={() => {
                    dispatch(setFeedbackDateType(0));
                  }}
                >
                  Бүгд
                </Button>
                <Button
                  onClick={() => {
                    dispatch(setFeedbackDateType(1));
                  }}
                >
                  Өнөөдөр
                </Button>
                <Button
                  onClick={() => {
                    dispatch(setFeedbackDateType(2));
                  }}
                >
                  7 хоногоор
                </Button>
                <Button
                  onClick={() => {
                    dispatch(setFeedbackDateType(3));
                  }}
                >
                  Сараар
                </Button>
                <Button
                  onClick={() => {
                    dispatch(setFeedbackDateType(4));
                  }}
                >
                  Жилээр
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box height="250px" m="0px 0 0 0">
            <FeedBackDashboard
              dashboardData={dashboardDataList}
              isDashboard={true}
            />
            {/* <LineChart dashboardData={dashboardDataList} isDashboard={true} /> */}
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            // borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Санал хүсэлт эх үүсвэрээр
            </Typography>
          </Box>
          <FeedBackTypeCount
            dashboardData={dashboardDataList}
            isDashboard={true}
          />
        </Box>

        <Box
          gridColumn="span 8"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Жолоочийн дэлгэрэнгүй тоон үзүүлэлт/Нас, хүйсийн харьцаагаар/
              </Typography>
            </Box>
            <Box alignItems={"center"}>
              <Typography
                variant="h5"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Нийт:{" "}
                {dashboardDataList != null
                  ? dashboardDataList.driver.total_count
                  : "0"}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ m: 1 }} />
          <Box m="0px 0 0 0">
            <Stack
              sx={{ m: 3 }}
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Stack direction={"row"} spacing={1}>
                <Person />
                <Typography variant="h5" fontWeight="600">
                  {dashboardDataList !== null
                    ? dashboardDataList.driver.male.total_count
                    : ""}
                </Typography>
              </Stack>
              <Stack direction={"row"} spacing={1}>
                <Person2 />
                <Typography variant="h5" fontWeight="600">
                  {dashboardDataList !== null
                    ? dashboardDataList.driver.female.total_count
                    : ""}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              {/* <Stack direction={"row"} spacing={1}>
                <Person />
                <Typography variant="h5" fontWeight="600">
                  {dashboardDataList !== null
                    ? dashboardDataList.driver.male.total_count
                    : ""}
                </Typography>
              </Stack> */}
              <DriverSexMaleChart
                dashboardData={dashboardDataList}
                isDashboard={true}
              />

              <DriverSexFemaleChart
                dashboardData={dashboardDataList}
                isDashboard={true}
              />
            </Stack>
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="20px"
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              Нийт жолооч насаар
            </Typography>

            <Stack direction={"row"} spacing={1}>
              <People />
              <Typography variant="h5" fontWeight="600">
                {dashboardDataList !== null
                  ? dashboardDataList.driver.total_count
                  : ""}
              </Typography>
            </Stack>
          </Stack>

          <Box>
            <DriverAllChart
              isDashboard={true}
              dashboardData={dashboardDataList}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Тээврийн хэрэгслийн дэлгэрэнгүй тоон үзүүлэлт/Насжилтаар/
              </Typography>
            </Box>
            <Box alignItems={"center"}>
              <Typography
                variant="h5"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                Нийт:{" "}
                {dashboardDataList !== null
                  ? dashboardDataList.vehicle.total_count
                  : ""}
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ m: 1 }} />
          <Box m="0px 0 0 0">
            <VehicleAgeCount
              dashboardData={dashboardDataList}
              isDashboard={true}
            />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
          p="20px"
        >
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography
              variant="h5"
              fontWeight="600"
              sx={{ marginBottom: "15px" }}
            >
              ТХ-н үйлчилгээний төрлөөр
            </Typography>
          </Stack>

          <Box>
            <VehicleSeriveTypeChart
              isDashboard={true}
              dashboardData={dashboardDataList}
            />
          </Box>
        </Box>

        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Зөрчил илрүүлэлтийн төрлөөр
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {/* {dashboardDataList != null
                  ? dashboardDataList.grievance.total_count
                  : "0"} */}
              </Typography>
            </Box>
            <Box>
              <Stack direction={"row"} spacing={2}>
                <Button
                  onClick={() => {
                    dispatch(setViolationDateType(1));
                  }}
                >
                  Сараар
                </Button>
                <Button
                  onClick={() => {
                    dispatch(setViolationDateType(2));
                  }}
                >
                  Жилээр
                </Button>
              </Stack>
            </Box>
          </Box>
          <Box height="250px" m="0px 0 0 0">
            {/* <FeedBackDashboard
              dashboardData={dashboardDataList}
              isDashboard={true}
            /> */}
            <VailotionBarChart
              dashboardData={dashboardDataList}
              isDashboard={true}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                ААН-ийн зөрчлийн график /Сүүлийн нэг жилийн байдлаар/
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {/* {dashboardDataList != null
                  ? dashboardDataList.grievance.total_count
                  : "0"} */}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="0px 0 0 0">
            {/* <FeedBackDashboard
              dashboardData={dashboardDataList}
              isDashboard={true}
            /> */}
            <VailotionCompCount
              dashboardData={dashboardDataList}
              isDashboard={true}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Статистик мэдээлэл дэлгэрэнгүй
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {/* {dashboardDataList != null
                  ? dashboardDataList.grievance.total_count
                  : "0"} */}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="0px 0 0 0">
            {/* <FeedBackDashboard
              dashboardData={dashboardDataList}
              isDashboard={true}
            /> */}
            <DashboardTable
              dashboardData={dashboardDataList}
              isDashboard={true}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 12"
          gridRow="span 6"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Санал хүсэлт шийдвэрлэлтийн явц
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {/* {dashboardDataList != null
                  ? dashboardDataList.grievance.total_count
                  : "0"} */}
              </Typography>
            </Box>
            {/* <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box> */}
          </Box>
          <Box height="250px" m="0px 0 0 0">
            {/* <FeedBackDashboard
              dashboardData={dashboardDataList}
              isDashboard={true}
            /> */}
            <TableGrievance
              dashboardData={dashboardDataList}
              isDashboard={true}
            />
          </Box>
        </Box>
      </Box>
      {/* <DashboardTable dashboardData={dashboardDataList} isDashboard={true} /> */}
      <ChartDetialDialog dashboardData={dashboardDataList} isDashboard={true} />
      <ChartDetialDialogVehicle
        dashboardData={dashboardDataList}
        isDashboard={true}
      />
      <ChartDetialDialogDriver
        dashboardData={dashboardDataList}
        isDashboard={true}
      />
      <ChartDetialDialogEmployee
        dashboardData={dashboardDataList}
        isDashboard={true}
      />
      <ChartDetialDialogViolation
        dashboardData={dashboardDataList}
        isDashboard={true}
      />
      <ChartDetialDialogCompany
        dashboardData={dashboardDataList}
        isDashboard={true}
      />
      <Box height={22}></Box>
    </Box>
  );
};

export default Dashboard;
