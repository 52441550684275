import {
  Box,
  useTheme,
  Button as Btn,
  Chip,
  useMediaQuery,
  Typography,
  Stack,
} from "@mui/material";

import { tokens } from "../../../../theme";

//import DataGrid from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Paging,
  Texts,
  Editing,
  Position,
  Popup as Popups,
} from "devextreme-react/data-grid";

import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  companyReqeustDriverFun,
  getRequest,
  removeRequestList,
  requestIsApprovedConfirm,
  requestIsCheckedConfirm,
  setFilter,
} from "../../../../store/reducers/requestDriver";
import { DataGridConfig } from "../../../../config";
import { blue } from "@mui/material/colors";
import { DateBox, FileUploader, TextArea, TextBox } from "devextreme-react";
import notify from "devextreme/ui/notify";
import { getDriver } from "../../../../store/reducers/driver";
import { removeRequestCompanyList } from "../../../../store/reducers/requestVehicle";
const RequestInData = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formData, setFormData] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formEditData, setFormEditData] = useState("");
  const logedUser = useSelector((state) => state.auth.user);
  const driverDataList = useSelector((state) => state.driver.driverData);
  const filter = useSelector((state) => state.driver.filter);
  const [isChecked, setIsChecked] = useState(false);
  const [isAproved, setIsApproved] = useState(false);
  const [isCheckedNote, setIsCheckedNote] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const dispatch = useDispatch();
  const [multiple, setMultiple] = useState(true);
  const [uploadMode, setUploadMode] = useState("instantly");
  const [accept, setAccept] = useState("image");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documentId, setDocumentId] = useState("");
  const [documentDate, setDocumentDate] = useState(null);
  const setFilters = (field, value) => {
    // console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };

  useEffect(() => {
    if (driverDataList == null) {
      gridRef.current.instance.beginCustomLoading();
      dispatch(getDriver({ is_approved: 0, is_requested: 0 }));
    }
  }, [driverDataList, filter, dispatch]);
  useEffect(() => {
    //  console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getDriver(filter));
    }
  }, [filter, dispatch]);

  useEffect(() => {
    if (driverDataList) {
      setFormData(driverDataList);
      gridRef.current.instance.endCustomLoading();
    }
  }, [driverDataList]);

  //console.log(props);
  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };

  const hideInfo = () => {
    setIsPop(false);
    setIsChecked(false);
    setIsDelete(false);
    // setFormEditData("");
  };

  const setStoreDriver = (data) => {
    gridRef.current.instance.refresh();
    setIsPop(false);
  };
  function deleteRecords() {
    const getSelectedData = gridRef.current.instance.getSelectedRowsData();
    getSelectedData.map((e) => dispatch(removeRequestList(e.id)));
    setIsDelete(false);

    gridRef.current.instance.refresh();
  }
  const onSelectedFilesChanged = useCallback(
    (e) => {
      setSelectedFiles(e.value);
    },
    [setSelectedFiles]
  );
  const submitRequest = () => {
    const getSelectedData = gridRef.current.instance.getSelectedRowsData();
    // console.log(selectedFiles);

    if (documentDate !== null && documentId !== "") {
      const formData = new FormData();
      formData.append("id", 0);
      formData.append("driver_id", getSelectedData[0].id);
      formData.append("official_letter_number", documentId);
      formData.append("official_letter_date", documentDate);
      formData.append("note", "Жолооч бүртгүүлэх хүсэлт");

      //  formData.append("attachment", uploadedFiles);
      // if (selectedFiles.length > 0) {
      //   Array.from(selectedFiles).forEach((img) => {
      //     //  console.log(img);
      //     formData.append("attachment", img);
      //   });
      // }

      dispatch(companyReqeustDriverFun(formData));
      // getSelectedData.map((e) => {
      //   dispatch(
      //     companyReqeustVehicleFun({
      //       id: 0,
      //       vehicle_id: e.id,
      //       note: "Тээврийн хэрэгсэл бүртгүүлэх хүсэлт",
      //       attachment: selectedFiles,
      //     })
      //   );
      // });
      setIsApproved(false);
    } else {
      notify(
        {
          message: "Талбарыг гүйцэд оруулна уу!!!",
          width: 450,
        },
        "error"
      );
    }
  };

  return (
    <>
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 12" }}>
          <Toolbar>
            <Item location="before">
              <Box sx={{ ml: 2 }}>
                <Button
                  icon={"refresh"}
                  onClick={() => {
                    //  gridRef.current.instance.refresh();
                    //gridRef.current.instance.endCustomLoading();
                    //   gridRef.current.instance.beginCustomLoading();
                    // console.log(gridRef.current.instance.getSelectedRowsData());
                  }}
                  type={"normal"}
                  text="Сэргээх"
                />
              </Box>
            </Item>

            <Item location="before">
              <Box sx={{ ml: 1 }}>
                <Button
                  icon={"check"}
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    // console.log(getSelectedData.length);setIsApproved
                    if (getSelectedData.length > 0) {
                      setIsApproved(true);

                      //   console.log(getSelectedData[0].id);
                      //   getSelectedData.map((e) =>
                      //     dispatch(requestIsCheckedConfirm(e.id))
                      //   );
                    } else {
                      notify(
                        {
                          message: "Хүсэлт илгээх жолооч сонгон уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                    // setIsChecked(true);
                  }}
                  type={"success"}
                  text="Хүсэлт илгээх"
                />
              </Box>
            </Item>

            <Item location="after">
              <Box>
                <Button
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                >
                  <Box sx={{ background: "#fff", p: 0.5 }}>
                    <img src="/assets/excel.png" alt="" className="dx-icon" />
                  </Box>
                </Button>
              </Box>
            </Item>
            {/* <Item location="center">
              <Box sx={{ mb: 2 }}>
                <Typography variant="h3">
                  Тээврийн хэрэгслийн хүсэлтүүд жагсаалт
                </Typography>
              </Box>
            </Item> */}
          </Toolbar>

          <DataGrid
            ref={gridRef}
            {...DataGridConfig}
            // showRowLines={true}
            // searchPanel={{ visible: true, placeholder: "Хайлт" }}

            remoteOperations={true}
            dataSource={formData}
            keyExpr="id"
            height={420}
            onRowDblClick={(row) => {
              gridRef.current.instance.editRow(row.rowIndex);
              //   if (isType === 2 && formEditData !== "") {
              //     setFormEditData(row.data);
              //     setIsPop(true);
              //   } else {
              //     alert("no");
              //   }
            }}
            onContentReady={(e) => {
              e.component.option("toolbarItems[0].visible", true);
            }}
            onCellPrepared={(e) => {
              if (e.column.command == "edit") {
                var saveButton = e.cellElement.find("div.role[button']");
                var btn = saveButton.dxButton("instance");
                console.log(btn);
              }
            }}
            onSelectionChanged={onSelectionChanged}
          >
            {" "}
            <Editing
              mode="popup"
              allowUpdating={false}
              allowAdding={false}
              allowDeleting={false}
              // changes={changes}

              useIcons={false}

              //  onChangesChange={onChangesChange}
              // editRowKey={editRowKey}
              //  onEditRowKeyChange={onEditRowKeyChange}
            >
              <Popups
                dragEnabled={false}
                title="Дэлгэрэнгүй мэдээлэл"
                showTitle={true}
                width={!isNonMobile ? "100%" : "60%"}
                height={!isNonMobile ? "100%" : "80%"}
              />
              <Position at="bottom" my="center" collision="fit" />
              <Texts
                confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
                confirmDeleteTitle={"Мэдээлэл устгах"}
                saveRowChanges={"Хадгалах"}
                cancelRowChanges={"Хаах"}
                deleteRow="Устгах"
                addRow="Нэмэх"
                editRow="Засах"
                addRowToNode="Нэмэх"
              />
              <Form>
                <Item itemType="group" colCount={2} colSpan={2}>
                  <Item dataField="company_regnum" />
                  <Item dataField="company_name" />
                </Item>
                <Item
                  itemType="group"
                  caption="Жолоочийн мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="surname" />
                  <Item dataField="lastname" />
                  <Item dataField="firstname" />
                  <Item dataField="register" />
                  <Item dataField="gender" />

                  <Item dataField="eye_color_name" />
                  <Item dataField="blood_type_name" />
                </Item>
                <Item
                  itemType="group"
                  caption="Мэрэгшсэн жолооны мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="dl_number" />
                  <Item dataField="dll" />
                </Item>
                <Item
                  itemType="group"
                  caption="Мэрэгшсэн жолооны мэдээлэл"
                  colCount={2}
                  colSpan={2}
                >
                  <Item dataField="pdl_number" />
                  <Item dataField="class_b" />
                  <Item dataField="class_c" />
                  <Item dataField="class_e" />
                  <Item dataField="class_d" />
                  <Item dataField="class_inter_passenger" />
                  <Item dataField="class_inter_transport" />
                  <Item dataField="class_tourist" />
                  <Item dataField="class_danger" />
                </Item>
                <Item dataField="created" />
                {/* <Item dataField="created_at" format="yyyy-MM-dd HH:mm" /> */}
                {/* <Item
              itemType="group"
              caption="Home Address"
              colCount={2}
              colSpan={2}
            >
              <Item dataField="created" />
              <Item dataField="Address" />
            </Item> */}
              </Form>
            </Editing>
            <Selection mode="multiple" />
            <ColumnChooser enabled={true} />
            <ColumnFixing enabled={true} />
            <Column
              fixed={true}
              alignment={"center"}
              allowSorting={true}
              caption="#"
              cellRender={(e) => {
                return e.rowIndex + 1;
              }}
            />
            <Column caption="Байгуулллага" fixed={true} fixedPosition="left">
              <Column
                dataField="company_regnum"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                format="fixedPoint"
                caption={"ААНБ-н регистер"}
              />
              <Column
                dataField="company_name"
                format="fixedPoint"
                caption={"ААНБ-н нэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
            </Column>
            <Column
              allowSorting={true}
              dataField="is_approved"
              caption="Төлөв"
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 1) {
                  return (
                    <Chip
                      label="Баталгаажуулсан"
                      size="small"
                      color="warning"
                    />
                  );
                } else if (p.value === 0) {
                  return <Chip label="Баталгаажаагүй  " size="small" />;
                }
                return <span className="status status-warning">{p.value}</span>;
              }}
            />
            <Column
              allowSorting={true}
              dataField="is_requested"
              caption="Хүсэлт"
              cellRender={(p) => {
                // console.log(p);
                if (p.value === 1) {
                  return (
                    <Chip
                      label="Хүсэлт илгээсэн"
                      size="small"
                      color="warning"
                    />
                  );
                } else if (p.value === 0) {
                  return <Chip label="Хүсэлт илгээгээгүй" size="small" />;
                }
                return <span className="status status-warning">{p.value}</span>;
              }}
            />
            <Column
              dataField="surname"
              format="fixedPoint"
              caption={"Ургийн овог"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="lastname"
              format="fixedPoint"
              caption={"Овог"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="firstname"
              format="fixedPoint"
              caption={"Нэр"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="register"
              format="fixedPoint"
              caption={"Регистер"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="gender"
              format="fixedPoint"
              caption={"Хүйс"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="eye_color_name"
              format="fixedPoint"
              caption={"Нүдний өнгө"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="blood_type_name"
              format="fixedPoint"
              caption={"Цусны бүлэг"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column caption="Жолооны үнэмлэх">
              <Column
                dataField="dl_number"
                //format="fixedPoint"
                caption={"Үнэмлэх №"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
              <Column
                dataField="dll"
                //format="fixedPoint"
                caption={"Ангилал"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                calculateCellValue={(v) => {
                  var data = [];
                  if (v.dl_a !== null) {
                    data.push("A,");
                  }
                  if (v.dl_b !== null) {
                    data.push("B,");
                  }
                  if (v.dl_c !== null) {
                    data.push("C,");
                  }
                  if (v.dl_d !== null) {
                    data.push("D,");
                  }
                  if (v.dl_e !== null) {
                    data.push("E,");
                  }
                  // console.log(data);
                  return data.map((e) => e);
                }}
              />
              <Column
                dataField="is_deleted"
                format="fixedPoint"
                caption={"Жолоочийн төлөв"}
                cellRender={(v) => {
                  if (v.value === 0) {
                    return (
                      <Chip label="Идэвхтэй" size="small" color="success" />
                    );
                  } else {
                    return <Chip label="Идэвхгүй" size="small" />;
                  }
                }}
              />
            </Column>
            <Column position="center" caption="Мэрэгшсэн жолооны үнэмлэх">
              <Column
                dataField="pdl_number"
                //  format="fixedPoint"
                caption={"МЖҮ №"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
              />
              <Column
                dataField="class_b"
                //  format="fixedPoint"
                caption={"В хүчинтэй огноо"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_c"
                // format="date"
                caption={"С хүчинтэй огноо"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  // console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />

              <Column
                dataField="class_d"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                // format="fixedPoint"
                caption={"D хүчинтэй огноо"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_e"
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                // format="fixedPoint"
                caption={"E хүчинтэй огноо"}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_inter_passenger"
                format="fixedPoint"
                caption={"ОУ-н зорчигч огноо"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_inter_transport"
                format="fixedPoint"
                caption={"ОУ-н тээвэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_tourist"
                format="fixedPoint"
                caption={"Жуулчин тээвэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
              <Column
                dataField="class_danger"
                format="fixedPoint"
                caption={"Аюултай тээвэр"}
                editCellRender={(e) => {
                  return (
                    <TextBox
                      readOnly
                      value={e.value}
                      valueChangeEvent="input"
                    />
                  );
                }}
                cellRender={(v) => {
                  //  console.log(v.value);
                  return v.value !== null ? (
                    v.value
                  ) : (
                    <Chip label="Ангилгүй" size="small" />
                  );
                }}
              />
            </Column>
            <Column
              dataField="created"
              format="fixedPoint"
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
              caption={"Хүсэлт илгээсэн"}
            />
            <Column
              dataField="checked_note"
              format="fixedPoint"
              caption={"Тайлбар"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <Column
              dataField="created_at"
              format="yyyy-MM-dd HH:mm"
              dataType="date"
              caption={"Бүртгэсэн огноо"}
              editCellRender={(e) => {
                return (
                  <TextBox readOnly value={e.value} valueChangeEvent="input" />
                );
              }}
            />
            <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
            <HeaderFilter visible={showHeaderFilter} />
          </DataGrid>
          {/* <LoadPanel
            visible={true}
            shadingColor="rgba(0,0,0,0.4)"
            shading={true}
            showPane={true}
            message="Уншиж байна түр хүлээнэ үү"
          /> */}
          <Popup
            visible={isPop}
            onHiding={hideInfo}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            position={"center"}
            title={
              isType === 2
                ? "Тээврийн хэрэгсэл засах"
                : "Тээврийн хэрэгсэл бүртгэх"
            }
            showTitle={true}
            width={900}
            //height={600}
          >
            sdfdsfds
          </Popup>

          <Popup
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isChecked}
            showTitle={true}
            title="Байцаагч батлах хэсэг"
            onHiding={hideInfo}
            width={350}
            height={280}
          >
            <Stack direction={"column"} justifyContent={"start"}>
              <Typography sx={{ pt: 2, pb: 2, textAlign: "start" }}>
                Та уг жолооч батлах уу ?
              </Typography>
              <Box sx={{ pb: 1, textAlign: "center" }}>
                <TextArea
                  height={100}
                  value={isCheckedNote}
                  // maxLength={this.state.maxLength}
                  // defaultValue={this.state.value}
                  autoResizeEnabled={true}
                  placeholder="Тайлбар оруулах...."
                  onKeyUp={(e) => {
                    //  if (e.event.keyCode === 13) {
                    e.event.preventDefault();
                    setIsCheckedNote(e.event.target.value);
                    //console.log(e.event.target.value);
                    //}
                    // console.log(e.event.target.value);
                  }}
                />
              </Box>

              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  width={120}
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsChecked(false);
                    setIsCheckedNote("");
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Батлах"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();

                    if (isCheckedNote !== "") {
                      // console.log(getSelectedData[0].id);
                      getSelectedData.map((e) =>
                        dispatch(requestIsCheckedConfirm(e.id, isCheckedNote))
                      );
                      setIsChecked(false);
                    } else {
                      notify(
                        {
                          message: "Тайлбар оруулна уу!!!",
                          width: 450,
                        },
                        "error"
                      );
                    }
                  }}
                />
              </Stack>
            </Stack>
          </Popup>

          <Popup
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            visible={isAproved}
            showTitle={true}
            title="Хүсэлт илгээх хэсэг"
            onHiding={hideInfo}
            width={400}
            height={"auto"}
          >
            <Stack direction={"column"} justifyContent={"start"} spacing={2}>
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
              >
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ textAlign: "start" }}>
                    Албан бичиг дугаар
                  </Typography>
                  <TextBox
                    value={documentId}
                    // onValueChange={onValueChange}
                    onKeyUp={(e) => {
                      e.event.preventDefault();
                      setDocumentId(e.event.target.value);
                    }}
                    placeholder=" Албан бичиг дугаар."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography sx={{ textAlign: "start" }}>
                    Албан бичиг огноо
                  </Typography>
                  <DateBox
                    value={documentDate}
                    // onValueChange={onValueChange}
                    defaultValue={
                      documentDate === ""
                        ? new Date().getDate() +
                          "-" +
                          parseInt(new Date().getMonth() + 1) +
                          "-" +
                          new Date().getFullYear()
                        : documentDate
                    }
                    displayFormat="yyyy-M-dd"
                    // onKeyUp={(e) => {
                    //   e.event.preventDefault();
                    //   setDocumentDate(e.event.target.value);
                    // }}
                    onValueChanged={(e) => {
                      // console.log(e.value);
                      setDocumentDate(e.value);
                    }}
                    type="date"
                    placeholder=" Албан бичиг огноо."
                    // console.log(e);
                    // setPlateValue(e);
                    //  }}
                  />
                </Box>
              </Stack>
              {/* 
              <FileUploader
                selectButtonText="Зураг хавсаргах"
                multiple={multiple}
                accept={accept}
                uploadMode={"useForm"}
                labelText="эсвэл файлаа чирч оруулна уу"
                // uploadMode={uploadMode}
                //uploadUrl="http://localhost:5001/"
                onValueChanged={onSelectedFilesChanged}
              /> */}

              <Stack
                direction="row"
                spacing={2}
                sx={{ pb: 2 }}
                justifyContent={"flex-end"}
              >
                <Button
                  width={120}
                  text="Үгүй"
                  icon="remove"
                  onClick={() => {
                    setIsApproved(false);
                  }}
                />

                <Button
                  width={120}
                  position="end"
                  icon="check"
                  text="Тийм"
                  type="default"
                  onClick={() => {
                    submitRequest();
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
          <Popup
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={true}
            visible={isDelete}
            showTitle={false}
            title="Устгах"
            onHiding={hideInfo}
            width={250}
            height={100}
          >
            <Stack direction={"column"} justifyContent={"stretch"}>
              <Typography sx={{ pt: 2, pb: 2, textAlign: "center" }}>
                Та уг хүсэлтийг устгах уу ?
              </Typography>
              <Stack
                direction="row"
                sx={{ pb: 2 }}
                justifyContent={"space-evenly"}
              >
                <Button
                  text="Болих"
                  icon="remove"
                  onClick={() => {
                    setIsDelete(false);
                  }}
                />

                <Button
                  position="end"
                  icon="check"
                  text="Тийм"
                  type="default"
                  onClick={() => {
                    const getSelectedData =
                      gridRef.current.instance.getSelectedRowsData();
                    dispatch(removeRequestCompanyList(getSelectedData[0].id));

                    // deleteRecords();
                  }}
                />
              </Stack>
            </Stack>
          </Popup>
        </Box>
      </Box>
    </>
  );
};

export default RequestInData;
