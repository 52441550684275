import {
  Box,
  useTheme,
  Button as Btn,
  Stack,
  Typography,
  Chip,
  Divider,
} from "@mui/material";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  LoadPanel,
  Texts,
  Editing,
  Paging,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { Popup } from "devextreme-react/popup";
import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { DataGridConfig } from "../../config";
import notify from "devextreme/ui/notify";
import FeedBackConfirmed from "./feedBackConfirm";
import {
  feedBackClosed,
  getGrievanceDecisionList,
  getGrievanceDecisionTypeListFun,
} from "../../store/reducers/feedBack";
import FilterBox from "./filter";
import { getFeedBack, setFilter } from "../../store/reducers/feedBack";
import {
  Image,
  PhotoCamera,
  RecordVoiceOverTwoTone,
  VoiceChat,
} from "@mui/icons-material";
import Gallery from "devextreme-react/gallery";
import moment from "moment";
import ReactAudioPlayer from "react-audio-player";
import BaseHelper from "../../helper/BaseHelper";
import { SelectBox, TextArea } from "devextreme-react";
import { useNavigate } from "react-router-dom";
const FeedBackList = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const feedBackDataList = useSelector((state) => state.feedBack.feedBackData);
  const getGrievanceDecisionTypeListData = useSelector(
    (state) => state.feedBack.grievanceDecisionTypeListData
  );
  const filter = useSelector((state) => state.feedBack.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [decissionId, setDecissionId] = useState("");
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [ispicView, setIspicView] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");
  const [mediaType, setMediaType] = useState(0);
  const [commentClosed, setCommentClosed] = useState("");
  const navigate = useNavigate();
  const [getPermision, setGetPermision] = useState(false);
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  useEffect(() => {
    if (getGrievanceDecisionTypeListData === null) {
      //  const data = { company_id: 1 };
      dispatch(getGrievanceDecisionTypeListFun());
    }
  }, [getGrievanceDecisionTypeListData, dispatch]);
  useEffect(() => {
    if (feedBackDataList === null && Object.keys(filter).length === 0) {
      dispatch(
        getFeedBack({
          ...filter,
          start_date: moment().year() + "-" + "01" + "-01",
          end_date: moment().year() + "-" + "12" + "-31",
        })
      );
    } else {
      setFormData(feedBackDataList);
    }
  }, [feedBackDataList, filter, dispatch]);
  useEffect(() => {
    if (Object.keys(filter).length > 0) {
      // console.log(Object.keys(filter).length);
      dispatch(getFeedBack(filter));
    }
  }, [filter, dispatch]);
  //console.log(logedUser);
  function permissionCheck(id) {
    // console.log(id);
    const perm = logedUser.permission.find((e) => e.id === 4);
    const permCheck = perm.child.find((e) => e.id === id && e.is_update === 1);
    // console.log(perm);
    // console.log(typeof permCheck === "undefined" ? false : true);
    return typeof permCheck === "undefined" ? false : true;
    // setGetPermision(typeof getFruit === "undefined" ? false : true);
  }
  // useEffect(() => {
  //   gridRef.current.instance.beginCustomLoading();
  //   if (props.feedBackListData) {
  //     setFormData(props.feedBackListData);
  //     gridRef.current.instance.endCustomLoading();
  //   }
  // }, [props]);
  // console.log(props.feedBackListData);
  const gridRef = useRef();

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log("selectedRowKeys");
    // console.log(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);
    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };
  const hideInfo = () => {
    setIsPop(false);
    // setIsConfirm(false);
    setIspicView(false);
  };

  function deleteRecords() {
    selectIndex.forEach((key) => {
      gridRef.current.instance.deleteRow(key);
    });
  }
  function closeIsPop() {
    setIsPop(false);
  }
  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " цаг, " : " цаг, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " мин, " : " мин, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " сек" : " сек") : "";
    return hDisplay + mDisplay + sDisplay;
  }
  //console.log(logedUser.employee[0].company_id);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar style={{ paddingBottom: "10px" }}>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <FilterBox getFilters={setFilters} />
          </Box>
        </Item>
      </Toolbar>
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(
                  getFeedBack({
                    ...filter,
                    start_date: moment().year() + "-" + "01" + "-01",
                    end_date: moment().year() + "-" + "12" + "-31",
                  })
                );
                gridRef.current.instance.refresh();
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(4, 78, "is_update", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"add"}
              onClick={() => {
                const getSelectedData =
                  gridRef.current.instance.getSelectedRowsData();
                // console.log(formEditData);
                if (formEditData !== "") {
                  dispatch(getGrievanceDecisionList(formEditData.id));
                  setIsPop(true);
                } else {
                  notify(
                    {
                      message: "Шилжүүлэх санал хүсэлтээ сонгон уу!!!",
                      width: 450,
                    },
                    "error"
                  );
                }
                // formEditData.id
                // console.log(gridRef.current.instance.getSelected);
                // dispatch(getGrievanceDecisionList(row.data.id));
              }}
              type={"success"}
              text="Шилжүүлэх"
            />
          </Box>
        </Item>

        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(4, 78, "is_update", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"check"}
              style={{ "background-color": "green" }}
              onClick={(e) => {
                setDecissionId("");
                setCommentClosed("");
                if (formEditData !== "") {
                  if (formEditData.is_closed === 0) {
                    gridRef.current.instance.deleteRow(selectIndex);
                    //gridRef.current.instance.deselectAll();
                    setIsConfirm(true);
                  } else {
                    notify(
                      {
                        message: "Уг санал хүсэлт шийдвэрлэгдсэн байна.!!!",
                        width: 450,
                      },
                      "error"
                    );
                  }
                } else {
                  notify(
                    {
                      message: "Та шийдвэрлэж хаах санал хүсэлтээ сонгон уу!!!",
                      width: 450,
                    },
                    "error"
                  );
                }
              }}
              type={"info"}
              text="Шийдвэрлэж хаах"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(4, 78, "is_update", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"fieldchooser"}
              style={{ "background-color": "blue" }}
              onClick={(e) => {
                setFilters("is_closed", 1);
              }}
              type={"warning"}
              text="Шийдвэрлэсэн"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(4, 78, "is_update", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"fields"}
              onClick={(e) => {
                setFilters("is_closed", 0);
              }}
              type={"primary"}
              text="Шийдвэрлээгүй"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box>
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        dataSource={formData}
        showRowLines={true}
        wordWrapEnabled={true}
        keyExpr="id"
        height={600}
        width={"100%"}
        onRowDblClick={(row) => {
          // console.log(row.data.id);
          dispatch(getGrievanceDecisionList(row.data.id));
          setFormEditData(row.data);
          setIsPop(true);
        }}
        onSelectionChanged={onSelectionChanged}
      >
        <Editing mode="popup">
          <Texts
            confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
            confirmDeleteTitle={"Жолооч устгах"}
            saveRowChanges={"Хадгалах"}
            cancelRowChanges={"Цуцлах"}
          />
        </Editing>
        <Selection mode="single" />
        <ColumnChooser enabled={false} />
        <ColumnFixing enabled={true} />

        <Column caption="Байгуулллага" fixed={true}>
          <Column
            allowSorting={true}
            fixed={true}
            caption="#"
            cellRender={(e) => {
              return e.rowIndex + 1;
            }}
          />
          <Column
            dataField="company_regnum"
            format="fixedPoint"
            caption={"ААНБ-н регистер"}
          />
          <Column
            dataField="company_name"
            format="fixedPoint"
            caption={"ААНБ-н нэр"}
          />
          <Column
            dataField="attachment"
            // format="yyyy-MM-dd HH:mm"
            // dataType="date"
            // width={300}
            caption={"Медиа"}
            cellRender={(v) => {
              //  console.log(v.data.source_id);
              if (v.data.source_id === 1) {
                // setIspicView(true);setAudioUrl
                return (
                  <Button
                    // width={120}
                    //  hint="Clone"
                    //icon="../../../assets/audio.png"
                    // visible={this.isCloneIconVisible}
                    // disabled={this.isCloneIconDisabled}
                    onClick={(e) => {
                      setAudioUrl(v.data.call_record);
                      setIspicView(true);
                      setMediaType(1);
                    }}
                  >
                    <RecordVoiceOverTwoTone />
                  </Button>
                );
              } else {
                return (
                  <Button
                    hint="Clone"
                    icon="image"
                    // visible={this.isCloneIconVisible}
                    // disabled={this.isCloneIconDisabled}
                    onClick={(e) => {
                      setMediaType(2);
                      setAttachment(
                        v.value.map(
                          (e) => "https://api.ptd.ub.gov.mn/api/v1/" + e.url
                        )
                      );
                      setIspicView(true);
                      // console.log(attachment);
                    }}
                  />
                );
              }
            }}
          />
        </Column>
        <Column
          dataField="phone_number"
          format="fixedPoint"
          caption={"Илгээсэн Утас"}
          cellRender={(v) => {
            return logedUser.employee[0].company_id === 1 ||
              logedUser.employee[0].company_id === 259
              ? v.value
              : v.value !== null
              ? v.value.substring(0, 2) + "*****" + v.value.substring(8 - 2)
              : "";
            // console.log(v.value);
          }}
        />
        <Column
          dataField="plate_no"
          // format="fixedPoint"
          caption={"Улсын дугаар"}
        />

        <Column
          dataField="source_name"
          format="fixedPoint"
          caption={"Эх үүсвэр"}
        />
        {/* <Column
          dataField="number"
          format="fixedPoint"
          caption={"Залгасан дугаар"}
        /> */}
        <Column
          dataField="duration"
          format="fixedPoint"
          caption={"Ярьсан хугацаа"}
          cellRender={(val) => {
            // console.log(val.value);
            return secondsToHms(val.value);
          }}
        />
        <Column dataField="type_name" format="fixedPoint" caption={"Хүсэлт"} />
        <Column
          dataField="is_closed"
          caption={"Төлөв"}
          cellRender={(e) => {
            // console.log(e.data.status_name);
            if (e.value === 1) {
              return <Chip label="Шийдвэрлэсэн" size="small" color="success" />;
            } else if (e.data.status_name === "Шилжүүлсэн") {
              return <Chip label="Шилжүүлсэн" color="warning" size="small" />;
            } else {
              return <Chip label="Шийдвэрлээгүй" size="small" />;
            }
          }}
        />
        <Column
          dataField="decision_type_name"
          format="fixedPoint"
          caption={"Шийдвэрлэсэн төлөв"}
        />
        <Column
          dataField="note"
          format="fixedPoint"
          caption={"Нэмэлт мэдээлэл"}
        />
        <Column
          dataField="user_info"
          format="fixedPoint"
          caption={"Хүлээн авсан"}
        />

        <Column dataField="created" format="fixedPoint" caption={"Илгээсэн"} />

        <Column
          dataField="created_at"
          format="yyyy-MM-dd HH:mm"
          dataType="date"
          caption={"Огноо"}
        />

        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />

        <LoadPanel enabled={true} />
      </DataGrid>
      <Popup
        visible={isPop}
        onHiding={hideInfo}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        position={"top"}
        title={"Санал хүсэлт шийдвэрлэх"}
        showTitle={true}
        width={1200}
        height={"auto"}
      >
        <FeedBackConfirmed
          permistion={permissionCheck}
          feedBackData={formEditData}
          isCloseFun={closeIsPop}
          loggedUserData={logedUser}
        />
      </Popup>
      <Popup
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        visible={isConfirm}
        showTitle={false}
        title="Устгах"
        onHiding={() => {
          setIsConfirm(false);
        }}
        width={450}
        height={"auto"}
      >
        <Stack direction={"column"} justifyContent={"stretch"} spacing={1}>
          <Typography variant="h5" sx={{ pt: 2, pb: 1, textAlign: "left" }}>
            Санал хүсэлтийг шийдвэрлэж хаах
          </Typography>
          <Divider />
          <Stack direction={"column"} justifyContent={"stretch"}>
            <Box sx={{ mb: 2 }}>
              {mediaType === 1 ? (
                <ReactAudioPlayer
                  style={{ width: "100%" }}
                  src={audioUrl}
                  preload="auto"
                  //autoPlay
                  controls
                />
              ) : (
                <Gallery
                  id="gallery"
                  dataSource={attachment}
                  width={"100%"}
                  height={"auto"}
                  slideshowDelay={2000}
                  loop={false}
                  showNavButtons={true}
                  dragEnabled={true}
                  showIndicator={true}
                />
              )}
            </Box>
            <Divider />
          </Stack>
          <Typography>Хаах төрөл сонгох</Typography>
          <SelectBox
            // style={{ "margin-left": "12px" }}
            // width="250"
            // height={"30"}
            items={getGrievanceDecisionTypeListData}
            displayExpr="name"
            valueExpr="id"
            value={decissionId}
            placeholder="Хаах төрөл сонгох"
            onValueChanged={(e) => {
              setDecissionId(e.value);
              // console.log(e.value);
            }}
          />
          <Typography sx={{}}>Тайлбар оруулах</Typography>
          <TextArea
            placeholder="Тайлбар оруулах"
            valueChangeEvent="input"
            value={commentClosed}
            onValueChanged={(e) => {
              setCommentClosed(e.value);

              // console.log(e.value);
            }}
            //  value={comment}
          />
          <Divider />
          <Stack
            direction="row"
            sx={{ pb: 2 }}
            spacing={2}
            justifyContent={"end"}
          >
            <Button
              text="Болих"
              icon="remove"
              onClick={() => {
                setIsConfirm(false);
              }}
            />

            <Button
              position="end"
              icon="check"
              text="Шийдвэрлэж хаах"
              type="default"
              onClick={() => {
                if (decissionId !== "") {
                  /// console.log(formEditData.id);
                  dispatch(
                    feedBackClosed({
                      id: formEditData.id,
                      comment: commentClosed,
                      type_id: decissionId,
                    })
                  );
                  setDecissionId("");
                  setCommentClosed("");
                  setIsConfirm(false);
                } else {
                  notify(
                    {
                      message: "Хаах төрөл сонгон уу!!!  ",
                      width: 450,
                    },
                    "error"
                  );
                }
              }}
            />
          </Stack>
        </Stack>
      </Popup>
      <Popup
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={true}
        visible={ispicView}
        showTitle={true}
        showIndicator={true}
        title="Медиа"
        onHiding={hideInfo}
        width={600}
        height={mediaType === 1 ? 200 : "auto"}
      >
        <Stack direction={"column"} justifyContent={"stretch"}>
          <Box sx={{ mb: 2 }}>
            {mediaType === 1 ? (
              <ReactAudioPlayer
                style={{ width: "100%" }}
                src={audioUrl}
                preload="auto"
                //autoPlay
                controls
              />
            ) : (
              <Gallery
                id="gallery"
                dataSource={attachment}
                width={"100%"}
                height={"auto"}
                slideshowDelay={2000}
                loop={false}
                showNavButtons={true}
                dragEnabled={true}
                showIndicator={true}
              />
            )}
          </Box>
          <Divider />
        </Stack>
      </Popup>
    </>
  );
};

export default FeedBackList;
