import { createSlice } from "@reduxjs/toolkit";

import {
  createFeedBackService,
  inspectionCall,
  feedBackDataCall,
  feedBackImageCall,
  feedBackRemove,
  feedBackSearchCall,
  getGrievanceDecisionListCall,
  grievanceMoveCall,
  getGrievanceDecisionTypeListCall,
  backDecisionCall,
  feedBackDataEditCall,
  feedBackDataClosedCall,
  getFeedBackTypeEditCall,
  getFeedBackTypeDeleteCall,
} from "../../utils/services/feedBackService";

import {
  setErrorSnackbarOpen,
  setInfoSnackbarOpen,
  setPosition,
  setSuccessSnackbarMessage,
  setSuccessSnackbarOpen,
} from "./uiReducer";
import notify from "devextreme/ui/notify";
import { getFeedBackTypeCall } from "../../utils/services/feedBackService";
import BaseHelper from "../../helper/BaseHelper";
export const slice = createSlice({
  name: "feedBack",
  initialState: {
    feedBackData: null,
    feedBackSearchData: null,
    feedBackTypeList: null,
    trailerInspectionData: null,
    grievanceDecisionList: null,
    grievanceDecisionTypeListData: null,
    grievanceIsEdit: false,
    filter: {},
    isLoading: true,
  },

  reducers: {
    setFilter: (state, { payload }) => {
      console.log(payload);
      state.filter = payload;
    },
    setLoading: (state, { payload }) => {
      // console.log(payload);
      state.isLoading = payload;
    },
    setFeedBackSearchData: (state, { payload }) => {
      // console.log(payload);
      state.feedBackSearchData = payload;
    },
    setGrievanceDecisionList: (state, { payload }) => {
      // console.log(payload);
      state.grievanceDecisionList = payload;
    },
    setGrievanceIsEdit: (state, { payload }) => {
      // console.log(payload);
      state.grievanceIsEdit = payload;
    },
    setFeedBackData: (state, { payload }) => {
      // console.log(payload);
      state.feedBackData = payload;
    },
    setFeedBackTypeList: (state, { payload }) => {
      // console.log(payload);
      state.feedBackTypeList = payload;
    },
    setGrievanceDecisionTypeListData: (state, { payload }) => {
      // console.log(payload);
      state.grievanceDecisionTypeListData = payload;
    },
    setCreateFeedBack: (state, { payload }) => {
      // console.log(payload);
      state.feedBackData.unshift(payload);
    },
    setEditFeedBack: (state, { payload }) => {
      // console.log(payload);
      let listCopy = [...state.feedBackData];
      listCopy.filter((item) => {
        if (item.id === payload.id) {
          item.phone_number = payload.phone_number;
        }
      });
    },
    setRemoveFeedBack: (state, { payload }) => {
      // console.log(payload);

      let index = state.feedBackData.findIndex(({ id }) => id === payload);
      state.feedBackData.splice(index, 1);
    },
  },
});

export const {
  setLoading,
  setFeedBackSearchData,
  setFeedBackData,
  setCreateFeedBack,
  setRemoveFeedBack,
  setFilter,
  setEditFeedBack,
  setFeedBackTypeList,
  setGrievanceDecisionList,
  setGrievanceIsEdit,
  setGrievanceDecisionTypeListData,
} = slice.actions;

export const getFeedBack = (filter) => async (dispatch, getState) => {
  try {
    // console.log(filter);
    const responseData = await feedBackDataCall(filter);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setFeedBackData(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setFeedBackInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
      dispatch(setFeedBackData(null));
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setFeedBackData(null));
    console.log(error);
  }
};
export const feedBackEdit = (data) => async (dispatch, getState) => {
  try {
    // console.log(filter);
    const responseData = await feedBackDataEditCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(getFeedBack());
      dispatch(setLoading(false));
      // dispatch(setFeedBackInspectionData(responseData.data));
      BaseHelper.nodifyFun(responseData.status.message, "success");
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
      dispatch(setFeedBackData(null));
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setFeedBackData(null));
    console.log(error);
  }
};
export const feedBackClosed = (data) => async (dispatch, getState) => {
  try {
    // console.log(filter);
    const responseData = await feedBackDataClosedCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(getFeedBack());
      dispatch(setLoading(false));
      // dispatch(setFeedBackInspectionData(responseData.data));
      BaseHelper.nodifyFun(responseData.status.message, "success");
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
      dispatch(setFeedBackData(null));
    }

    //console.log(alert);
  } catch (error) {
    dispatch(setFeedBackData(null));
    console.log(error);
  }
};
export const grievanceMove = (data) => async (dispatch, getState) => {
  try {
    //console.log(filter);
    dispatch(setGrievanceIsEdit(true));
    const responseData = await grievanceMoveCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      dispatch(setGrievanceIsEdit(false));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const backDecisionFun = (data) => async (dispatch, getState) => {
  try {
    //console.log(filter);
    const responseData = await backDecisionCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      dispatch(setGrievanceIsEdit(false));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getFeedBackTypeList = (filter) => async (dispatch, getState) => {
  try {
    const responseData = await getFeedBackTypeCall(filter);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      //   console.log(responseData.data);
      dispatch(setFeedBackTypeList(responseData.data));
      dispatch(setLoading(false));
      // dispatch(setFeedBackInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getFeedBackTypeEditFun = (data) => async (dispatch, getState) => {
  try {
    const responseData = await getFeedBackTypeEditCall(data);
    // console.log(responseData);
    if (responseData.status.code === 200) {
      //   console.log(responseData.data);
      BaseHelper.nodifyFun(responseData.status.message, "success");
      dispatch(setLoading(false));
      dispatch(getFeedBackTypeList());
      // dispatch(setFeedBackInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getFeedBackTypeDeleteFun =
  (data) => async (dispatch, getState) => {
    try {
      const responseData = await getFeedBackTypeDeleteCall(data);
      // console.log(responseData);
      if (responseData.status.code === 200) {
        BaseHelper.nodifyFun(responseData.status.message, "success");
        dispatch(setLoading(false));
        dispatch(getFeedBackTypeList());
        // dispatch(setFeedBackInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };

export const createFeedBack =
  (formData, config) => async (dispatch, getState) => {
    try {
      const responseData = await createFeedBackService(formData);
      // console.log(responseData);
      if (responseData) {
        dispatch(setCreateFeedBack(responseData.data));
        // console.log(responseData.data);
        dispatch(setFeedBackSearchData(null));
        BaseHelper.nodifyFun(responseData.status.message, "success");
        // dispatch(setFeedBackInspectionData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const removeFeedBackList =
  (feedBackId) => async (dispatch, getState) => {
    try {
      const responseData = await feedBackRemove(feedBackId);
      if (responseData.status.code === 200) {
        dispatch(setRemoveFeedBack(feedBackId));
        BaseHelper.nodifyFun(responseData.status.message, "success");
      }
    } catch (error) {
      console.log(error);
    }
  };
export const getFeedBackSearch = (reg) => async (dispatch, getState) => {
  try {
    // console.log(data);
    const regnum = { regnum: reg };
    const responseData = await feedBackSearchCall({ regnum });
    // console.log(responseData);
    if (responseData.status.code === 200) {
      // console.log("---------------");
      // console.log(responseData.data);
      dispatch(setFeedBackSearchData(responseData.data));
      // dispatch(setFeedBackInspectionData(responseData.data));
    } else {
      //console.log(responseData);
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getGrievanceDecisionList =
  (grievanceId) => async (dispatch, getState) => {
    try {
      //  console.log(data);

      const data = { grievance_id: grievanceId };
      const responseData = await getGrievanceDecisionListCall(data);
      // console.log(responseData);
      if (responseData.status.code === 200) {
        // console.log("---------------");
        // console.log(responseData.data);
        dispatch(setGrievanceDecisionList(responseData.data));

        // dispatch(setFeedBackInspectionData(responseData.data));
      } else {
        //console.log(responseData);
        //console.log("nnnnnnn");
        dispatch(setGrievanceDecisionList(null));
        // notify(
        //   {
        //     message: responseData.status.message,
        //     width: 450,
        //   },
        //   "error"
        // );
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getFeedBackData = (data) => async (dispatch, getState) => {
  try {
    // console.log(data);
    const plate_no = { plate_no: data };
    const responseData = await feedBackSearchCall({ plate_no });

    if (responseData.status.code === 200) {
      // console.log(responseData.data);
      dispatch(setLoading(false));
      //  dispatch(setFeedBackInspectionData(responseData.data));
    } else {
      BaseHelper.nodifyFun(responseData.status.message, "error");
    }

    //console.log(alert);
  } catch (error) {
    console.log(error);
  }
};
export const getTrailerInspectionData =
  (data) => async (dispatch, getState) => {
    try {
      const cabin_no = { cabin_no: data };
      const responseData = await inspectionCall({ cabin_no });

      if (responseData.status.code === 200) {
        // console.log(responseData.data);
        dispatch(setLoading(false));
        // dispatch(setTrailerInspectionData(responseData.data));
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };
export const getGrievanceDecisionTypeListFun =
  () => async (dispatch, getState) => {
    try {
      const responseData = await getGrievanceDecisionTypeListCall();
      //   console.log(responseData.data);
      if (responseData.status.code === 200) {
        dispatch(setLoading(false));
        dispatch(setGrievanceDecisionTypeListData(responseData.data));
      } else {
        BaseHelper.nodifyFun(responseData.status.message, "error");
      }

      //console.log(alert);
    } catch (error) {
      console.log(error);
    }
  };

export default slice.reducer;
