import { Box } from "@mui/material";
import Header from "../../../../components/Header";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

import RequestInData from "../../../../components/request/company/driver/requestInData";
import RequestConfirmedData from "../../../../components/request/company/driver/requestConfirmed";

const RequestCompanyDriver = () => {
  return (
    <Box m="20px">
      <Header
        title="Жолооч баталгаажуулах хүсэлтүүд"
        subtitle="Жолооч баталгаажуулах хүсэлтүүд"
      />
      <Header subtitle="1. Хүсэлт илгээх боломжтой" />
      <Stack>
        <Box>
          <RequestInData />
        </Box>
        <Box height={12}></Box>
        <Header
          //title="Илгээсэн хүсэлтүүд"
          subtitle="2. Хүсэлт илгээсэн"
        />
        <Box>
          <RequestConfirmedData />
        </Box>
      </Stack>
    </Box>
  );
};

export default RequestCompanyDriver;
