import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";

const Contacts = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.dashboardData != null) {
      setData(props.dashboardData.table);
    }
  }, [props]);
  // console.log(data);
  const columns = [
    { field: "id", headerName: "id", flex: 0.5 },
    {
      field: "title",
      headerName: "ҮА чиглэл",
      cellClassName: "name-column--cell",
    },
    { field: "total_company", headerName: "ААНБ тоо" },
    {
      field: "total_driver",
      headerName: "Жолоочийн тоо",
      flex: 1,
    },
    {
      field: "total_vehicle",
      headerName: "ТХ-н тоо",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "total_employees",
      headerName: "Ажилчдын тоо",
      flex: 1,
    },
    {
      field: "total_grievances",
      headerName: "Санал хүсэлтийн тоо",
      flex: 1,
    },
    {
      field: "total_violations",
      headerName: "Зөрчлын тоо",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Статистик мэдээлэл"
        subtitle="Статистик мэдээлэл дэлгэрэнгүй"
      />
      <Box
        m="40px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Contacts;
