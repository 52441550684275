import { Box, Button, TextField, Typography } from "@mui/material";

import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { HorizontalRule, LineAxis } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  FilterRow,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { getCompany } from "../../../store/reducers/company";
import { DropDownBox } from "devextreme-react";
import { getFeedBackTypeList } from "../../../store/reducers/feedBack";
import { setFilter } from "../../../store/reducers/report";
import moment from "moment";
const FilterBox = (props) => {
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };

  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(0, "days").format("YYYY-M-D")
  );
  const logedUser = useSelector((state) => state.auth.user);
  const [typeDateGridBoxValue, setTypeDateGridBoxValue] = useState("year");
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);

  const [formData, setFormData] = useState([]);

  const [typeDate, setTypeDate] = useState([
    {
      id: "year",
      name: "Оноор",
    },
    { id: "month", name: "Сараар" },
    { id: "day", name: "Өдрөөр" },
  ]);
  const gridColumns = ["name"];
  const filter = useSelector((state) => state.report.filter);
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeDateGridBoxValue === "") {
      setTypeDateGridBoxValue("month");
    }
  }, [typeDateGridBoxValue]);
  const dateFilterFun = (value, type) => {
    if (type == 1) {
      console.log(value);

      dispatch(
        setFilter({
          ...filter,
          start_date: value,
          end_date: endDate,
        })
      );
    } else {
      dispatch(
        setFilter({
          ...filter,
          start_date: startDate,
          end_date: value,
        })
      );
    }
  };
  // console.log(feedBackType);
  const dataCompNameGridRender = () => {
    return (
      <DataGrid
        dataSource={typeDate}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={typeDateGridBoxValue}
        onSelectionChanged={(e) => {
          //  console.log(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          // console.log(selValue);
          setTypeDateGridBoxValue(selValue[0]);
          //  console.log(selValue[0]);
          setIsGridBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  return (
    <>
      <div style={{ margin: "10px" }} className="caption">
        Шүүлт
      </div>
      <Stack direction={"row"} spacing={1}>
        <Typography>Төрөл сонгох:</Typography>
        <DropDownBox
          height={30}
          value={typeDateGridBoxValue}
          opened={isGridBoxOpened}
          valueExpr="id"
          deferRendering={true}
          displayExpr={gridBoxDisplayExpr}
          placeholder="Төрөл сонгох..."
          showClearButton={false}
          dataSource={typeDate}
          onValueChanged={(e) => {
            setTypeDateGridBoxValue(e.value);
            // console.log(e.value);
            if (e.value === "year") {
              dispatch(
                setFilter({
                  date_type: e.value,
                })
              );
            } else {
              dispatch(
                setFilter({
                  ...filter,
                  date_type: e.value,
                })
              );
            }

            //  setFilter("date_type", e.value);
          }}
          onOptionChanged={(e) => {
            // console.log(e.name);
            if (e.name === "opened") {
              setIsGridBoxOpened(e.value);
              // props.filterCompany(null);
            }
          }}
          contentRender={dataCompNameGridRender}
        />
        <Typography>Эхлэх:</Typography>
        <Stack
          alignItems={"center"}
          direction={"row"}
          spacing={1}
          sx={{ mt: 1 }}
        >
          <DateBox
            height={30}
            defaultValue={startDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              //  console.log(e.value);
              setStartDate(e.value);
              dateFilterFun(e.value, 1);
              // setFilter("start_date", e.value);
            }}
            type="date"
          />
          <Typography>Дуусах:</Typography>
          <DateBox
            height={30}
            defaultValue={endDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              // console.log(e.value);
              setEndDate(e.value);
              dateFilterFun(e.value, 2);
              // setFilter("end_date", e.value);
            }}
            type="date"
          />
        </Stack>
      </Stack>
    </>
  );
};

export default FilterBox;
