import { useCallback, useState } from "react";

import { useDispatch } from "react-redux";

export default function PasswordDialogModal(Component) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  // if (!open) {
  //   dispatch(setClearGiverance());
  // } else {
  //   dispatch(setClearGiverance());
  // }
  //console.log({ open });
  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const closeModal = useCallback((event, reason) => {
    // if (reason && reason === "backdropClick") return;
    // console.log(event);
    setOpen(false);
    // dispatch(setClearGiverance());
  }, []);

  const DialogComponent = useCallback(
    ({ ...props }) => {
      // console.log(props);

      // setOpen(props.setIsOpen);

      if (!open) {
        return null;
      }
      if (Component) {
        // console.log(Component);
        return (
          <Component
            open={open}
            onClose={(event, reason) => setOpen(false)}
            {...props}
          />
        );
      }
    },
    [open, Component]
  );

  return [DialogComponent, openDialog, closeModal];
}
