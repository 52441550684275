import { Box, Typography } from "@mui/material";

import Header from "../../components/Header";
//import DataGrid from "devextreme-react/data-grid";

import React, { useEffect, useRef, useState } from "react";

import { Stack } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import themes from "devextreme/ui/themes";

import { useDispatch, useSelector } from "react-redux";
import TreeView from "devextreme-react/tree-view";
import {
  branchTreeListFun,
  getEmployeTreeList,
} from "../../store/reducers/employe";
import SelectBox from "devextreme-react/select-box";
import CheckBox from "devextreme-react/check-box";
import { DataGrid, DropDownBox, List } from "devextreme-react";
import { getUserListData, setFilter } from "../../store/reducers/user/auth";
import EmployeList from "../../components/employee/employeList";
import {
  FilterRow,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { getCompany } from "../../store/reducers/company";

const Employe = () => {
  const employeTreeListData = useSelector(
    (state) => state.employe.employeTreeListData
  );
  const getBranchTreeListData = useSelector(
    (state) => state.employe.branchTreeListData
  );
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };
  const logedUser = useSelector((state) => state.auth.user);
  const filter = useSelector((state) => state.auth.filter);
  const filterCompany = useSelector((state) => state.company.filter);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const gridRef = useRef();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [employes, setEmployes] = useState([]);
  const getUserList = useSelector((state) => state.auth.userListData);
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [isGridCompNameBoxOpened, setIsGridCompNameBoxOpened] = useState(false);
  const getCompanyList = useSelector((state) => state.company.companyData);
  const dispatch = useDispatch();

  const setFilters = (field, value) => {
    //  console.log(field, value);
    dispatch(setFilter({ [field]: value }));
  };
  useEffect(() => {
    if (employeTreeListData == null) {
      if (companyId === 0) {
        if (logedUser !== null) {
          const data = { company_id: logedUser.employee[0].company_id };
          dispatch(getEmployeTreeList(data));
          setCompanyId(logedUser.employee[0].company_id);
        }
      }
    }
  }, [employeTreeListData, logedUser, companyId, dispatch]);
  //console.log(companyId);
  useEffect(() => {
    if (employeTreeListData !== null) {
      var tempProps = JSON.parse(JSON.stringify(employeTreeListData));

      setEmployes(tempProps);
    }
  }, [employeTreeListData, dispatch]);
  // useEffect(() => {
  //   if (getUserList == null) {
  //     if (logedUser !== null) {
  //       dispatch(
  //         getUserListData({
  //           company_id: logedUser.employee[0].company_id,
  //         })
  //       );
  //     }
  //   }
  // }, [getUserList, logedUser, filter, dispatch]);
  useEffect(() => {
    if (getUserList == null) {
      if (logedUser !== null) {
        if (Object.keys(filter).length === 0) {
          setFilters("company_id", logedUser.employee[0].company_id);
        }

        // dispatch(
        //   getUserListData({
        //     company_id: logedUser.employee[0].company_id,
        //   })
        // );
      }
    }
  }, [getUserList, logedUser, filter, setFilters]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getUserListData(filter));
    }
  }, [filter, dispatch]);
  useEffect(() => {
    if (getCompanyList == null) {
      dispatch(getCompany(filterCompany));
    }
  }, [filterCompany, getCompanyList, dispatch]);
  //console.log(employes);
  const syncSelection = (treeView) => {
    // console.log(treeView);
    const selectedEmployees = treeView
      .getSelectedNodes()
      .map((node) => node.itemData);
    //console.log(selectedEmployees);
    setSelectedEmployees(selectedEmployees);

    // console.log(selectedEmployees);
    if (selectedEmployees.length > 0) {
      setFilters(
        "branch_id",
        selectedEmployees.length > 0
          ? selectedEmployees[0].id
          : logedUser.employee[0].branch_id
      );
    }

    // console.log(selectedEmployees.length > 0 ? selectedEmployees[0].id : "");

    // this.setState(() => ({ selectedEmployees }));
  };
  const treeViewSelectionChanged = (e) => {
    //console.log(e);
    syncSelection(e.component);
  };

  const treeViewContentReady = (e) => {
    //  console.log(e.component);
    syncSelection(e.component);
  };
  function renderTreeViewItem(item) {
    // console.log(item);
    return `${item.name}`;
  }
  function renderListItem(item) {
    // return `${item.name} ${item.id} (${item.created_at})`;
    return `${item.name})`;
  }
  //console.log(logedUser);
  const gridColumns = ["regnum", "name"];

  //console.log(employes);
  const dataCompNameGridRender = () => {
    return (
      <DataGrid
        dataSource={getCompanyList}
        columns={gridColumns}
        hoverStateEnabled={true}
        //  selectedRowKeys={companyId}
        onSelectionChanged={(e) => {
          //  console.log(e.currentDeselectedRowKeys[0]["id"]);
          //  setFilters("company_id", e.currentDeselectedRowKeys[0]["id"]);
          //  props.filterCompany(e.selectedRowsData);
          setCompanyName(e.selectedRowKeys[0].name);
          // dispatch(setFilter({}));
          setFilters("company_id", e.selectedRowKeys[0].id);
          // console.log(e.selectedRowKeys);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          setCompanyId(parseInt(selValue[0]));

          //  console.log(selValue[0]);
          setIsGridCompNameBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  return (
    <Box m="20px">
      <Header title="Хүний нөөц" subtitle="Хүий нөөцийн удирдлагын хэсэг" />
      <Box
        display="grid"
        gap="30px"
        gridTemplateColumns="repeat(12, minmax(0, 1fr))"
        sx={{
          "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
        }}
      >
        <Box sx={{ gridColumn: "span 3" }}>
          <Box sx={{ p: 2 }} className="options">
            <Box sx={{ p: 1 }}>
              <Typography variant="h6">Аж ахуй нэгж сонгох</Typography>
              <DropDownBox
                value={companyId}
                opened={isGridCompNameBoxOpened}
                valueExpr="id"
                deferRendering={true}
                displayExpr={gridBoxDisplayExpr}
                placeholder="Аж ахуй нэгж сонгох..."
                showClearButton={true}
                dataSource={getCompanyList}
                onValueChanged={
                  (e) => {
                    //  props.getFilters("company_id", e.value);
                    //  console.log(e.value);
                    setCompanyId(e.value);
                    if (e.value !== null) {
                      //  console.log(companyId);
                      const data = { company_id: e.value };
                      dispatch(getEmployeTreeList(data));
                      setFilters("company_id", e.value);
                    } else {
                      if (logedUser !== null) {
                        const data = {
                          company_id: logedUser.employee[0].company_id,
                        };

                        dispatch(getEmployeTreeList(data));
                        setFilters(
                          "company_id",
                          logedUser.employee[0].company_id
                        );
                      }

                      //  const data = { company_id: 1 };

                      setCompanyName(logedUser.employee[0].company_name);
                    }
                  }
                  // console.log(e.value);
                }
                onOptionChanged={(e) => {
                  // console.log(e.value);
                  if (e.name === "opened") {
                    setIsGridCompNameBoxOpened(e.value);
                    // props.filterCompany(null);
                  }
                }}
                contentRender={dataCompNameGridRender}
              />
            </Box>
            <Box sx={{ pl: 1 }}>
              <Typography variant="h6">
                {companyName === ""
                  ? logedUser.employee[0].company_name
                  : companyName}
              </Typography>
              <TreeView
                id="treeview"
                ref={gridRef}
                //width={340}
                //height={320}
                itemsExpr={"child"}
                //parentIdExpr={"child.id"}
                items={employes}
                //items={dataaa}
                selectNodesRecursive={false}
                selectByClick={true}
                showCheckBoxesMode={"normal"}
                selectionMode={"single"}
                onSelectionChanged={treeViewSelectionChanged}
                onContentReady={treeViewContentReady}
                itemRender={renderTreeViewItem}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ gridColumn: "span 9" }}>
          {/* <List
            id="selected-employees"
            width={400}
            height={200}
            showScrollbar="always"
            items={selectedEmployees}
            itemRender={renderListItem}
          /> */}
          <EmployeList
            userList={getUserList}
            branchData={selectedEmployees}
            companyData={companyId}
            // comptData={compName}
            // isCompanyFilter={isCompanyFilter}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Employe;
