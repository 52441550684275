import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../Header";
import { SelectBox } from "devextreme-react/select-box";
import { NumberBox } from "devextreme-react/number-box";
import { Lookup, DropDownOptions } from "devextreme-react/lookup";
import { employeesList, employeesTasks } from "./customers";
import DateBox from "devextreme-react/date-box";
import TextBox from "devextreme-react/text-box";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { HorizontalRule, LineAxis } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  FilterRow,
  Item,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import { getCompany } from "../../store/reducers/company";
import { DropDownBox, Toolbar } from "devextreme-react";
import moment from "moment";
import { setFilter } from "../../store/reducers/driver";
const FilterBox = (props) => {
  const gridBoxDisplayExpr = (item) => {
    // console.log(item);
    return item ? `${item.name}` : "";
  };
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(0, "days").format("YYYY-M-D")
  );
  const logedUser = useSelector((state) => state.auth.user);
  const [compNameGridBoxValue, setCompNameGridBoxValue] = useState(0);
  const [isGridCompNameBoxOpened, setIsGridCompNameBoxOpened] = useState(false);
  const getCompanyList = useSelector((state) => state.company.companyData);
  const [formData, setFormData] = useState([]);
  const gridColumns = ["name"];
  const filter = useSelector((state) => state.company.filter);
  const dispatch = useDispatch();
  useEffect(() => {
    if (getCompanyList == null) {
      dispatch(getCompany(filter));
    } else {
      setFormData(getCompanyList);
    }
  }, [getCompanyList, dispatch]);
  const dateFilterFun = (value, type) => {
    if (type == 1) {
      console.log(value);

      dispatch(
        setFilter({
          ...filter,
          start_date: value,
          end_date: endDate,
        })
      );
    } else {
      dispatch(
        setFilter({
          ...filter,
          start_date: startDate,
          end_date: value,
        })
      );
    }
  };

  const dataCompNameGridRender = () => {
    return (
      <DataGrid
        dataSource={formData}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={compNameGridBoxValue}
        onSelectionChanged={(e) => {
          props.filterCompany(e.selectedRowsData);
          const selValue = e.selectedRowKeys.map((ex) => {
            return ex.id;
          });
          setCompNameGridBoxValue(parseInt(selValue[0]));
          //  console.log(selValue[0]);
          setIsGridCompNameBoxOpened(false);
        }}
        height="100%"
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    );
  };
  return (
    <Toolbar>
      <Item location="before">
        <Typography variant="h4" mb={1} ml={2}>
          Шүүлт
        </Typography>
        <Stack
          direction={"row"}
          spacing={1}
          sx={{ ml: 2 }}
          justifyContent={"space-between"}
        >
          {/* <DateBox
            defaultValue={startDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              // console.log(e.value);
              //   props.getFilters("start_date", e.value);
              setStartDate(e.value);
              dateFilterFun(e.value, 1);
            }}
            type="date"
          />
          <HorizontalRule sx={{ fontSize: "16px", mt: 2 }} />
          <DateBox
            defaultValue={endDate}
            displayFormat="yyyy-M-dd"
            onValueChanged={(e) => {
              //console.log(e.value);
              // props.getFilters("end_date", e.value);
              setEndDate(e.value);
              dateFilterFun(e.value, 2);
            }}
            type="date"
          /> */}

          <DropDownBox
            display={logedUser.role !== "user" ? "display" : "none"}
            value={compNameGridBoxValue}
            width={"200px"}
            opened={isGridCompNameBoxOpened}
            valueExpr="id"
            deferRendering={true}
            displayExpr={gridBoxDisplayExpr}
            placeholder=" ААНБ-н нэр сонгох"
            showClearButton={true}
            dataSource={formData}
            onValueChanged={(e) => {
              setCompNameGridBoxValue(e.value);
              props.getFilters("company_id", e.value);
              if (e.value === null) {
                props.filterCompany(null);
              }
              // console.log(e.value);
            }}
            onOptionChanged={(e) => {
              // console.log(e.name);
              if (e.name === "opened") {
                setIsGridCompNameBoxOpened(e.value);
                // props.filterCompany(null);
              }
            }}
            contentRender={dataCompNameGridRender}
          />

          <TextBox
            // ref={this.CarTextRef}
            placeholder="Хайлт...."
            showClearButton={true}
            onValueChanged={(e) => {
              // console.log([...e.value].length);
              if ([...e.value].length > 0) {
                props.getFilters("search_value", e.value);
              } else {
                props.getFilters("search_value", null);
              }
            }}
            onKeyUp={(e) => {
              if (e.event.keyCode === 13) {
                e.event.preventDefault();
                //  console.log(e.event.target.value);
              }
              // console.log(e.event.target.value);
            }}
          />
        </Stack>
      </Item>
    </Toolbar>
  );
};

export default FilterBox;
