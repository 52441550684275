import { Box } from "@mui/material";
import Header from "../../../../components/Header";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import themes from "devextreme/ui/themes";
import { useDispatch, useSelector } from "react-redux";

import {
  getRequest,
  setFilter,
} from "../../../../store/reducers/requestVehicle";
import RequestInData from "../../../../components/request/company/vehicle/requestInData";
import RequestConfirmedData from "../../../../components/request/company/vehicle/requestConfirmed";

const RequestCompanyVehicle = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box m="20px">
      <Header
        title="Тээврийн хэрэгсэл баталгаажуулах хүсэлтүүд"
        subtitle="Тээврийн хэрэгслийн баталгаажуулах хүсэлтүүд "
      />
      <Header
        // title="Тээврийн хэрэгсэл баталгаажуулах хүсэлтүүд"
        subtitle="1. Хүсэлт илгээх боломжтой"
      />
      <Stack>
        <Box>
          <RequestInData />
        </Box>
        <Box height={12}></Box>
        <Header
          // title="Тээврийн хэрэгсэл баталгаажуулах хүсэлтүүд"
          subtitle="2. Хүсэлт илгээсэн"
        />
        <Box>
          <RequestConfirmedData />
        </Box>
      </Stack>
    </Box>
  );
};

export default RequestCompanyVehicle;
