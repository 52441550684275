import { Box } from "@mui/material";
import Header from "../../../../components/Header";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

import RequestInData from "../../../../components/request/admin/driver/requestInData";
import RequestConfirmedData from "../../../../components/request/admin/driver/requestConfirmed";

const RequestDriver = () => {
  return (
    <Box m="20px">
      <Header
        title="Жолооч"
        subtitle="Жолооч баталгаажуулахаар ирүүлсэн хүсэлтүүд"
      />
      <Stack>
        <Box>
          <RequestInData />
        </Box>
        <Box height={12}></Box>
        <Header subtitle="Баталгаажсан хүсэлтүүд" />
        <Box>
          <RequestConfirmedData />
        </Box>
      </Stack>
    </Box>
  );
};

export default RequestDriver;
