import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

// Adjust the import based on your file structure

const DashboardTable = (props) => {
  // Sample data with an amount column
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props.dashboardData != null) {
      setData(props.dashboardData.table);
    }
  }, [props]);
  //   const data = [
  //     {
  //       id: 1,
  //       name: "John Doe",
  //       email: "john@example.com",
  //       role: "Admin",
  //       amount: 100,
  //     },
  //     {
  //       id: 2,
  //       name: "Jane Smith",
  //       email: "jane@example.com",
  //       role: "User",
  //       amount: 200,
  //     },
  //     {
  //       id: 3,
  //       name: "Alice Johnson",
  //       email: "alice@example.com",
  //       role: "Moderator",
  //       amount: 150,
  //     },
  //   ];

  // Calculate total amount
  const totalAmountComp = data.reduce(
    (total, row) => total + parseInt(row.total_company),
    0
  );
  const totalAmountDriver = data.reduce(
    (total, row) => total + parseInt(row.total_driver),
    0
  );
  const totalAmountVehicle = data.reduce(
    (total, row) => total + parseInt(row.total_vehicle),
    0
  );
  const totalAmountEmployees = data.reduce(
    (total, row) => total + parseInt(row.total_employees),
    0
  );
  const totalAmountGrievances = data.reduce(
    (total, row) => total + parseInt(row.total_grievances),
    0
  );
  const totalAmountViolation = data.reduce(
    (total, row) => total + parseInt(row.total_violations),
    0
  );

  return (
    <Paper style={{ padding: "16px", marginTop: "20px", background: "none" }}>
      {/* <Typography variant="h6" gutterBottom>
        Статистик мэдээлэл дэлгэрэнгүй
      </Typography> */}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell>ҮА чиглэл</TableCell>
              <TableCell align="right">ААНБ тоо</TableCell>
              <TableCell align="right">Жолоочийн тоо</TableCell>
              <TableCell align="right">ТХ-н тоо</TableCell>
              <TableCell align="right">Ажилчдын тоо</TableCell>
              <TableCell align="right">Санал хүсэлтийн тоо</TableCell>
              <TableCell align="right">Зөрчил тоо</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="left">{row.id}</TableCell>
                <TableCell align="left">{row.title}</TableCell>
                <TableCell align="right">{row.total_company}</TableCell>
                <TableCell align="right">{row.total_driver}</TableCell>
                <TableCell align="right">{row.total_vehicle}</TableCell>
                <TableCell align="right">{row.total_employees}</TableCell>
                <TableCell align="right">{row.total_grievances}</TableCell>
                <TableCell align="right">{row.total_violations}</TableCell>
              </TableRow>
            ))}
            {/* Total Row */}
            <TableRow>
              <TableCell colSpan={2} align="right">
                <strong>Нийт:</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totalAmountComp}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totalAmountDriver}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totalAmountVehicle}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totalAmountEmployees}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totalAmountGrievances}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{totalAmountViolation}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DashboardTable;
