import { Box, useTheme, Button as Btn, Typography, Stack } from "@mui/material";

import { tokens } from "../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  Popup,
  LoadPanel,
  Texts,
  Editing,
  Lookup,
  RequiredRule,
  CustomRule,
} from "devextreme-react/data-grid";
import { Lookup as Lookups, DropDownOptions } from "devextreme-react/lookup";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect, useCallback } from "react";

import "./style.css";

import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGridConfig } from "../../config";

import { useDispatch, useSelector } from "react-redux";

import notify from "devextreme/ui/notify";
import EmployeDetial from "./employeDetial";
import {
  createEmployee,
  deleteEmployee,
  deleteUser,
  getEmployeeInfoData,
  getUserJobposition,
  getUserListData,
  getUserLocation,
  getUserLocationDist,
  getUserLocationHoroo,
  getUserProfileData,
  getUserSexData,
  getUserSocietyData,
  getUserUserNationalityData,
  updateEmployee,
} from "../../store/reducers/user/auth";
import EmployeeAdd from "./employeeAdd";
import BaseHelper from "../../helper/BaseHelper";
import { getUserTypeList } from "../../store/reducers/metaCrud";
//import { DateBox, TextBox } from "devextreme-react";
import { getCompany } from "../../store/reducers/company";
import { getEmployeTreeList } from "../../store/reducers/employe";
import { useNavigate } from "react-router-dom";
const EmployeList = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [displayMode, setDisplayMode] = useState("full");
  const [showPageSizeSelector, setShowPageSizeSelector] = useState(true);
  const [showInfo, setShowInfo] = useState("full");
  const [showNavButtons, setShowNavButtons] = useState("full");
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const filter = useSelector((state) => state.auth.filter);
  const [formData, setFormData] = useState([]);
  const [testVal, setTestVal] = useState("test");
  const [formEditData, setFormEditData] = useState("");
  const logedUser = useSelector((state) => state.auth.user);
  const [isConfirm, setIsConfirm] = useState(false);
  const getUserListDataa = useSelector((state) => state.auth.userListData);
  const getUserTypeListData = useSelector(
    (state) => state.metaCrud.userTypeListData
  );
  // console.log(props.branchData[0].company_id);
  const getUserLocationData = useSelector(
    (state) => state.auth.userLocationData
  );
  const getUserLocationDistData = useSelector(
    (state) => state.auth.userLocationDistData
  );
  const getUserLocationHorooData = useSelector(
    (state) => state.auth.userLocationHorooData
  );
  const getUserNationalityData = useSelector(
    (state) => state.auth.userNationalityData
  );
  const getUserSocietyDataList = useSelector(
    (state) => state.auth.userSocietyData
  );
  const getUserJobpositionData = useSelector(
    (state) => state.auth.userJobposition
  );
  const getUserSexDataList = useSelector((state) => state.auth.userSexData);
  const getCompanyDatatData = useSelector((state) => state.company.companyData);
  const getEmployeTreeListData = useSelector(
    (state) => state.employe.employeTreeListData
  );
  const filterComp = useSelector((state) => state.company.filter);
  const [password, setPassword] = useState("");
  const [repassword, setRepassword] = useState("");
  const [birth_date, setBirth_date] = useState("");

  const [aimag_city_id, setAimag_city_id] = useState(0);
  const [soum_district_id, setSoum_district_id] = useState(0);
  const [bag_khoroo_id, setBag_khoroo_id] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [branchId, setBranchId] = useState(0);
  const [userLocationData, setUserLocationData] = useState(null);
  const [userLocationDist, setUserLocationDist] = useState(null);
  const [userLocationHorooDataa, setUserLocationHorooDataa] = useState(null);
  const [companydata, setCompanydata] = useState(null);
  const [employeTreeListData, setEmployeTreeListData] = useState(null);
  const [insert, setInsert] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(props);
  //console.log(branchId);
  // console.log(filter.branch_id);
  useEffect(() => {
    if (typeof props.companyData !== "undefined") {
      setCompanyId(props.companyData);
    }
  }, [companyId, props, setCompanyId]);
  useEffect(() => {
    if (typeof props.branchData[0] !== "undefined") {
      setBranchId(props.branchData[0].id);
    }
  }, [branchId, props, setBranchId]);
  // console.log(props);
  // console.log(companyId);
  useEffect(() => {
    if (getUserTypeListData === null) {
      dispatch(getUserTypeList());
    }
  }, [getUserTypeListData, dispatch]);
  const gridRef = useRef();
  useEffect(() => {
    if (getUserLocationData === null) {
      dispatch(getUserLocation());
    }
  }, [getUserLocationData, dispatch]);
  useEffect(() => {
    if (getUserLocationData !== null && userLocationData === null) {
      setUserLocationData(getUserLocationData);
    }
  }, [getUserLocationData, userLocationData, setUserLocationData]);
  useEffect(() => {
    if (getUserJobpositionData === null) {
      dispatch(getUserJobposition());
    }
  }, [getUserJobpositionData, dispatch]);
  useEffect(() => {
    if (getUserLocationDistData === null) {
      dispatch(getUserLocationDist());
    } else {
      // setUserLocationDist(getUserLocationDistData);
    }
  }, [getUserLocationDistData, dispatch]);
  useEffect(() => {
    if (getUserLocationDistData !== null && userLocationDist === null) {
      setUserLocationDist(getUserLocationDistData);
    }
  }, [getUserLocationDistData, userLocationDist, setUserLocationDist]);
  //console.log(getUserLocationHorooData);
  useEffect(() => {
    if (getUserLocationHorooData === null) {
      dispatch(getUserLocationHoroo());
      setUserLocationHorooDataa(getUserLocationHorooData);
    } else {
      //setUserLocationHorooDataa(getUserLocationHorooData);
    }
  }, [getUserLocationHorooData, dispatch]);
  useEffect(() => {
    if (getUserLocationHorooData !== null && userLocationHorooDataa === null) {
      setUserLocationHorooDataa(getUserLocationHorooData);
    }
  }, [
    getUserLocationHorooData,
    userLocationHorooDataa,
    setUserLocationHorooDataa,
  ]);

  useEffect(() => {
    if (getUserNationalityData === null) {
      dispatch(getUserUserNationalityData());
    }
  }, [getUserNationalityData, dispatch]);
  useEffect(() => {
    if (getUserSocietyDataList === null) {
      dispatch(getUserSocietyData());
    }
  }, [getUserSocietyDataList, dispatch]);
  useEffect(() => {
    if (getUserSexDataList === null) {
      dispatch(getUserSexData());
    }
  }, [getUserSexDataList, dispatch]);
  useEffect(() => {
    if (getCompanyDatatData === null) {
      dispatch(getCompany(filterComp));
    }
  }, [filterComp, getCompanyDatatData, dispatch]);
  useEffect(() => {
    if (getCompanyDatatData !== null && companydata === null) {
      setCompanydata(getCompanyDatatData);
    }
  }, [getCompanyDatatData, companydata, setCompanydata]);
  useEffect(() => {
    if (getEmployeTreeListData === null) {
      dispatch(getEmployeTreeList());
    }
  }, [getEmployeTreeListData, dispatch]);
  useEffect(() => {
    if (getEmployeTreeListData !== null && employeTreeListData === null) {
      //  dispatch(getEmployeTreeList());
      setEmployeTreeListData(getEmployeTreeListData);
    }
  }, [getEmployeTreeListData, setEmployeTreeListData, employeTreeListData]);
  // useEffect(() => {
  //   if (getUserLocationHorooData === null) {
  //     dispatch(getUserLocation({}, 3));
  //   }
  // }, [dispatch]);
  const setStoreUser = (data) => {
    // dispatch(editCompany(data));
    // console.log(data);

    // console.log(datt);
    // setFormData([
    //   ...formData,
    //   {
    //     datt,
    //   },
    // ]);

    gridRef.current.instance.refresh();
    setIsPop(false);

    //console.log({ data });
  };
  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // console.log(selectedRowsData);
    //console.log(selectedRowsData[0]);
    setSelectIndex(selectedRowKeys[0]);

    setIsType(2);
    setFormEditData(selectedRowsData[0]);
  };
  const hideInfo = () => {
    setIsPop(false);

    // setFormEditData("");
  };

  //   const onSaving = (e) => {
  //     console.log(e.changes);
  //     //gridRef.current.instance.saveEditData();
  //   };
  function deleteRecords() {
    const getSelectedData = gridRef.current.instance.getSelectedRowsData();

    //  console.log(getSelectedData);
    for (let i = 0; i < getSelectedData.length; i++) {
      //console.log(getSelectedData[i].id);

      dispatch(deleteUser({ id: getSelectedData[i].id }));
    }
    setIsConfirm(false);
  }

  const editUserFun = (data) => {
    // dispatch(editUser(data));
    // console.log(data);

    gridRef.current.instance.refresh();
    setIsPop(false);
    // window.location = "/employe";
  };
  const onSaving = React.useCallback(
    (e) => {
      //console.log(e);
      //e.cancel = true;
      // console.log(e.changes[0].type);
      // console.log("first");
      const selectedData = gridRef.current.instance.getSelectedRowsData();
      if (e.changes[0] !== undefined) {
        if (e.changes[0].type === "insert") {
          // console.log(e.changes);
          //  console.log(driverSearchData.id);
          const data = e.changes[0].data;
          data["aimag_city_id"] = aimag_city_id;
          data["soum_district_id"] = soum_district_id;
          data["bag_khoroo_id"] = bag_khoroo_id;
          data["company_id"] = companyId;
          data["branch_id"] = branchId;
          data["email"] = data["email[0]"]["email"];
          data["phone_number"] = data["phone_number[0]"]["phone_number"];
          //console.log(data);

          if (data.password === data.repassword) {
            dispatch(createEmployee(data)).then((res) => {
              if (res) {
                e.cancel = true;
                gridRef.current.instance.cancelEditData();
              }
            });
            //  console.log(data);
          } else {
            notify("Нууц үг таарахгүй байна.");
          }

          //const values = e.changes[0].data;
          // console.log(dd);
          // const data = {
          //   //    id: driverSearchData.id,
          //   is_basic: 1,
          //   company_id: props.compData !== null ? props.comptData.id : "",
          //   phone_number: values.phone_number,
          // };

          //  dispatch(createDriver(data));
          // gridRef.current.instance.cancelEditData();
          e.cancel = true;

          // console.log(data);
        } else if (e.changes[0].type === "update") {
          const data = e.changes[0].data;
          //console.log(data["email[0]"]);
          data["id"] = e.changes[0].key;
          data["aimag_city_id"] = aimag_city_id;
          data["soum_district_id"] = soum_district_id;
          data["bag_khoroo_id"] = bag_khoroo_id;
          data["company_id"] = companyId;
          data["branch_id"] = branchId;
          if (typeof data["email[0]"] !== "undefined") {
            data["email"] = data["email[0]"]["email"];
          }
          if (typeof data["phone_number[0]"] !== "undefined") {
            data["phone_number"] = data["phone_number[0]"]["phone_number"];
          }

          // console.log(data);
          // console.log(data["email[0]"]);
          if (typeof data.password !== "undefined") {
            data["is_new_password"] = 1;
            if (data.password === data.repassword) {
              //   console.log(data);
              dispatch(updateEmployee(data)).then((res) => {
                if (res) {
                  // dispatch(getUserListData(filter));
                  // gridRef.current.instance.refresh();
                  e.cancel = true;
                  gridRef.current.instance.cancelEditData();
                }
              });
              ///  updateEmployee
            } else {
              notify("Нууц үг таарахгүй байна.", "error");
            }
          } else {
            //  console.log(data);
            dispatch(updateEmployee(data)).then((res) => {
              // console.log(res);
              // navigate(0);
              e.cancel = true;
              gridRef.current.instance.cancelEditData();
            });
          }

          // data["legal_provision_id"] = 1;
          // data["is_active"] = 1;
          // if ("code" in data === false) {
          //   data["code"] = selectedData[0].code;
          // }
          // if ("rank" in data === false) {
          //   data["rank"] = selectedData[0].rank;
          // }
          // if ("name" in data === false) {
          //   data["name"] = selectedData[0].name;
          // }
          //console.log(data);
          // dispatch(createDriver(data));
          //  gridRef.current.instance.cancelEditData();

          e.cancel = true;
          //   console.log(e);
        } else if (e.changes[0].type === "remove") {
          const data = { id: e.changes[0].key };
          // console.log(data);
          dispatch(deleteEmployee(data, companyId));
          // dispatch(getUserListData(filter));
          // gridRef.current.instance.refresh();
          e.cancel = true;
          gridRef.current.instance.cancelEditData();
        }
      }

      // e.component.cancelEditData();
    },
    [
      aimag_city_id,
      soum_district_id,
      companyId,
      branchId,
      bag_khoroo_id,

      dispatch,
    ]
  );
  function avatarRender() {
    return (
      <img
        style={{ marginLeft: "20px", width: "120px", height: "140px" }}
        src={"/assets/profile.png"}
        alt=""
      />
    );
  }
  function reload() {
    navigate(0);
  }
  const test = (data) => {
    console.log(data);
  };
  function setStateValue(rowData, value) {
    rowData.CityID = null;
    this.defaultSetCellValue(rowData, value);
    dispatch(
      getEmployeTreeList({
        company_id: value,
      })
    );
    setCompanyId(value);

    // console.log(rowData);
  }
  // const confirmPassword = useCallback(
  //   ({ value }) => value === gridRef.current.password,

  //   []
  // );
  const denyAdminLogin = (e) => {
    console.log(e);
    // if(e.isValid && e.newData.Login === "Administrator") {
    //     e.isValid = false;
    //     e.errorText = "You cannot log in as Administrator";
    // }
  };
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar>
        <Item location="after">
          <Typography variant="h5">
            {props.branchData.length > 0 ? props.branchData[0].name : ""}
          </Typography>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(getUserListData(filter));
                gridRef.current.instance.refresh();

                // setIsPopUpEdit(true);
                // console.log(isPopUpEdit);
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(1, 0, "is_create", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"add"}
              onClick={() => {
                // console.log(props.isCompanyFilter);
                // console.log(branchId);
                if (branchId > 0) {
                  gridRef.current.instance.addRow();
                  setInsert(true);
                } else {
                  notify(
                    {
                      message: "Хэлтэс сонгон уу!!!",
                      width: 450,
                    },
                    "error"
                  );
                  setInsert(false);
                }
              }}
              type={"default"}
              text="Шинэ"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(1, 0, "is_update", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"edit"}
              onClick={() => {
                const getSelectedData =
                  gridRef.current.instance.getSelectedRowsData();
                // console.log(getSelectedData.length);
                if (selectIndex > -1) {
                  // console.log(selectIndex);
                  setInsert(false);
                  gridRef.current.instance.editRow(selectIndex);
                } else {
                  notify("Засах мэдээлэлээ сонгон уу!!!", "error");
                }

                //gridRef.current.instance.instance.deselectAll();
              }}
              type={"success"}
              text="Засах"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              disabled={
                BaseHelper.permitionCheck(1, 0, "is_delete", logedUser) ===
                false
                  ? true
                  : false
              }
              icon={"remove"}
              onClick={(e) => {
                // console.log(e.row);

                const getSelectedData =
                  gridRef.current.instance.getSelectedRowsData();
                if (getSelectedData.length > 0) {
                  //  console.log(getSelectedData);

                  // console.log(selectIndex);
                  setIsConfirm(true);
                } else {
                  notify(
                    {
                      message: "Устгах хэрэглэгч сонгон уу !!!",
                      width: 450,
                    },
                    "error"
                  );
                }

                //gridRef.current.instance.deleteRow(selectIndex);
                //gridRef.current.instance.deselectAll();
              }}
              type={"danger"}
              text="Устгах"
            />
          </Box>
        </Item>
        <Item location="after">
          <Box>
            {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>
      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        rowAlternationEnabled={true}
        remoteOperations={true}
        dataSource={getUserListDataa}
        keyExpr="id"
        height={420}
        onEditingStart={(e) => {
          // console.log(e);
          setAimag_city_id(e.data.aimag_city_id);
          setSoum_district_id(e.data.soum_district_id);
          setBag_khoroo_id(e.data.bag_khoroo_id);
          setCompanyId(e.data.company_id);
          setBranchId(e.data.branch_id);
          // if (e.parentType === "dataRow" && e.dataField === "repassword") {
          //   // console.log(e.validationRules);
          //   e.validationRules[0].type = "required";
          // } else if (e.parentType === "dataRow" && e.dataField === "password") {
          //   e.validationRules[0].type = "";
          // }
        }}
        onEditCanceled={(e) => {
          setAimag_city_id(0);
          setSoum_district_id(0);
          setBag_khoroo_id(0);
          setCompanyId(0);
          setBranchId(0);
          setInsert(false);
          // if (e.parentType === "dataRow" && e.dataField === "repassword") {
          //   // console.log(e.validationRules);
          //   e.validationRules[0].type = "required";
          // } else if (e.parentType === "dataRow" && e.dataField === "password") {
          //   e.validationRules[0].type = "";
          // }
        }}
        onRowDblClick={(row) => {
          if (
            isType === 2 &&
            formEditData !== "" &&
            BaseHelper.permitionCheck(1, 0, "is_update", logedUser)
          ) {
            setFormEditData(row.data);
            gridRef.current.instance.editRow(row.rowIndex);
            //  console.log(row.data);
            // dispatch(
            //   getUserProfileData({
            //     user_id: row.data.id,
            //   })
            // );
            // var data = {
            //   company_id: row.data.company_id,
            //   user_id: row.data.id,
            // };
            // // console.log(data);
            // dispatch(getEmployeeInfoData(data));
            // setFormEditData(row.data);
            // setIsPop(true);
          } else {
            // alert("Та ");
          }
        }}
        onRowClick={(e) => {
          // console.log(e);
          setSelectIndex(e.loadIndex);
        }}
        onSelectionChanged={onSelectionChanged}
        onSaving={onSaving}
        onEditorPreparing={(e) => {
          //   console.log(e);
          if (e.parentType === "dataRow" && e.dataField === "regnum") {
            e.editorOptions.maxLength = 10;
          } else if (
            e.parentType === "dataRow" &&
            e.dataField === "phone_number[0].phone_number"
          ) {
            e.editorOptions.maxLength = 8;
            // e.editorOptions.mode = "number";
          } else if (e.parentType === "dataRow" && e.dataField === "age") {
            e.editorOptions.maxLength = 2;
            // e.editorOptions.mode = "number";
          }
        }}
        // onCellPrepared={(e) => {
        //   // console.log(e.data ? e.data.violation_date : "");
        //   if (
        //     (e.rowType === "data" && e.column.dataField === "password") ||
        //     e.column.dataField === "repassword"
        //   ) {
        //     //e.cellElement.style.hideInfo = true;
        //     //  e.column. = true;
        //     console.log(e);
        //   }
        // }}
      >
        <Editing
          mode="popup"
          dragEnabled={true}
          hideOnOutsideClick={true}
          showCloseButton={true}
          position={"top"}
          allowUpdating={false}
          allowDeleting={BaseHelper.permitionCheck(
            1,
            0,
            "is_delete",
            logedUser
          )}
          useIcons={true}
          //  changes={changes}
          //  onChangesChange={setChanges}
        >
          <Popup
            title="Ажилтны мэдээлэл"
            showTitle={true}
            // width={"70%"}
            // height={"60%"}
          />
          <Texts
            confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
            confirmDeleteTitle={"Мэдээлэл устгах"}
            saveRowChanges={"Хадгалах"}
            saveRowToNode={"ffff"}
            cancelRowChanges={"Цуцлах"}
            deleteRow="Устгах"
            addRow="Нэмэх"
            editRow="Засах"
            addRowToNode="Нэмэх"
          />
          <Form>
            <Item
              itemType="group"
              colCount={2}
              colSpan={2}
              caption="Ерөнхий мэдээлэл"
            >
              {/* <Item render={avatarRender} colSpan={1} /> */}
              <Item dataField={"lastname"} />
              <Item dataField={"firstname"} />
              <Item dataField={"regnum"} />
              <Item dataField={"phone_number[0].phone_number"} />
              <Item dataField={"email[0].email"} />
              <Item dataField={"user_type_id"} />

              <Item dataField={"password"} />
              <Item dataField={"age"} />
              <Item dataField={"repassword"} />

              {/* <Item
                visible={logedUser.role === "admin" ? true : false}
                dataField="company_id"
              /> */}
            </Item>
            <Item
              itemType="group"
              caption="Профайл мэдээлэл"
              colCount={2}
              colSpan={2}
            >
              {/* <Item dataField={"picture_url"} /> */}

              {/* <Item render={avatarRender} colSpan={0} /> */}

              <Item dataField={"birth_date"} />

              <Item dataField={"aimag_city_id"} />
              <Item dataField={"sex_id"} />
              <Item dataField={"soum_district_id"} />
              <Item dataField={"nationality_id"} />
              <Item dataField={"bag_khoroo_id"} />
              <Item dataField={"is_solder"} />
              <Item dataField={"address_detail"} />
              <Item dataField={"society_id"} />
            </Item>
            <Item
              itemType="group"
              caption="Албан тушаалын мэдээлэл"
              colCount={2}
              colSpan={2}
            >
              <Item dataField={"company_id"} />
              <Item dataField={"employee_code"} />
              <Item dataField={"branch_id"} />

              <Item dataField={"job_title"} />
              <Item dataField={"job_position_id"} />
            </Item>
          </Form>
        </Editing>
        <Selection mode="multiple" />

        <ColumnChooser enabled={false} />
        <ColumnFixing enabled={true} />

        <Column
          allowSorting={true}
          fixed={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
        />

        <Column caption="Байгуулллага" fixed={true}>
          {/* <Column
            dataField="register"
            width={130}
            format="fixedPoint"
            caption={"ААНБ-н регистер"}
          /> */}
          {/* <Column
            dataField="company_name"
            format="fixedPoint"
            caption={"ААНБ-н нэр"}
          /> */}
          <Column
            dataField="company_id"
            //   allowEditing={checkBoxValue ? true : false}companydata
            caption="Байгууллага"
            // editorOptions={{
            //   mode: "selectbox",
            // }}
            //setCellValue={setStateValue}

            editCellRender={(e) => {
              return (
                <Lookups
                  disabled={insert ? true : false}
                  onSelectionChanged={(e) => {
                    // console.log(e.selectedItem);
                    dispatch(
                      getEmployeTreeList({
                        company_id:
                          Object.keys(e.selectedItem).length > 0
                            ? e.selectedItem["id"]
                            : 0,
                      })
                    );
                    setCompanyId(
                      Object.keys(e.selectedItem).length > 0
                        ? e.selectedItem["id"]
                        : 0
                    );
                  }}
                  value={companyId}
                  dataSource={getCompanyDatatData}
                  valueExpr="id"
                  displayExpr="name"
                />
              );
            }}
          >
            {/* <RequiredRule /> */}
            <Lookup
              // onSelectionChanged={(e) => {
              //   console.log(e);
              // }}
              dataSource={companydata}
              valueExpr="id"
              displayExpr="name"
            />
          </Column>
          <Column
            dataField="branch_id"
            //   allowEditing={checkBoxValue ? true : false}
            caption="Хэлтэс"
            editCellRender={(e) => {
              return (
                <Lookups
                  disabled={insert ? true : false}
                  onSelectionChanged={(e) => {
                    // console.log(
                    //    Object.keys(e.selectedItem).length > 0 ? e.selectedItem["id"] : 0
                    // );

                    setBranchId(
                      Object.keys(e.selectedItem).length > 0 ||
                        e.selectedItem !== null ||
                        typeof e.selectedItem !== "undefined"
                        ? e.selectedItem["id"]
                        : filter.branch_id
                    );
                  }}
                  value={branchId}
                  dataSource={getEmployeTreeListData}
                  valueExpr="id"
                  displayExpr="name"
                />
              );
            }}
          >
            {/* <RequiredRule /> */}
            <Lookup
              dataSource={employeTreeListData}
              valueExpr="id"
              displayExpr="name"
            />
          </Column>
        </Column>

        <Column caption="Хувийн мэдэээлэл">
          <Column dataField="lastname" format="fixedPoint" caption={"Овог"}>
            <RequiredRule />
          </Column>
          <Column dataField="firstname" format="fixedPoint" caption={"Нэр"}>
            {" "}
            <RequiredRule />
          </Column>
          <Column
            dataField="regnum"
            maxLength={10}
            format="fixedPoint"
            caption={"Регистер"}
            cellRender={(e) => {
              return e.value !== null ? e.value.substring(0, 2) + "*****" : "";
            }}
          >
            {" "}
            <RequiredRule />
          </Column>
          <Column
            dataField="branch_name"
            format="fixedPoint"
            caption={"Салбарын нэр"}
          />
          <Column
            dataField="job_position_id"
            //   allowEditing={checkBoxValue ? true : false}
            caption="Албан тушаалын төрөл"
          >
            <RequiredRule />
            <Lookup
              dataSource={getUserJobpositionData}
              valueExpr="id"
              displayExpr="name"
            />
          </Column>
          <Column
            dataField="job_title"
            format="fixedPoint"
            caption={"Албан тушаал"}
          >
            {" "}
            <RequiredRule />
          </Column>
          <Column
            dataField="user_type_id"
            //   allowEditing={checkBoxValue ? true : false}
            caption="Хандах эрх"
          >
            <RequiredRule />
            <Lookup
              dataSource={getUserTypeListData}
              valueExpr="id"
              displayExpr="name"
            />
          </Column>

          <Column
            dataField="age"
            maxLength={2}
            format="fixedPoint"
            caption={"Нас"}
          />
          <Column
            dataField="employee_code"
            format="fixedPoint"
            caption={"Ажилтны код"}
          >
            {" "}
            <RequiredRule />
          </Column>
          <Column
            dataField="birth_date"
            format="fixedPoint"
            caption={"Төрсөн огноо"}
            editorOptions={{
              mode: "date",
            }}

            // editCellRender={(e) => {
            //   return (
            //     <DateBox
            //       defaultValue={birth_date === "" ? e.value : birth_date}
            //       displayFormat="yyyy-M-dd"
            //       onValueChanged={(e) => {
            //         // console.log(e.value);
            //         setBirth_date(e.value);
            //       }}
            //       type="date"
            //     />
            //   );
            // }}
          >
            {" "}
            <RequiredRule />
            {/* <DateBox
                  defaultValue={birth_date === "" ? e.value : birth_date}
                  displayFormat="yyyy-M-dd"
                  onValueChanged={(e) => {
                    // console.log(e.value);
                    setBirth_date(e.value);
                  }}
                  type="date"
                /> */}
          </Column>
          <Column
            dataField="phone_number[0].phone_number"
            format="fixedPoint"
            // editorOptions={{
            //   mode: "number",
            // }}
            maxLength={8}
            caption={"Утас"}
          >
            <RequiredRule />
          </Column>
          <Column
            editorOptions={{
              mode: "email",
            }}
            dataField="email[0].email"
            format="fixedPoint"
            caption={"И-мэйл"}
          >
            {" "}
            <RequiredRule />
          </Column>
        </Column>
        <Column
          dataField="aimag_city_id"
          //   allowEditing={checkBoxValue ? true : false}
          caption="Хаяг аймаг/нийслэл"
          editCellRender={(v) => {
            //  console.log(v);
            // console.log(v.row.data.aimag_city_id);

            // if (aimag_city_id == 0) {
            //   setAimag_city_id(v.row.data.aimag_city_id);
            // }

            return (
              <Lookups
                onSelectionChanged={(e) => {
                  //console.log(e.selectedItem["id"]);
                  setAimag_city_id(
                    Object.keys(e.selectedItem).length > 0 ||
                      e.selectedItem !== null
                      ? e.selectedItem["id"]
                      : 0
                  );
                  dispatch(
                    getUserLocationDist({
                      parent_id:
                        Object.keys(e.selectedItem).length > 0
                          ? e.selectedItem["id"]
                          : 0,
                    })
                  );
                }}
                //defaultValue={v.row.data.aimag_city_id}
                value={aimag_city_id}
                dataSource={getUserLocationData}
                valueExpr="id"
                displayExpr="name"
              />
            );
          }}
        >
          <Lookup
            dataSource={userLocationData}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>
        <Column
          dataField="soum_district_id"
          //   allowEditing={checkBoxValue ? true : false}
          caption="Хаяг сум/дүүрэг"
          editCellRender={(e) => {
            return (
              <Lookups
                onSelectionChanged={(e) => {
                  // console.log(e.selectedItem["id"]);
                  dispatch(
                    getUserLocationHoroo({
                      parent_id:
                        Object.keys(e.selectedItem).length > 0
                          ? e.selectedItem["id"]
                          : 0,
                    })
                  );
                  setSoum_district_id(
                    Object.keys(e.selectedItem).length > 0
                      ? e.selectedItem["id"]
                      : 0
                  );
                }}
                value={soum_district_id}
                dataSource={getUserLocationDistData}
                valueExpr="id"
                displayExpr="name"
              />
            );
          }}
        >
          <Lookup
            dataSource={userLocationDist}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>
        <Column
          dataField="bag_khoroo_id"
          //   allowEditing={checkBoxValue ? true : false}
          caption="Хаяг баг/хороо"
          editCellRender={(e) => {
            return (
              <Lookups
                onSelectionChanged={(e) => {
                  // console.log(e.selectedItem["id"]);
                  // dispatch(
                  //   getUserLocationHoroo({
                  //     parent_id: e.selectedItem["id"],
                  //   })
                  // );
                  setBag_khoroo_id(
                    Object.keys(e.selectedItem).length > 0
                      ? e.selectedItem["id"]
                      : 0
                  );
                }}
                value={bag_khoroo_id}
                dataSource={getUserLocationHorooData}
                valueExpr="id"
                displayExpr="name"
              />
            );
          }}
        >
          <Lookup
            dataSource={userLocationHorooDataa}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>
        <Column
          dataField="nationality_id"
          //   allowEditing={checkBoxValue ? true : false}
          caption="Яс үндэс"
        >
          <RequiredRule />
          <Lookup
            dataSource={getUserNationalityData}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>
        <Column
          dataField="society_id"
          //   allowEditing={checkBoxValue ? true : false}
          caption="Нийгмийн гарал"
        >
          <RequiredRule />
          <Lookup
            dataSource={getUserSocietyDataList}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>

        <Column
          dataField="sex_id"
          //   allowEditing={checkBoxValue ? true : false}
          caption="Хүйс"
        >
          <RequiredRule />
          <Lookup
            dataSource={getUserSexDataList}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>

        <Column dataField={"address_detail"} caption="Хаяг дэлгэрэнгүй" />

        <Column
          dataField={"is_solder"}
          caption="Цэргийн алба хаасан эсэх"
          editorOptions={{
            mode: "boolen",
          }}
          cellRender={(e) => {
            return e.value + "₮";
          }}
        >
          <Lookup
            dataSource={[
              { id: 0, name: "Үгүй" },
              { id: 1, name: "Тийм" },
            ]}
            valueExpr="id"
            displayExpr="name"
          />
        </Column>

        <Column
          dataField="password"
          format="fixedPoint"
          caption={"Нууц үг"}
          visible={false}
          editorOptions={{
            mode: "password",
            stylingMode: "filled",
            placeholder: "Нууц үг",
          }}
        />
        {/* <RequiredRule />
        </Column> */}
        <Column
          dataField="repassword"
          format="fixedPoint"
          caption={"Нууц үг давтан оруулах"}
          visible={false}
          editorOptions={{
            mode: "password",
            stylingMode: "filled",
            placeholder: "Нууц үг давтан оруулах",
          }}
        />
        {/* <RequiredRule /> */}
        {/* <CustomRule
            message={"Passwords do not match"}
              validationCallback={confirmPassword}
          /> */}
        {/* </Column> */}
        <Column
          dataField="created_at"
          format="yyyy-MM-dd HH:mm"
          dataType="date"
          caption={"Бүртгэсэн огноо"}
        />

        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />

        <LoadPanel enabled={true} />
      </DataGrid>
    </>
  );
};

export default EmployeList;
