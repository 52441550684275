import {
  Box,
  useTheme,
  Button as Btn,
  Chip,
  Stack,
  Typography,
} from "@mui/material";

import { tokens } from "../../theme";

//import DataGrid from "devextreme-react/data-grid";

import DataGrid, {
  Scrolling,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  ColumnFixing,
  ColumnChooser,
  Form,
  //LoadPanel,
  Texts,
  Popup,
  Editing,
  Position,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { ToolbarItem } from "devextreme-react/popup";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import "./style.css";

import useMediaQuery from "@mui/material/useMediaQuery";
import VehicleEdit from "./vehicleEdit";
import VehicleAdd from "./vehicleAdd";
import {
  createVehicle,
  getVehicle,
  getVehicleInspectionData,
  getVehicleSearch,
  getVehicleTypeListFun,
  removeVehicleList,
  setVehicleSearchData,
} from "../../store/reducers/vehicle";

import { DataGridConfig } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { Cancel, Check, Search } from "@mui/icons-material";
import notify from "devextreme/ui/notify";
import { getCompany } from "../../store/reducers/company";
import { getDriver, setFilter } from "../../store/reducers/driver";
import { LoadIndicator } from "devextreme-react/load-indicator";
const gridBoxDisplayExpr = (item) => {
  // console.log(item);
  return item
    ? `${item.lastname !== null ? item.lastname.substring(0, 1) : ""}.${
        item.firstname
      }`
    : "";
};
const VehicleList = (props) => {
  const theme = useTheme();
  const vehicleSearchData = useSelector(
    (state) => state.vehicle.vehicleSearchData
  );
  const getVehicleTypeListData = useSelector(
    (state) => state.vehicle.vehicleTypeListData
  );
  const getVehicleInspection = useSelector(
    (state) => state.vehicle.vehicleInspectionData
  );
  const getIsLoading = useSelector((state) => state.vehicle.isLoading);

  const driverDataList = useSelector((state) => state.driver.driverData);
  const logedUser = useSelector((state) => state.auth.user);
  const getCompanyList = useSelector((state) => state.company.companyData);
  const filterComp = useSelector((state) => state.company.filter);
  const filterDriver = useSelector((state) => state.driver.filter);
  const colors = tokens(theme.palette.mode);
  const [displayMode, setDisplayMode] = useState("full");
  const filter = useSelector((state) => state.vehicle.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");

  const [isConfirm, setIsConfirm] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [formData, setFormData] = useState([]);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [formEditData, setFormEditData] = useState("");
  const [editType, setEditType] = useState(false);
  const [isRequired, setisRequired] = useState(false);

  const [plateValue, setPlateValue] = useState("");
  const dispatch = useDispatch();
  const setFilters = (field, value) => {
    // console.log(field, value);
    dispatch(setFilter({ ...filterDriver, [field]: value }));
  };
  // console.log(props);
  function getDisplayExpr(item) {
    return item ? `${item.Lastname} ${item.firstname}` : "";
  }
  // console.log(getVehicleTypeListData);
  // useEffect(() => {
  //   if (driverDataList === null) {
  //     //  if (Object.keys(filter).length > 0) {
  //     dispatch(getDriver(filterDriver));
  //   } else {
  //     //  dispatch(getDriver(filterDriver));
  //   }
  // }, [filterDriver, driverDataList, dispatch]);
  // useEffect(() => {
  //   if (driverDataList == null) {
  //     dispatch(getDriver(filterDriver));
  //   }
  // }, [driverDataList, filterDriver, dispatch]);
  // console.log(getIsLoading);

  useEffect(() => {
    // gridRef.current.instance.beginCustomLoading();
    if (props.vehicleListData) {
      setFormData(props.vehicleListData);
      //   gridRef.current.instance.endCustomLoading();
    }
  }, [props]);
  // useEffect(() => {
  //   if (getCompanyList == null) {
  //     dispatch(getCompany(filterComp));
  //   }
  // }, [filterComp, getCompanyList, dispatch]);
  //console.log(getCompanyList);
  useEffect(() => {
    if (getVehicleTypeListData == null) {
      dispatch(getVehicleTypeListFun());
    }
  }, [getVehicleTypeListData, dispatch]);
  // useEffect(() => {
  //   if (driverDataList === null) {
  //     // if (Object.keys(filterDriver).length > 0) {
  //     dispatch(getDriver(filterDriver));
  //     //  }
  //   }
  // }, [driverDataList, filterDriver, dispatch]);

  useEffect(() => {
    if (driverDataList == null) {
      dispatch(getDriver(filterDriver));
    }
  }, [driverDataList, filterDriver, dispatch]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filterDriver).length > 0) {
      dispatch(getDriver(filterDriver));
    }
  }, [filterDriver, dispatch]);
  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (props.compId > 0) {
      setFormEditData(props.compId);
    }
  }, [props, dispatch]);
  // console.log(logedUser.employee[0].company_id);
  // useEffect(() => {
  //   // console.log(editType);
  //   if (editType) {
  //     setFilters("company_id", formEditData);
  //     if (Object.keys(filterDriver).length > 0) {
  //       dispatch(getDriver(filterDriver));
  //     }
  //   }
  //   console.log(driverDataList);
  // }, [
  //   editType,
  //   formEditData,
  //   filterDriver,
  //   driverDataList,
  //   dispatch,
  //   setFilters,
  // ]);
  const onSelectionChanged = ({ selectedRowsData }) => {
    const data = selectedRowsData[0];
    //  console.log(selectedRowsData[0]);
    // console.log(selectedRowsData[0]);
    // setFilters(
    //   "company_id",
    //   selectedRowsData.length > 0 ? selectedRowsData[0].company_id : null
    // );

    if (selectedRowsData.length > 0) {
      setFormEditData(data.company_id);
    }

    // setSelectIndex(selectedRowKeys[0]);
  };
  const searchVeh = useCallback(
    (e) => {
      if (e.event.type === "dxclick") {
        dispatch(getVehicleSearch(plateValue));
        dispatch(getVehicleInspectionData(plateValue));
      }
    },
    [plateValue, dispatch]
  );
  //console.log(filterDriver);
  const gridRef = useRef();

  // const cellEditRenderFun = (e) => {
  //   setPlateValue(e.data.plate_no);
  // };
  // console.log(vehicleSearchData);
  const closeButtonOptions = {
    text: "Close",
    onClick: () => {},
  };
  const searchButton = {
    icon: "/assets/eye.png",
    //stylingMode: "outlined",

    //icon: "fa fa-envelope-o",
    type: "default",
    disabled: getIsLoading ? true : false,
    onClick: (e) => {
      searchVeh(e);
      // dispatch(getVehicleSearch(plateValue));
      // dispatch(getVehicleInspectionData(plateValue));
    },
  };
  const onValueChange = useCallback((v) => {
    const result = v;

    setPlateValue(result);
    // if (value) {

    // }
    // console.log(v);
  }, []);
  //console.log(props.comptData);
  const onSaving = React.useCallback(
    (e) => {
      // e.cancel = true;

      //  const selectedData = gridRef.current.instance.getSelectedRowsData();
      if (e.changes[0] !== undefined) {
        if (e.changes[0].type === "insert") {
          // console.log(e.changes[0]);
          const data = e.changes[0].data;

          // console.log(props.compId);
          if (Object.keys(data).length > 0) {
            data["id"] = vehicleSearchData.id;
            data["is_basic"] = 1;
            data["company_id"] =
              props.compId !== null || props.compId !== ""
                ? props.compId
                : logedUser.employee[0].company_id;
          }

          dispatch(createVehicle(data, 1));
          //  dispatch(getViolationVehicleEditFun(data));
          // console.log(data);
          gridRef.current.instance.cancelEditData();
          e.cancel = true;
          // e.component.cancelEditData();
          gridRef.current.instance.cancelEditData();
        } else if (e.changes[0].type === "update") {
          const data = e.changes[0].data;
          // console.log(data);
          data["id"] = e.changes[0].key;
          dispatch(createVehicle(data, 2));
          //  dispatch(getViolationVehicleEditFun(data));
          gridRef.current.instance.cancelEditData();

          e.cancel = true;
        } else if (e.changes[0].type === "remove") {
          // console.log(e.changes[0].key);
          const data = { id: e.changes[0].key };
          // console.log(data);
          dispatch(removeVehicleList(data));
          e.cancel = true;
          gridRef.current.instance.cancelEditData();
        }
      }
      // e.component.cancelEditData();
    },
    [vehicleSearchData, logedUser, props, dispatch]
  );

  //console.log(gridRef.current.instance.onValueChange());
  //console.log(gridRef.current.instance.e);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(getVehicle(filter));
                gridRef.current.instance.refresh();
                // console.log(gridRef.current.instance.getSelectedRowsData());
                // setIsPopUpEdit(true);
                // console.log(isPopUpEdit);
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>
        <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              icon={"add"}
              onClick={() => {
                // console.log(props.isCompanyFilter);
                //console.log(gridRef.current.instance);
                if (logedUser.role !== "user") {
                  if (props.isCompanyFilter > 0) {
                    //   console.log(props);
                    // console.log(props.vehicleListData);

                    setFilters("company_id", formEditData);

                    gridRef.current.instance.addRow();
                  } else {
                    notify(
                      {
                        message: "Аж ахуй нэгж сонгон уу!!!",
                        width: 450,
                      },
                      "error"
                    );
                  }
                } else {
                  //    setIsType(1);
                  gridRef.current.instance.addRow();
                }
              }}
              type={"default"}
              text="Шинэ"
            />
          </Box>
        </Item>

        {/* <Item location="before">
          <Box sx={{ ml: 1 }}>
            <Button
              icon={"remove"}
              onClick={(e) => {
                console.log(e.row);
                // console.log(selectIndex);
                // for (let i = 0; i < selectIndex.length; i++) {
                //   gridRef.current.instance.deleteRow(selectIndex[i]);
                // }
                setIsConfirm(true);

                gridRef.current.instance.deleteRow(selectIndex);
                //gridRef.current.instance.deselectAll();
              }}
              type={"danger"}
              text="Устгах"
            />
          </Box>
        </Item> */}
        <Item location="after">
          <Box>
            {/* <Button
                  icon={"export"}
                  onClick={() => {
                    gridRef.current.instance.exportToExcel();
                  }}
                /> */}
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        // showRowLines={true}
        // searchPanel={{ visible: true, placeholder: "Хайлт" }}
        remoteOperations={true}
        dataSource={formData}
        keyExpr="id"
        height={520}
        onEditorPreparing={(e) => {
          // console.log(e);
          if (e.dataField === "service_type_id" && e.parentType === "dataRow") {
            var onValueChanged = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (args) {
              onValueChanged.apply(this, arguments);
              if (args.value === 1) {
                setisRequired(true);
              } else {
                setisRequired(false);
              }
              // your custom code here
            };
            // e.editorOptions.onValueChanged = (e) => {
            //   if (e.value === 1) {
            //     setisRequired(true);
            //   } else {
            //     setisRequired(false);
            //   }
            // };

            // console.log(e.editorOptions.value);
            //  console.log(e.editorOptions.onValueChanged());
          }
        }}
        onEditingStart={(e) => {
          // setIsEdit(true);

          setFilters("company_id", formEditData);

          //  console.log(filterDriver);
          if (e.data.driver1_id === "driver1_id") {
            e.editorOptions.readOnly = true;
          }
          setEditType(true);
          setPlateValue(e.data.plate_no);

          // console.log(editType);
        }}
        // onInitNewRow={(e) => {
        //   console.log(e);
        // }}

        onEditCanceled={(e) => {
          setEditType(false);
          dispatch(setVehicleSearchData(null));
          // dispatch(setFilter({}));
          setPlateValue("");
          gridRef.current.instance.refresh();
        }}
        onRowDblClick={(row) => {
          // setFormEditData(row.data);

          // console.log(filterDriver);
          // setFilters("company_id", row.data.company_id);
          // dispatch(setFilter({ company_id: row.data.company_id }));
          // console.log(row);
          gridRef.current.instance.editRow(row.rowIndex);
          //  dispatch(getDriver(filterDriver));
        }}
        onSelectionChanged={onSelectionChanged}
        onSaving={onSaving}
      >
        <Editing
          mode="popup"
          allowUpdating={true}
          // allowAdding={(e) => {
          //   console.log(e);
          // }}
          allowDeleting={true}
          //  changes={changes}
          useIcons={true}
          //  onChangesChange={onChangesChange}
          // \\\\editRowKey={editRowKey}
          // onEditRowKeyChange={(e) => {
          //   console.log(e);
          // }}
        >
          <Popup
            title="Тээврийн хэрэгсэл"
            showTitle={true}
            // width={"40%"}
            // height={400}
            width={!isNonMobile ? "100%" : "70%"}
            height={!isNonMobile ? "100%" : "80%"}
          />

          {/* <LoadPanel showPane={true} /> */}
          <Texts
            confirmDeleteMessage="Та энэ үйлдлийг хийх үү?"
            confirmDeleteTitle={"Мэдээлэл устгах"}
            saveRowChanges={"Хадгалах"}
            saveRowToNode={"ffff"}
            cancelRowChanges={"Цуцлах"}
            deleteRow="Устгах"
            addRow="Нэмэх"
            editRow="Засах"
            addRowToNode="Нэмэх"
          />

          {/* <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            //   position={position}
            onHiding={false}
            visible={true}
            showIndicator={true}
            shading={true}
            showPane={true}
            hideOnOutsideClick={true}
          /> */}

          <Form>
            <Item itemType="group" colCount={2} colSpan={2}>
              <Item dataField={"plate_no"} />
              <Item dataField={"garage_no"} isRequired={isRequired} />
              <Item dataField={"service_type_id"} />
              <Item dataField={"driver1_id"} />
              <Item dataField={"driver2_id"} />
            </Item>

            <Item
              itemType="group"
              caption="Техникийн үзүүлэлт"
              colCount={2}
              colSpan={2}
            >
              <Item dataField="company_name" />
              <Item dataField="cabin_no" />
              <Item dataField="mark_name" />
              <Item dataField="model_name" />
              <Item dataField="color_name" />

              <Item dataField="seat_count" />
              <Item dataField="build_year" />
              <Item dataField="imported_date" />
              <Item dataField="country_name" />
              <Item dataField="fuel_type_name" />
              <Item dataField="axle_count" />
              <Item dataField="max_load" />
              <Item dataField="total_weight" />
              <Item dataField="length" />
            </Item>
            <Item
              itemType="group"
              caption="Техникийн хяналт"
              colCount={2}
              colSpan={2}
            >
              <Item>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">
                      Оношлогоонд орсон огноо:
                    </Box>
                    <Box className="dx-field-value">
                      <TextBox
                        readOnly
                        value={
                          getVehicleInspection !== null
                            ? getVehicleInspection[0].INSPECTION_DATE.substring(
                                0,
                                10
                              )
                            : ""
                        }
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Item>
              <Item>
                <Box className="dx-fieldset">
                  <Box className="dx-field">
                    <Box className="dx-field-label">
                      Оношлогоо дуусах огноо:
                    </Box>
                    <Box className="dx-field-value">
                      <TextBox
                        readOnly
                        value={
                          getVehicleInspection !== null
                            ? getVehicleInspection[0].EXPIRE_DATE !== null
                              ? getVehicleInspection[0].EXPIRE_DATE.substring(
                                  0,
                                  10
                                )
                              : ""
                            : ""
                        }
                        valueChangeEvent="input"
                      />
                    </Box>
                  </Box>
                </Box>
              </Item>
            </Item>
          </Form>
        </Editing>
        <Selection mode="multiple" />
        <ColumnChooser enabled={true} />
        <ColumnFixing enabled={true} />
        <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex + 1;
          }}
          fixed={true}
          alignment={"center"}
        />
        <Column caption="Байгуулллага" fixed={true} fixedPosition="left">
          {/* <Column dataField="company_regnum" caption={"ААНБ-н регистер"} /> */}
          <Column
            //   editCellRender={(e) => {

            allowEditing={false}
            dataField="company_name"
            caption={"ААНБ-н нэр"}
          />
        </Column>
        <Column
          //   editCellRender={(e) => {

          allowEditing={false}
          dataField="owner_firstname"
          caption={"Өмчлөгчийн нэр"}
        />
        <Column
          dataField="garage_no"
          caption={"Грааш дугаар"}
          disabled={isRequired}
        />

        {/* <Column editRow={false} dataField="smart_bus_id" caption={"ID code"} /> */}
        <Column
          allowSorting={false}
          dataField="is_approved"
          caption="Төлөв"
          cellRender={(p) => {
            // console.log(p);
            if (p.value === 1) {
              return <Chip label="Баталгаажсан" size="small" color="success" />;
            } else if (p.value === 0) {
              return <Chip label="Баталгаажаагүй" size="small" />;
            }
            return <span className="status status-warning">{p.value}</span>;
          }}
        />

        <Column
          dataField="plate_no"
          //editRow={false}
          caption={"Улсын дугаар"}
          editCellRender={(e) => {
            // console.log(e);
            return (
              <TextBox
                location="before"
                value={plateValue}
                onValueChange={onValueChange}
                // onKeyUp={
                //   (e) => {
                //     e.event.preventDefault();
                //     setPlateValue(e.event.target.value);
                //   }

                // console.log(e.event.target.value);
                // dispatch(getVehicleSearch(plateValue));
                //  console.log("ok");
                //  dispatch(getVehicleInspectionData(plateValue));
                //  }

                // console.log(e);
                // setPlateValue(e);
                //  }}
              >
                <TextBoxButton
                  name="password"
                  location="after"
                  options={searchButton}
                >
                  <LoadIndicator className="button-indicator" visible={true} />
                </TextBoxButton>
              </TextBox>
            );
          }}
          // editCellRender={(e) => {
          //   return (
          //     <TextBox
          //
          //       value={
          //         vehicleSearchData
          //           ? vehicleSearchData.plate_no
          //           : e.value
          //       }
          //       valueChangeEvent="input"
          //     />
          //   );
          //   // console.log(e.value);
          // }}
        />
        <Column
          dataField="cabin_no"
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={vehicleSearchData ? vehicleSearchData.cabin_no : e.value}
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
          // editCellRender={cellEditRenderFun}
          caption={"Арлын дугаар"}
        />
        <Column
          dataField="mark_name"
          caption={"Марк"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData ? vehicleSearchData.mark_name : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="model_name"
          caption={"Загвар"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData ? vehicleSearchData.model_name : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="color_name"
          caption={"Өнгө"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData ? vehicleSearchData.color_name : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column dataField="service_type_id" caption={"Тээврийн хэрэгсэл төрөл"}>
          <Lookup
            dataSource={getVehicleTypeListData}
            valueExpr="id"
            displayExpr="name"

            // onValueChanged={(e) => {
            //   console.log(e);
            // }}
            // onValueChanged={onValueChanged}
          />
          <RequiredRule />
        </Column>
        <Column dataField="driver1_id" caption={"Жолооч1"}>
          <Lookup
            dataSource={driverDataList}
            valueExpr="id"
            displayExpr={gridBoxDisplayExpr}
          />
        </Column>
        <Column
          //   editCellRender={(e) => {

          //  allowEditing={logedUser.role === "admin" ? true : false}
          dataField="driver2_id"
          caption={"Жолооч2"}
        >
          <Lookup
            dataSource={driverDataList}
            valueExpr="id"
            displayExpr={gridBoxDisplayExpr}
          />
        </Column>
        <Column dataField="driver1_register" caption={"Жолооч регистер"} />
        <Column
          dataField="seat_count"
          caption={"Суудал тоо"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData
                    ? vehicleSearchData.modification_seat_count
                    : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />

        <Column
          dataField="build_year"
          caption={"Үйлдвэрлэсэн огноо:"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData ? vehicleSearchData.build_year : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="imported_date"
          caption={"Импорт огноо:"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData ? vehicleSearchData.imported_date : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="country_name"
          caption={"Үйлдвэрлэсэн улс:"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData ? vehicleSearchData.country_name : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="fuel_type_name"
          caption={"Шатхууны төрөл:"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData ? vehicleSearchData.fuel_type_name : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="axle_count"
          caption={"Тэнхлэг тоо:"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData
                    ? vehicleSearchData.modification_axle_count
                    : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="max_load"
          caption={"Даац:"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData
                    ? vehicleSearchData.modification_max_load
                    : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="total_weight"
          caption={"Нийт жин:"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData
                    ? vehicleSearchData.modification_total_weight
                    : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="length"
          caption={"Урт:"}
          editCellRender={(e) => {
            return (
              <TextBox
                readOnly
                value={
                  vehicleSearchData
                    ? vehicleSearchData.modification_length
                    : e.value
                }
                valueChangeEvent="input"
              />
            );
            // console.log(e.value);
          }}
        />
        <Column
          dataField="approved_at"
          format="yyyy-MM-dd HH:mm"
          dataType="date"
          caption={"Баталгаажсан огноо"}
        />
        <Column
          dataField="created_at"
          format="yyyy-MM-dd HH:mm"
          dataType="date"
          caption={"Бүртгэсэн"}
        />

        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />
      </DataGrid>
    </>
  );
};

export default VehicleList;
