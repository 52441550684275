import { Box, useTheme } from "@mui/material";
import DataGrid, {
  FilterRow,
  HeaderFilter,
  Selection,
  Column,
  SortByGroupSummaryInfo,
  ColumnFixing,
  ColumnChooser,
  LoadPanel,
  Summary,
  GroupItem,
  TotalItem,
} from "devextreme-react/data-grid";
import Button from "devextreme-react/button";
import Toolbar, { Item } from "devextreme-react/toolbar";

import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { DataGridConfig } from "../../../config";
import notify from "devextreme/ui/notify";

import FilterBox from "./filter";
import {
  getVehicleCountBuildReportFun,
  getViolationInspectorWorkFun,
  setFilter,
} from "../../../store/reducers/report";
import { getPorkurorReportFun } from "../../../store/reducers/report";
import { formatDate } from "devextreme/localization";
import moment from "moment";
import BaseHelper from "../../../helper/BaseHelper";

const ViolationInspectorWorkList = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const violationInspectorWork = useSelector(
    (state) => state.report.violationInspectorWork
  );
  const filter = useSelector((state) => state.report.filter);
  const [showFilterRow, setShowFilterRow] = useState(true);
  const [showHeaderFilter, setShowHeaderFilter] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("full");
  const [isType, setIsType] = useState(1);
  const [isPop, setIsPop] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const logedUser = useSelector((state) => state.auth.user);
  const [formData, setFormData] = useState([]);
  const start = moment().add(-4, "y");
  const [formEditData, setFormEditData] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);
  const setFilters = (field, value) => {
    //console.log(field, value);
    dispatch(setFilter({ ...filter, [field]: value }));
  };
  // useEffect(() => {
  //   //console.log(Object.keys(filter).length);
  //   if (Object.keys(filter).length === 0) {
  //     dispatch(setFilter({ date_type: "year" }));
  //     //  dispatch(getPorkurorReportData(filter));
  //   }
  // }, [filter, dispatch]);
  // console.log(start);
  // console.log(moment().year() - 1);
  useEffect(() => {
    // gridRef.current.instance.beginCustomLoading();
    if (violationInspectorWork == null && Object.keys(filter).length === 0) {
      dispatch(
        getViolationInspectorWorkFun({
          ...filter,
          start_date: moment().year() - 4 + "-" + "01" + "-01",
          end_date: moment().year() + "-" + "12" + "-31",
        })
      );
    } else {
      // gridRef.current.instance.endCustomLoading();
      setFormData(violationInspectorWork);
      // dispatch(setRemFilter());
    }
  }, [violationInspectorWork, filter, dispatch]);
  // useEffect(() => {
  //   if (getPorkurorReportData == null && Object.keys(filter).length > 0) {
  //     dispatch(getPorkurorReportFun(filter));
  //   }
  // }, [getPorkurorReportData, filter, dispatch]);
  //console.log(getInspectorReportData);

  useEffect(() => {
    //console.log(Object.keys(filter).length);
    if (Object.keys(filter).length > 0) {
      dispatch(getViolationInspectorWorkFun(filter));
    }
  }, [filter, dispatch]);

  //console.log(props);
  // useEffect(() => {
  //   gridRef.current.instance.beginCustomLoading();
  //   if (props.porkurorReportListData) {
  //     setFormData(props.porkurorReportListData);
  //     gridRef.current.instance.endCustomLoading();
  //   }
  // }, [props]);
  // console.log(props.feedBackListData);
  const gridRef = useRef();

  const allowedPageSizes = [5, 10, , 30, 50, 100, "Бүгд"];

  // const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
  //   // console.log("selectedRowKeys");
  //   // console.log(selectedRowsData[0].id);
  //   setSelectIndex(selectedRowKeys[0]);
  //   setIsType(2);
  //   setFormEditData(selectedRowsData[0]);
  // };
  const hideInfo = () => {
    setIsPop(false);
    setIsConfirm(false);
  };

  function deleteRecords() {
    selectIndex.forEach((key) => {
      gridRef.current.instance.deleteRow(key);
    });
  }
  function closeIsPop() {
    setIsPop(false);
  }
  function customizeDate(data) {
    return `Нийт дүн`;
  }
  function cellRender() {
    for (let index = 0; index < 5; index++) {
      return <Column dataField="violation_date" caption={"Огноо"} />;
    }
  }
  // const calculateCustomSummary = (options) => {
  //   // Calculating "customSummary1"
  //   console.log(options);
  //   // if (options.column === "legal_penalty_amount") {
  //   //   options.value = +22222;
  //   // }
  // };
  // console.log(formData);
  return (
    <>
      {/* <Box id="descContainer">dsdsvdsvs</Box> */}
      <Toolbar style={{ paddingBottom: "10px" }}>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <FilterBox getFilters={setFilters} />
          </Box>
        </Item>
      </Toolbar>
      <Toolbar>
        <Item location="before">
          <Box sx={{ ml: 2 }}>
            <Button
              icon={"refresh"}
              onClick={() => {
                dispatch(getVehicleCountBuildReportFun(filter));
                gridRef.current.instance.refresh();
              }}
              type={"normal"}
              text="Сэргээх"
            />
          </Box>
        </Item>

        <Item location="after">
          <Box>
            <Button
              onClick={() => {
                gridRef.current.instance.exportToExcel();
              }}
            >
              <Box sx={{ background: "#fff", p: 0.5 }}>
                <img src="/assets/excel.png" alt="" className="dx-icon" />
              </Box>
            </Button>
          </Box>
        </Item>
      </Toolbar>

      <DataGrid
        ref={gridRef}
        {...DataGridConfig}
        remoteOperations={false}
        dataSource={formData}
        //keyExpr="id"
        //  keyExpr="violation_date"
        id="gridContainer"
        wordWrapEnabled={false}
        height={600}
      >
        <Selection mode="multiple" />
        <ColumnChooser enabled={false} />
        <ColumnFixing enabled={true} />

        {/* <Column
          allowSorting={true}
          caption="#"
          cellRender={(e) => {
            return e.rowIndex - 1 + 1;
          }}
          fixed={true}
        /> */}
        <Column dataField="checked" caption={"Нэр"} fixed={true} />
        <Column
          dataField="legal_penalty_amount"
          caption={"Нийт торгууль"}
          cellRender={(v) => {
            return BaseHelper.currancyFormat(parseInt(v.value));
          }}
        />
        <Column dataField="score" caption={"Оноо"} />
        <Column caption={"АТХУБ НЭГДСЭН ҮЗҮҮЛЭЛТ"} alignment={"center"}>
          <Column dataField="citizen_count" caption={"Зорчигч"} />
          <Column dataField="company_count" caption={"ААН тоо"} />
          <Column dataField="vcount" caption={"Нийт тоо"} />
        </Column>

        <FilterRow visible={showFilterRow} applyFilter={currentFilter} />
        <HeaderFilter visible={showHeaderFilter} />

        <LoadPanel enabled={true} />

        <Summary>
          <TotalItem
            column="checked"
            summaryType="sum"
            // valueFormat="currency"
            customizeText={customizeDate}
          />
          <TotalItem
            column="legal_penalty_amount"
            summaryType="sum"
            skipEmptyValues={true}
            customizeText={(e) => {
              return BaseHelper.currancyFormat(parseInt(e.value));
            }}
            displayFormat="{0}₮"

            // valueFormat="number"
            //]] customizeText={customizeDate}
          />
          <TotalItem
            column="score"
            summaryType="sum"
            displayFormat="{0}"
            customizeText={(e) => {
              return BaseHelper.CusromFormat(parseInt(e.value));
            }}
            displa
            // customizeText={customizeDate}
          />
          <TotalItem
            column="vcount"
            summaryType="sum"
            displayFormat="{0}"
            customizeText={(e) => {
              return BaseHelper.CusromFormat(parseInt(e.value));
            }}
            // valueFormat="currency"
            // customizeText={customizeDate}
          />
        </Summary>
        <SortByGroupSummaryInfo summaryItem={0} />
      </DataGrid>
    </>
  );
};

export default ViolationInspectorWorkList;
