import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
} from "@mui/material";
import { orange } from "@mui/material/colors";

const TableGrievance = ({ dashboardData }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  useEffect(() => {
    if (dashboardData != null) {
      setData(dashboardData.table_grievance);
      setFilteredData(dashboardData.table_grievance);
    }
  }, [dashboardData]);

  // Filter data based on search term
  useEffect(() => {
    const filtered = data.filter(
      (row) =>
        row.branch_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.job_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        row.lastname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
    setPage(0); // Reset to first page when search changes
  }, [searchTerm, data]);

  // Calculate totals
  const totalAmountComp = filteredData.reduce(
    (total, row) => total + parseInt(row.total_count || 0, 10),
    0
  );
  const totalAmountDriver = filteredData.reduce(
    (total, row) => total + parseInt(row.closed_count || 0, 10),
    0
  );

  const totalAmountEmployees = filteredData.reduce(
    (total, row) =>
      total + parseInt(row.total_count - row.closed_count || 0, 10),
    0
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper style={{ padding: "16px", marginTop: "20px", background: "none" }}>
      <TextField
        label="Хайх"
        variant="outlined"
        sx={{ float: "right" }}
        //fullWidth
        size="small"
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>№</TableCell>
              <TableCell align="left">Хэлтэс</TableCell>
              <TableCell align="left">Албан тушаал</TableCell>
              <TableCell align="left">Овог, Нэр</TableCell>
              <TableCell align="right">Нийт</TableCell>
              <TableCell align="right">Шийдвэрлэж хаасан</TableCell>
              <TableCell align="right">Шийдвэрлээгүй</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.branch_name}</TableCell>
                  <TableCell align="left">{row.job_title}</TableCell>
                  <TableCell align="left">
                    {row.lastname[0]}.{row.firstname}
                  </TableCell>
                  <TableCell align="right">
                    <strong>{row.total_count}</strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong style={{ color: "#0aff0a" }}>
                      {row.closed_count}
                    </strong>
                  </TableCell>
                  <TableCell align="right">
                    <strong style={{ color: "red" }}>
                      {" "}
                      {parseInt(row.total_count) - parseInt(row.closed_count)}
                    </strong>
                  </TableCell>
                </TableRow>
              ))}
            {/* Total Row */}
            <TableRow>
              <TableCell colSpan={4} align="right">
                <strong style={{ color: "orange" }}>Нийт:</strong>
              </TableCell>

              <TableCell align="right">
                <strong style={{ color: "orange" }}>{totalAmountComp}</strong>
              </TableCell>
              <TableCell align="right">
                <strong style={{ color: "orange" }}>{totalAmountDriver}</strong>
              </TableCell>
              <TableCell align="right">
                <strong style={{ color: "orange" }}>
                  {totalAmountEmployees}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={filteredData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Хуудсанд харуулах мөр:"
      />
    </Paper>
  );
};

export default TableGrievance;
